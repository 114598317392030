import axiosUtils from '../utils/axios';

const baseUrl = "api/digipoort"

const getAuthenticationModel = async (reportId: string): Promise<any> => {
    const result = await axiosUtils.instance.get<any>(`${baseUrl}/authenticationModel/${reportId}`);
    return result.data;
}

export default {
    getAuthenticationModel
}