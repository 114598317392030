import { useEffect, useRef, useState } from "react";

export const useClickOutside = <T extends HTMLElement>() =>{
    const ref = useRef<T>(null);
    const [clickedOutsite, setClickedOutside] = useState(false);

    useEffect(() => {
        const handleClickOutside = (event: any) => {
            if (ref.current && !ref.current.contains(event.target) && clickedOutsite === true) {
                setClickedOutside(false);
            }
        };
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, [clickedOutsite]);

    return [ref, clickedOutsite, setClickedOutside] as const;
}