import { useEffect } from "react";
import ReactGA from "react-ga4";
import { toLower } from "lodash";

export const sendEvent = (eventName: string, action: string) => {
    if (toLower(process.env.REACT_APP_GOOGLE_ANALYTICS_ENABLED) !== "true" || !process.env.REACT_APP_GOOGLE_ANALYTICS_ID) {
        return;
    }
 
    ReactGA.send({
        hitType: "event",
        eventName: eventName,
        eventAction: action
    });
}