import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    lng: 'en',
    defaultNS: 'common',
    debug: true,
    fallbackLng: {
      'nb': ['no', 'en'],
      'nn': ['no', 'en'],
      'default': ['en']
    },
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    }
  });


export default i18n;