import { useId, useState } from "react";
import { useTranslation } from "react-i18next";
import "../styles/searchBar.css"

export const SearchBar = ({ onChange }: { onChange: (input: string) => any; }) => {
    const id = useId();
    const [value, setValue] = useState<string>("");
    const { t } = useTranslation("general");

    const handleOnChange = (input: string) => {
        setValue(input);
        onChange(input);
    };

    return (
        <div className="search-group">
            <label htmlFor={id} className="sr-only">{t("Search")}</label>
            <input
                onChange={(e) => handleOnChange(e.target.value)}
                value={value}
                id={id}
                className="form-control pr-16"
                type="search"
                placeholder={`${t("Search")}...`}
                aria-label="searchBlock" />
            <span className="search-icon">{t("SearchIcon")}</span>
            <span className="clear-search">{t("Clear")}</span>
        </div>
    );
};
