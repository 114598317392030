import { useTranslation } from "react-i18next";
import { IReportSummary } from "../../interfaces";
import { toLocaleDateString, toLocaleDateTimeString } from "../../utils";
import { ReportDetailsContainerEnum } from "../../utils/constants";

const defaultValue = "n/a";
export const ReportSummary = ({ referenceId, reportDetails, detailsContainer }: { referenceId: string, reportDetails?: IReportSummary, detailsContainer: ReportDetailsContainerEnum}) => {
    const { t } = useTranslation("general");

    return (
        <div className="col ar-summary">
            {detailsContainer === ReportDetailsContainerEnum.DetailsPage &&
                <>
                    <ReportTableRowContentValue title={t("ReportType")} value={reportDetails ? reportDetails.ReportType : defaultValue} />
                    <ReportTableRowContentValue title={t("OrganizationName")} value={reportDetails ? reportDetails.OrganizationName : defaultValue} />
                </>
            }
            <ReportTableRowContentValue title={t("OrganizationNumber")} value={reportDetails ? reportDetails.OrganizationNumber : defaultValue} />
            <ReportTableRowContentValue title={t("TaxRegistrationNumber")} value={reportDetails ? reportDetails.VatNumber : defaultValue} />
            {detailsContainer === ReportDetailsContainerEnum.DetailsPage &&
                <ReportTableRowContentValue title={t("Period")} value={reportDetails ? 
                    `${toLocaleDateString(reportDetails.PeriodStart)} - ${toLocaleDateString(reportDetails.PeriodEnd)}` : defaultValue} />
            }
            <ReportTableRowContentValue title={t("Username")} value={reportDetails ? reportDetails.UserName : defaultValue} />
            <ReportTableRowContentValue title={t("ReportReference")} value={referenceId} />
            <ReportTableRowContentValue title={t("ServiceReference")} value={reportDetails ? reportDetails.ServiceId : defaultValue} />
            <ReportTableRowContentValue title={t("LastChange")} value={reportDetails ? toLocaleDateTimeString(reportDetails.LastChange) : defaultValue} />
            <ReportTableRowContentValue title={t("SentOn")} value={reportDetails ? toLocaleDateTimeString(reportDetails.Sent) : defaultValue} />

            {reportDetails && reportDetails.Invoice &&
                <>
                    <h3 className="pl-8 pt-16 ar-fw-bold">{t("PaymentDetails")}</h3>
                    {
                        reportDetails.Invoice.PaymentType &&
                        <ReportTableRowContentValue title={t("PaymentType")} value={reportDetails.Invoice.PaymentType} />
                    }
                    <ReportTableRowContentValue title={t("PaymentReference")} value={reportDetails.Invoice.PaymentReference} />
                    <ReportTableRowContentValue title={t("BankAccountNumber")} value={reportDetails.Invoice.BankAccountNumber} />
                    <ReportTableRowContentValue title={t("Amount")} value={`${reportDetails.Invoice.Amount} ${reportDetails.Invoice.CurrencyCode}`} />
                    <ReportTableRowContentValue title={t("DueDate")} value={toLocaleDateString(reportDetails.Invoice.DueDate)} />
                </>
            }
            {reportDetails && reportDetails.Parameters &&
                <>
                    {reportDetails.Parameters.map(p => <ReportTableRowContentValue title={p.Key} value={p.Value} key={p.Key} /> )}
                </>
            }
        </div>
    )
}

const ReportTableRowContentValue = ({ title, value }: { title: string, value: any }) => {
    return (
        <div className="row pb-10 ar-summary-value">
            <div className="col col-md-4 col-xl-3 ar-break-word ar-bold">
                {title}
            </div>
            <div className="col ar-break-word">
                {value}
            </div>
        </div>
    )
};