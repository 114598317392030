import { useTranslation } from "react-i18next";

export const ThirdPartyComponentPage = () => {
    const { t } = useTranslation("general");
    
    return (
        <div className="ar-page-container container">
            <div className="panel panel-default mt-32">
                <div className="panel-heading">
                    <h4 className="panel-title">{t("3rdPartyComponents")}</h4>
                </div>
                <div className="panel-body">
                    <div className="fullHeight fullHeight-container fullWidth">
                        <ul>
                            <li>Antlr3.Runtime	3.5.1 </li>
                            <li>Apache.NMS	1.8.0</li>
                            <li>Apache.NMS.ActiveMQ	1.8.0</li>
                            <li>Azure.Core	1.27.0</li>
                            <li>Azure.Identity	1.8.1</li>
                            <li>Azure.Security.KeyVault.Secrets	4.4.0</li>
                            <li>Azure.Storage.Blobs	12.14.1</li>
                            <li>Azure.Storage.Common	12.13.0</li>
                            <li>Azure.Storage.Queues	12.12.0</li>
                            <li>Captcha	4.4.2</li>
                            <li>Castle.Core	4.4.1</li>
                            <li>Castle.Facilities.ServiceFabricIntegration	2.2.0</li>
                            <li>Castle.WcfIntegrationFacility	5.1.1</li>
                            <li>Castle.Windsor	5.1.1</li>
                            <li>FluentFTP	44.0.1</li>
                            <li>FluentNHibernate    3.1.0</li>
                            <li>Iesi.Collections	4.0.4</li>
                            <li>Iesi.Collections	4.0.5</li>
                            <li>log4net	2.0.14</li>
                            <li>log4net	2.0.15</li>
                            <li>Microsoft.ApplicationInsights	2.20.0</li>
                            <li>Microsoft.ApplicationInsights	2.21.0</li>
                            <li>Microsoft.ApplicationInsights.Agent.Intercept	2.4.0</li>
                            <li>Microsoft.ApplicationInsights.DependencyCollector	2.20.0</li>
                            <li>Microsoft.ApplicationInsights.DependencyCollector	2.21.0</li>
                            <li>Microsoft.ApplicationInsights.Log4NetAppender	2.20.0</li>
                            <li>Microsoft.ApplicationInsights.Log4NetAppender	2.21.0</li>
                            <li>Microsoft.ApplicationInsights.PerfCounterCollector	2.20.0</li>
                            <li>Microsoft.ApplicationInsights.PerfCounterCollector	2.21.0</li>
                            <li>Microsoft.ApplicationInsights.ServiceFabric	2.3.1</li>
                            <li>Microsoft.ApplicationInsights.ServiceFabric.Native	2.3.1</li>
                            <li>Microsoft.ApplicationInsights.Web	2.21.0</li>
                            <li>Microsoft.ApplicationInsights.WindowsServer	2.20.0</li>
                            <li>Microsoft.ApplicationInsights.WindowsServer	2.21.0</li>
                            <li>Microsoft.ApplicationInsights.WindowsServer.TelemetryChannel	2.20.0</li>
                            <li>Microsoft.ApplicationInsights.WindowsServer.TelemetryChannel	2.21.0</li>
                            <li>Microsoft.AspNet.Mvc	5.2.9</li>
                            <li>Microsoft.AspNet.Razor	3.2.9</li>
                            <li>Microsoft.AspNet.TelemetryCorrelation	1.0.8</li>
                            <li>Microsoft.AspNet.WebApi	5.2.9</li>
                            <li>Microsoft.AspNet.WebApi.Client	5.2.9</li>
                            <li>Microsoft.AspNet.WebApi.Core	5.2.9</li>
                            <li>Microsoft.AspNet.WebApi.WebHost	5.2.9</li>
                            <li>Microsoft.AspNet.WebPages	3.2.9</li>
                            <li>Microsoft.Bcl	1.1.10</li>
                            <li>Microsoft.Bcl.AsyncInterfaces	7.0.0</li>
                            <li>Microsoft.Bcl.Build	1.0.21</li>
                            <li>Microsoft.Data.Edm	5.8.5</li>
                            <li>Microsoft.Data.OData	5.8.5</li>
                            <li>Microsoft.Data.Services.Client	5.8.5</li>
                            <li>Microsoft.Identity.Client	4.49.1</li>
                            <li>Microsoft.Identity.Client.Extensions.Msal	2.25.3</li>
                            <li>Microsoft.IdentityModel.Abstractions	6.26.0</li>
                            <li>Microsoft.IdentityModel.Clients.ActiveDirectory	5.3.0</li>
                            <li>Microsoft.IdentityModel.JsonWebTokens	6.26.0</li>
                            <li>Microsoft.IdentityModel.Logging	6.15.1</li>
                            <li>Microsoft.IdentityModel.Logging	6.26.0</li>
                            <li>Microsoft.IdentityModel.Tokens	6.15.1</li>
                            <li>Microsoft.IdentityModel.Tokens	6.26.0</li>
                            <li>Microsoft.NETCore.Platforms	7.0.0</li>
                            <li>Microsoft.NETCore.Targets	5.0.0</li>
                            <li>Microsoft.Net.Http	2.2.29</li>
                            <li>Microsoft.ServiceFabric	8.2.1486</li>
                            <li>Microsoft.ServiceFabric	9.1.1436</li>
                            <li>Microsoft.ServiceFabric.Data	5.2.1486</li>
                            <li>Microsoft.ServiceFabric.Data	6.1.1436</li>
                            <li>Microsoft.ServiceFabric.Data.Extensions	5.2.1486</li>
                            <li>Microsoft.ServiceFabric.Data.Extensions	6.1.1436</li>
                            <li>Microsoft.ServiceFabric.Data.Interfaces	5.2.1486</li>
                            <li>Microsoft.ServiceFabric.Data.Interfaces	6.1.1436</li>
                            <li>Microsoft.ServiceFabric.Diagnostics.Internal	5.2.1486</li>
                            <li>Microsoft.ServiceFabric.Diagnostics.Internal	6.1.1436</li>
                            <li>Microsoft.ServiceFabric.FabricTransport.Internal	5.2.1486</li>
                            <li>Microsoft.ServiceFabric.FabricTransport.Internal	6.1.1436</li>
                            <li>Microsoft.ServiceFabric.Services	5.2.1486</li>
                            <li>Microsoft.ServiceFabric.Services	6.1.1436</li>
                            <li>Microsoft.ServiceFabric.Services.Remoting	5.2.1486</li>
                            <li>Microsoft.ServiceFabric.Services.Remoting	6.1.1436</li>
                            <li>Microsoft.VisualStudio.Azure.Fabric.MSBuild	1.7.7</li>
                            <li>Microsoft.Web.Infrastructure	2.0.0</li>
                            <li>Moq	4.16.1</li>
                            <li>MSTest.TestAdapter	2.2.8</li>
                            <li>MSTest.TestFramework	2.2.8</li>
                            <li>Newtonsoft.Json	13.0.1</li>
                            <li>Newtonsoft.Json	13.0.2</li>
                            <li>NHibernate	5.1.3</li>
                            <li>NHibernate	5.3.10</li>
                            <li>Remotion.Linq	2.2.0</li>
                            <li>Remotion.Linq.EagerFetching	2.2.0</li>
                            <li>Stub.System.Data.SQLite.Core.NetFramework	1.0.117.0</li>
                            <li>System.Buffers	4.5.1</li>
                            <li>System.Data.SQLite.Core	1.0.117.0</li>
                            <li>System.Diagnostics.DiagnosticSource	6.0.0</li>
                            <li>System.Diagnostics.DiagnosticSource	7.0.0</li>
                            <li>System.IdentityModel.Tokens.Jwt	6.26.0</li>
                            <li>System.IO	4.3.0</li>
                            <li>System.IO.Hashing	7.0.0</li>
                            <li>System.Memory	4.5.4</li>
                            <li>System.Memory	4.5.5</li>
                            <li>System.Memory.Data	7.0.0</li>
                            <li>System.Net.Http	4.3.4</li>
                            <li>System.Net.Primitives	4.3.1</li>
                            <li>System.Numerics.Vectors	4.5.0</li>
                            <li>System.Private.Uri	4.3.2</li>
                            <li>System.Runtime	4.3.1</li>
                            <li>System.Runtime.CompilerServices.Unsafe	6.0.0</li>
                            <li>System.Runtime.InteropServices.RuntimeInformation	4.3.0</li>
                            <li>System.Security.Cryptography.Algorithms	4.3.1</li>
                            <li>System.Security.Cryptography.Encoding	4.3.0</li>
                            <li>System.Security.Cryptography.Primitives	4.3.0</li>
                            <li>System.Security.Cryptography.ProtectedData	7.0.0</li>
                            <li>System.Security.Cryptography.X509Certificates	4.3.2</li>
                            <li>System.Spatial	5.8.5</li>
                            <li>System.Text.Encoding	4.3.0</li>
                            <li>System.Text.Encodings.Web	7.0.0</li>
                            <li>System.Text.Json	7.0.1</li>
                            <li>System.Threading.Tasks.Extensions	4.5.4</li>
                            <li>System.ValueTuple	4.5.0</li>
                            <li>Visma.AutoReport.Altinn3Connector	2.0.7</li>
                            <li>Visma.AutoReport.AltinnConnector	2.1.7</li>
                            <li>Visma.AutoReport.ApitamoPkiConnector	1.0.28</li>
                            <li>Visma.AutoReport.DuoConnector	1.0.27</li>
                            <li>Visma.AutoReport.KatreConnector	1.0.39</li>
                            <li>Visma.AutoReport.PinkWebConnector	2.0.2</li>
                            <li>Visma.AutoReport.UpaConnector	2.0.3</li>
                            <li>Visma.AutoReport.VeroApiConnector	1.0.21</li>
                            <li>VS.QualityTools.UnitTestFramework	15.0.27323.2</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>)
}