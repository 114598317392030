import { useState } from "react";

export const useListNavigation = <T>(options: T[]) => {
    const [activeItem, setActiveItem] = useState<T>();

    const focusNext = (): void => {
        if (options?.length === 0) {
            return;
        }

        const currentItem = activeItem === undefined ? -1 : options.indexOf(activeItem);
        if (currentItem === options.length - 1 || currentItem === -1) {
            focusFirst();
        } else {
            setActiveItem(options[currentItem + 1]);
        }
    };
    const focusPrevious = (): void => {
        if (options?.length === 0) {
            return;
        }

        const currentItem = activeItem === undefined ? 0 : options.indexOf(activeItem);
        if (currentItem === 0) {
            focusLast();
        } else {
            setActiveItem(options[currentItem - 1]);
        }
    };
    const focusFirst = (): void => {
        if (options?.length > 0) {
            setActiveItem(options[0]);
        }
    };
    const focusLast = (): void => {
        if (options?.length > 0) {
            setActiveItem(options[options.length - 1]);
        }
    };

    const clearState = () => {
        setActiveItem(undefined);
    };

    return {activeItem, setActiveItem, clearState, focusNext, focusPrevious, focusFirst, focusLast} as const;
};