import { useRef, useState } from "react";
import { IAlert } from "../interfaces";
import { Toast } from '../components';

export const useToast = () => {
    const [toast, setToast] = useState<any>(undefined);
    const timeouts = useRef<any[]>([]);

    const showToast = (alert: IAlert) => {
        timeouts.current.forEach(element => {
            clearTimeout(element);
        });

        timeouts.current = [];

        setToast(<Toast alert={alert} style={{ animation: "show 0.5s" }} />);

        timeouts.current.push(setTimeout(() => {
            setToast(<Toast alert={alert} style={{ animation: "hide 2.5s" }} />);
        }, 3500));

        timeouts.current.push(setTimeout(() => {
            setToast(undefined);
        }, 5000));
    };

    return [toast, showToast] as const;
};
