import { IFilterRow } from "../interfaces"

export const FilterRow = ({ title, children, className }: IFilterRow) => {
    return (
        <div className={`row p-8 ${className ?? ""}`}>
            <div className="col-md-1 ar-col-xxl-05 pt-4 pb-8 px-8 ar-break-word">
                {title}
            </div>
            {children}
        </div>
    )
}