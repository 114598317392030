import { IAltinnAuthenticationModel } from "../interfaces";
import axiosUtils from '../utils/axios';

const baseUrl = "api/altinn"

const getReportAuthenticationModel = async (reportId: string): Promise<IAltinnAuthenticationModel> => {
    const result = await axiosUtils.instance.get<IAltinnAuthenticationModel>(`${baseUrl}/authenticationModel/${reportId}`);
    return result.data;
}

export default {
    getReportAuthenticationModel
}