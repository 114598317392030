import axios, { AxiosInstance, InternalAxiosRequestConfig  } from 'axios';

const disableAntiForgeryValidationUrls: string[] = [
    "api/antiForgery/token",
    "connectIdp/authorize"
];
const antiForgeryMethods: string[] = ["POST", "PUT", "PATCH", "DELETE"];

export class AntiForgery {

    public constructor(private instance: AxiosInstance) { }

    public async addToken(request: InternalAxiosRequestConfig <any>) {
        const modifiedRequest = request;

        let method: string = (request.method || '').toUpperCase();
        let shortUrl: string = (request.url || '').toUpperCase();


        if (antiForgeryMethods.find(el => el === method)
            && !disableAntiForgeryValidationUrls.find(url => url.toUpperCase() === shortUrl)) {

            let completeUrl = new URL(request.baseURL + "/" + request.url);
            let targetUrl: string = encodeURI(completeUrl.pathname);
            let token = await this.instance.post("api/antiForgery/token", null, {
                params: {
                    relativeUrl: targetUrl
                }
            });

            if (request.headers)
                request.headers.RequestVerificationToken = token.data;
            else
                console.error("Headers missing from request");
        }

        return modifiedRequest;
    }
}