import { useTranslation } from "react-i18next";
import { IMultiselect } from "../interfaces";
import { MultiselectButton } from "./MultiselectButton";
import { useEffect } from "react";

export const Multiselect = ({ Options, onChange, Value }: IMultiselect) => {
    const { t } = useTranslation("general");

    const addId = (id: string) => {
        if(Value?.length === Object.entries(Options).length)
            Value=[];

        onChange(Value ? [...Value, id] : [id]);
    }

    const removeId = (id: string) => {
        const newValue = Value?.filter(o => o !== id);
        onChange(newValue ? newValue : []);
    }

    const addAll = () => {
        if (Value?.length === 0)
            return;

        onChange(Object.entries(Options).map(el => {return el[0]}));
    }

    useEffect(() => {
        if( Value?.length != 0){
            Value?.forEach(element => {
                var option = Object.entries(Options).find(el => el[0] === element);
                if(!option){
                    addAll();
                    return;
                }
            });
        }
    }, [])

    return (
        <div className="col nav nav-pills nav-pills-primary">
            <MultiselectButton Id={"All"}
                Value={t("All")}
                Active={!Value || Value.length === 0 || Value.length === Object.entries(Options).length}
                addId={addAll}
                removeId={addAll} />
            {
                Object.entries(Options).map(el => {
                    return <MultiselectButton Id={el[0]}
                        key={el[0]}
                        Value={el[1]}
                        Active={Value ? Value.includes(el[0]) && Value.length !== Object.entries(Options).length : false}
                        addId={addId}
                        removeId={removeId} />
                })
            }
        </div>
    );
};
