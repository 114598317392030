import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ReportActionsPanel } from ".";
import { Alert } from "../../components";
import { ComponentLoadingSpinner } from "../../components/ComponentLoadingSpinner";
import { IReportData } from "../../interfaces";
import reportService from "../../services/reportService";
import { ReportDetailsContainerEnum } from "../../utils/constants";
import { ReportSummary } from "./ReportSummary";

export const ReportDetailsContent = ({ referenceId, expanded, detailsContainer, refreshRowHeader, setAction }:
    {
        referenceId: string,
        expanded: boolean,
        detailsContainer: ReportDetailsContainerEnum,
        refreshRowHeader?: () => void,
        setAction? : (action:string) => void
    }) => {
    const [data, setData] = useState<IReportData>();
    const { t } = useTranslation("general");

    useEffect(() => {
        if (!expanded || data)
            return;

        refreshReportDetails();
    }, [expanded])

    const refreshReportDetails = () => {
        reportService.getReport(referenceId)
            .then((response) => {
                setData(response);
                setAction && !response.Actions?.NeedsAuthentication && setAction("authenticated");
            });
    }

    const refreshData = () => {
        refreshRowHeader && refreshRowHeader();
        refreshReportDetails();
    }

    return (
        <div className="row">
            {data ?
                <>
                    <ReportSummary referenceId={data.ReferenceId} reportDetails={data.Summary} detailsContainer={detailsContainer}/>
                    <div className="col">
                        <Alert alert={{
                            type: data.StatusDetails.Category,
                            title: data.StatusDetails.Title,
                            message: data.StatusDetails.Message && <span className="d-block mt-24">{data.StatusDetails.Message}</span>,
                            sticky: true,
                        }} />
                        {data.Actions &&
                            <ReportActionsPanel referenceId={data.ReferenceId}
                                reportActions={data.Actions}
                                refreshData={refreshData}
                                detailsContainer={detailsContainer}
                            />}
                    </div>
                </>
                : <ComponentLoadingSpinner message={t("PleaseWaitMessage")} />
            }
        </div >
    );
};

