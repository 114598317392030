import { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Checkbox, RadioButton, FormInput } from "../../../components";
import { useAlert } from "../../../hooks/AlertHook";
import { useClickInputHook } from "../../../hooks/ClickInputHook";
import { IPinkWebSettings, IPinkWebSettingsProps, IPinkWebUpdateSettingsRequest } from "../../../interfaces";
import pinkWebSettingService from "../../../services/pinkWebSettingService";
import { ResponseTypeEnum } from "../../../utils/constants";
import '../../../styles/settings.css';
import { ViewAllCompaniesModal } from "./ViewAllCompaniesModal";
import { ConfirmModal } from "../../../components/ConfirmModal";
import { postMessageToParent, redirectToOriginUrl, sendMessageToParentFromSettingsPage, triggerWootric } from "../../../utils";
import { useLocation } from "react-router-dom";
import { sendEvent } from "../../../hooks/GoogleAnalyticsHook";

export const PinkWebSettings = ({ data, refreshData }: IPinkWebSettingsProps) => {

    const { t } = useTranslation(["general", "pinkweb"]);
    const { search } = useLocation();

    const [existingSettings, setExistingSettings] = useState<IPinkWebSettings>();

    const [usePinkWebForVat, setUsePinkWebForVat] = useState(false);
    const [useDefaultEndpoint, setUseDefaultEndpoint] = useState<boolean>(true);
    const [endpoint, setEndpoint] = useState("");
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");

    const [alert, showAlert, clearAlert] = useAlert();
    useClickInputHook(clearAlert);

    const [showViewAll, setShowViewAll] = useState<boolean>(false);
    const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);

    useEffect(() => {
        sendEvent("settings_pinkweb", "Open pinkWeb settings page.");
        setExistingSettings(data);
        resetValues(data, true);
    }, [data]);

    const handleUsePinkWeb = (checked: boolean) => {
        setUsePinkWebForVat(checked);
        resetValues(existingSettings, false);
    }

    const handleEndpointChange = (value: string) => {
        const isDefaultEndpoint = value === "true";
        setUseDefaultEndpoint(isDefaultEndpoint);

        if (existingSettings?.Endpoint && !isDefaultEndpoint) {
            setEndpoint(existingSettings.Endpoint)
        } else {
            setEndpoint("");
        }
    }

    const disableSave = () => {
        if (!existingSettings) {
            return !usePinkWebForVat || !username || !password || (!useDefaultEndpoint && !endpoint);
        }

        const valuesChanged = existingSettings.Username !== username ||
            existingSettings.UseDefaultEndpoint !== useDefaultEndpoint ||
            existingSettings.Endpoint !== endpoint ||
            existingSettings.UsePinkWebForVat !== usePinkWebForVat ||
            password;

        return !valuesChanged;
    }

    const handleSave = () => {
        const request: IPinkWebUpdateSettingsRequest = {
            usePinkWebForVat,
            useDefaultEndpoint,
            endpoint,
            username,
            password
        }

        pinkWebSettingService.updateSettings(request)
            .then(() => {
                refreshData();
                redirectToOriginUrl(search);
                if (sendMessageToParentFromSettingsPage(search))
                    postMessageToParent("settings/pinkweb", "sent");

                showAlert({
                    type: ResponseTypeEnum.Success,
                    message: t("SaveSuccess", { ns: "pinkweb" }),
                    title: t("Success")
                });
            })
            .catch((error) => {
                showAlert({
                    type: ResponseTypeEnum.Danger,
                    message: error?.data ?? t("SomethingWentWrongTitle", { ns: "general" }),
                    title: t("Error")
                })
            });
        
        triggerWootric('settings_save');
    }

    const resetValues = (settings: IPinkWebSettings | undefined, resetUsePinkWebForVat: boolean) => {
        if (resetUsePinkWebForVat)
            setUsePinkWebForVat(settings ? settings.UsePinkWebForVat : false);

        setUseDefaultEndpoint(settings ? settings.UseDefaultEndpoint : true);
        setEndpoint(settings ? settings.Endpoint : "");
        setUsername(settings ? settings.Username : "");
        setPassword("");

    }

    const handleCancel = () => {
        resetValues(existingSettings, true);
        if (sendMessageToParentFromSettingsPage(search))
            postMessageToParent("settings/pinkweb", "cancelled");
    }

    const handleDelete = () => {
        setShowConfirmModal(false);
        pinkWebSettingService.deleteCredentials()
            .then(() => {
                refreshData();
                showAlert({
                    type: ResponseTypeEnum.Success,
                    message: t("DeleteSuccess", { ns: "pinkweb" }),
                    title: t("Success")
                });
            });
    }

    const NewLoginInfoMessage = () => {
        let message = "";
        const endpointOrUsernameChanged = (existingSettings?.Endpoint !== endpoint || existingSettings?.Username !== username);

        if (password && endpointOrUsernameChanged)
            message = t("PinkWebLoginInfo", { ns: "pinkweb" });
        else if (password)
            message = t("PinkWebPasswordInfo", { ns: "pinkweb" });
        else if (endpointOrUsernameChanged)
            message = t("PinkWebCredentialsInfo", { ns: "pinkweb" })

        return <>{existingSettings && message && <div className="mt-24">{message}</div>}</>;
    }

    return (
        <>
            <div className="d-flex justify-content-between">
                <h1>{t("PinkWebClient", { ns: "pinkweb" })}</h1>
                <div className="pinkweb-logo"></div>
            </div>

            <p>{t("PinkWebInstructions", { ns: "pinkweb" })}</p>

            <div>
                <Checkbox label={t("UsePinkWeb", { ns: "pinkweb" })} isChecked={usePinkWebForVat} onChange={handleUsePinkWeb} />
                <br />
                <small className="text-grey">{t("RememberToClickSave", { ns: "pinkweb" })}</small>
            </div>

            <br />

            <b className="d-block">{t("Endpoint", { ns: "pinkweb" })}</b>

            <div className="ml-8">
                <RadioButton label={t("DefaultEndpoint", { ns: "pinkweb" })} value={"true"} selectedValue={`${useDefaultEndpoint}`} onChange={handleEndpointChange} disabled={!usePinkWebForVat} />
                <br />
                <RadioButton label={t("DifferentEndpoint", { ns: "pinkweb" })} value={"false"} selectedValue={`${useDefaultEndpoint}`} onChange={handleEndpointChange} disabled={!usePinkWebForVat} />
                <br />
            </div>

            <FormInput label={t("Endpoint", { ns: "pinkweb" })}
                value={endpoint}
                onChange={setEndpoint}
                disabled={!usePinkWebForVat || useDefaultEndpoint}
                autocomplete="off" />

            <div className="mb-8 mt-16">{t("Username")}</div>
            <FormInput label={t("Username")}
                value={username}
                onChange={setUsername}
                disabled={!usePinkWebForVat}
                autocomplete="off" />

            <div className="mb-8 mt-16">{t("Password")}</div>
            <FormInput
                label={t("Password")}
                value={password}
                onChange={setPassword}
                isPassword
                placeholder={existingSettings ? "*".repeat(existingSettings.PasswordChecksum) : undefined}
                disabled={!usePinkWebForVat}
                autocomplete="new-password" />

            <NewLoginInfoMessage />

            {existingSettings &&
                <>
                    <div className="mt-24" data-testid="pinkweb-credentials-stats">
                        <Trans
                            i18nKey="CredentialsStatistics"
                            ns={"pinkweb"}
                            values={{ companies: existingSettings.CompaniesAssociated, totalCompanies: existingSettings.TotalCompanies }}
                            components={{ bold: <strong /> }}
                        />
                    </div>

                    {showConfirmModal &&
                        <ConfirmModal title={`${t("Remove")} ${t("Settings").toLowerCase()}`}
                            description={t("RemoveSettingsMessage", { ns: "pinkweb" })}
                            onSuccess={handleDelete}
                            onClose={() => setShowConfirmModal(false)} />
                    }

                    {showViewAll &&
                        <ViewAllCompaniesModal
                            currentCompany={existingSettings.CompanyName}
                            currentEndpoint={existingSettings.Endpoint}
                            refreshData={refreshData}
                            showAlert={showAlert}
                            onClose={ () => { setShowViewAll(false); triggerWootric('settings_modal'); }} />
                    }
                </>
            }

            <div className="mt-24">
                <b className="d-block">{t("Options")}</b>
                <button type="button" className="btn mt-8" disabled={!existingSettings} onClick={() => setShowConfirmModal(true)}>{t("Remove")}</button>
                <button type="button" 
                    className="btn mt-8" 
                    title={t("ViewAllTooltip", { ns: "general" })}
                    disabled={!existingSettings || !existingSettings.UsePinkWebForVat} 
                    onClick={() => setShowViewAll(true)}>
                        {t("ViewAll")}..
                </button>
            </div>

            <br />

            <div className="ar-alert-container">
                {alert}
            </div>

            <div className="d-flex justify-content-end mt-32">
                <button type="button" className="btn btn-primary" disabled={disableSave()} onClick={() => handleSave()}>{t("Save")}</button>
                <button type="button" className="btn" onClick={() => handleCancel()}>{t("Cancel")}</button>
            </div>

        </>
    );
}