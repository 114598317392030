import { MutableRefObject, useEffect, useRef } from "react";
import { ComponentLoadingSpinner } from "./ComponentLoadingSpinner";

const options = {
    root: null,
    threshold: 0
};

export const InfiniteScroller = ({ data, onLoadMore, disabled }: { data: any[], onLoadMore: () => any, disabled: boolean }) => {
    const loader: MutableRefObject<null> = useRef(null);

    useEffect(() => {
        if (!data || data.length === 0)
            return;

        const handleObserver = (entries: any) => {
            const [entry] = entries;
            if (entry.isIntersecting) {
                onLoadMore();
            }
        }

        const observer = new IntersectionObserver(handleObserver, options);
        const currentTarget = loader.current;

        if (currentTarget) {
            observer.observe(currentTarget)
        }

        return () => {
            if (currentTarget) {
                observer.disconnect();
            }
        }
    }, [data]);

    return (
        <>
            {!disabled && <div className="m-8" ref={loader}><ComponentLoadingSpinner /></div>}
        </>
    )
}