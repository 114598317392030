(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("focus-trap-react"), require("react"));
	else if(typeof define === 'function' && define.amd)
		define(["focus-trap-react", "react"], factory);
	else if(typeof exports === 'object')
		exports["ReactNc4"] = factory(require("focus-trap-react"), require("react"));
	else
		root["ReactNc4"] = factory(root["focus-trap-react"], root["react"]);
})(self, function(__WEBPACK_EXTERNAL_MODULE__4537__, __WEBPACK_EXTERNAL_MODULE__8156__) {
return 