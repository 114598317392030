import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import settingsService from "../services/settingsService";
import { getQueryParameter, inIFrame, isApp, postMessageToParent } from "../utils";

/**
 * This is used whenever someone is using us in an iframe
 */
export const DialogCloseButton = ({ dialog, action, className }: { dialog: string, action: string, className?: string }) => {
    const { search } = useLocation();
    const navigate = useNavigate();
    const { t } = useTranslation("general");

    const handleClick = () => {
        const returnUrl = getQueryParameter(search, "returnUrl");

        if (returnUrl) {
            settingsService.verifyOriginUrl(returnUrl)
                .then(() => {
                    window.open(returnUrl, '_parent');
                })
                .catch((error: any) => {
                    if (error?.status === 400)
                        navigate("/error/400");
                    else
                        navigate("/error/500");
                });
        } else {
            postMessageToParent(dialog, action);
        }
    }

    const showCloseButton = (): boolean => {
        var urlParams = new URLSearchParams(search);

        return !isApp(urlParams) && (Boolean(getQueryParameter(search, "returnUrl")) || inIFrame());
    }

    return (
        <>
            {showCloseButton() &&
                <div className={"d-flex justify-content-end " + (className ? className : "")}>
                    <button className="btn mr-32" onClick={handleClick}>{t("Return")}</button>
                </div>
            }
        </>
    )
}