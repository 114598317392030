import * as React from 'react';
import { ChangeEvent, PropsWithChildren, ReactElement, useEffect, useState } from 'react';
import { ClassNameBuilder } from '../utils/ClassNameBuilder';
import { getElementId } from '../utils/getElementId';

export interface ICheckboxProps {
    onChange: (event: ChangeEvent<HTMLInputElement>, isChecked: boolean) => void;
    id?: string;
    className?: string;
    name?: string;
    value?: string | number;
    checked?: boolean;
    disabled?: boolean;
    label?: string | ReactElement;
    ariaLabel?: string | ReactElement;
}

export const Checkbox: React.FC<PropsWithChildren<ICheckboxProps>> & React.HTMLProps<HTMLDivElement> = ({
    id,
    className = '',
    value,
    name,
    label = '',
    ariaLabel = '',
    checked = false,
    disabled = false,
    onChange,
    ...otherProps
}) => {
    const classes = new ClassNameBuilder(className);
    const elementId = getElementId(id);
    const ariaElementId = `${elementId}-aria`;
    const [isChecked, setIsChecked] = useState(checked);

    useEffect(() => {
        setIsChecked(checked);
    }, [checked]);

    classes.add('checkbox');

    return (
        <>
            <div className={classes.build()}>
                <input
                    id={elementId}
                    type="checkbox"
                    name={name}
                    value={value}
                    checked={isChecked}
                    disabled={disabled}
                    onChange={(event) => {
                        setIsChecked(!isChecked);
                        onChange(event, event.target.checked);
                    }}
                    aria-labelledby={ariaElementId}
                    {...otherProps}
                />
                <label htmlFor={elementId}>{label}</label>
                <label className="sr-only" id={ariaElementId}>
                    {ariaLabel}
                </label>
            </div>
        </>
    );
};
