import { PropsWithChildren } from 'react';
import * as React from 'react';
import ClassCollector from '../utils/ClassCollector';
import { NavbarNavMenuLevel } from './enums/NavbarNavMenuLevel';

interface IMenubarProps {
    position?: 'left' | 'right';
    level?: NavbarNavMenuLevel;
    isExpandable?: boolean;
    isMenuDropdown?: boolean;
}

export const NavbarNavMenu = (props: PropsWithChildren<IMenubarProps>) => {
    const classes: string[] = [];

    const isFirstLevel = !props.level || props.level === NavbarNavMenuLevel.first;
    const isThirdLevel = props?.level === NavbarNavMenuLevel.third;

    ClassCollector('dropdown-menu', classes, props.isMenuDropdown || isThirdLevel);
    ClassCollector('dropdown-menu-right', classes, props.isMenuDropdown && isFirstLevel);
    ClassCollector('nav navbar-nav', classes, !props.isMenuDropdown && isFirstLevel);

    ClassCollector(`${NavbarNavMenuLevel[props.level] || 'first'}-level`, classes, !props.isMenuDropdown);
    ClassCollector(`navbar-${props.position}`, classes, props.position?.length > 0);
    ClassCollector('dropdown', classes, props.isExpandable);

    return (
        <>
            <ul role="menubar" className={classes.join(' ')}>
                {props.children}
            </ul>
        </>
    );
};
