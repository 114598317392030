import { useContext, useMemo, KeyboardEvent, useEffect } from "react";
import { useClickOutside } from '../../hooks/ClickOutsideHook';
import { ISettingsCountryOption } from "../../interfaces";
import { CountrySelectionOption } from "./CountrySelectionOption";
import { SettingsContext } from "../../contexts/SettingsContext";
import { handleKeyDown } from "../../utils";
import { useListNavigation } from "../../hooks/ListNavigation";

const options: ISettingsCountryOption[] = [
    { countryCode: "no", icon: "vismaicon vismaicon-flags vismaicon-norway", text: "NorwegianSettings" },
    { countryCode: "fi", icon: "vismaicon vismaicon-flags vismaicon-finland", text: "FinnishSettings" },
    { countryCode: "nl", icon: "vismaicon vismaicon-flags vismaicon-nederlands", text: "DutchSettings" },
];

export const CountrySelection = () => {
    const { defaultCountryCode, countryCode, setCountryCode } = useContext(SettingsContext);

    const [ref, showList, setShowList] = useClickOutside<HTMLDivElement>();
    const { activeItem, setActiveItem, clearState, focusNext, focusPrevious, focusFirst, focusLast } = useListNavigation<ISettingsCountryOption>(options);

    const handleTabKeyDown = (event: KeyboardEvent<HTMLButtonElement>) => {
        if(showList === true)
            handleKeyDown(event, 'Tab', () => setShowList(false));
    }
    
    const handleTabKeyDownMemo  = useMemo(() => handleTabKeyDown, [showList]);

    const handleKeyDownButton = (event: KeyboardEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        handleKeyDown(event, "Enter", () => {
            if(activeItem)
                setCountryCode(activeItem.countryCode);

            setShowList(!showList);
        });
        handleKeyDown(event, 'ArrowDown', () => focusNext());
        handleKeyDown(event, 'ArrowUp', () => focusPrevious());
        handleKeyDown(event, 'Escape', () => setShowList(false));
        handleTabKeyDownMemo(event);
    }
    
    const handleClick = (option: any) => {
        setCountryCode(option.countryCode);
        setShowList(false);
    };

    useEffect(() => {
        if (showList === false)
            setActiveItem(undefined);
    }, [showList])

    return (
        <div className="panel ar-panel p-0 ar-country-selector">
            <div ref={ref}
                className={`dropdown ${showList === true ? "open" : ""} w-100 m-0`}
                data-testid="dropdown-countrycode">
                <button type="button"
                    onClick={() => setShowList(!showList)}
                    className="btn dropdown-toggle w-100 border-0 py-24"
                    data-toggle="dropdown"
                    data-testid="dropdown-toggle-countrycode"
                    aria-haspopup="true"
                    onKeyDown={handleKeyDownButton}
                    aria-expanded={showList}>
                    <CountrySelectionOption
                        option={options.find(o => o.countryCode === countryCode) ?? options[0]}
                        defaultCountryCode={defaultCountryCode} />
                </button>

                <ul className="dropdown-menu w-100" role="menu" aria-hidden={!showList} data-testid="dropdown-list-countrycode">
                    {options.map(o =>
                        <li key={o.countryCode} className={`pr-16 ` + (activeItem?.countryCode === o.countryCode ? "active" : "") }>
                            <a onClick={() => handleClick(o)}>
                                <CountrySelectionOption
                                    option={o}
                                    defaultCountryCode={defaultCountryCode} />
                            </a>
                        </li>
                    )}
                </ul>
            </div>
        </div>
    );
};


