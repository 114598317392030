import { toLower } from "lodash";
import { WootricData } from "../interfaces";
import { IWootricDisclaimer } from "../interfaces/WootricDisclaimer";
import axiosUtils from "../utils/axios";

const loadWootric = (data: WootricData | null, userLanguage: string, disclaimerData: IWootricDisclaimer) => {
    
    loadWootricScript();

    var language = getWootricLanguage(userLanguage);

    if (data != null) {
        initWootricSettings(data, language, disclaimerData);
    }
    else {
        axiosUtils.instance
            .get<WootricData>("api/connectIdpData/userdata")
            .then((response) => {
                if (response.data) {
                    initWootricSettings(response.data, language, disclaimerData);
                }
            })
            .catch((err: any) => console.error("Unable to get connect user info", err));
    }
}

const run = (eventName: string) => {
    if (!window.wootricSettings || !window.wootric){
        return;
    }

    window.wootricSettings.event_name = eventName;
    window.wootric('run');    
    window.wootricSettings.event_name = undefined;
}

function initWootricSettings(data: WootricData, userLanguage: string, disclaimer: IWootricDisclaimer) {
    window.wootricSettings = {
        aria: true,
        email: data.UserId,
        created_at: data.CreatedAt,
        language: userLanguage,
        account_token: 'NPS-EU-28cd6264',
        event_name: undefined,
        disclaimer: {
            text: disclaimer.text,
            link: 'https://www.visma.com/privacy/privacy-statement-inmoment/',
            link_word: disclaimer.linkWord
        },
        properties: {
            env: toLower(process.env.REACT_APP_IS_TEST_ENVIRONMENT) === "true" ? 'test' : 'production',
            groups_array: data.Groups
        }
    };
}

function loadWootricScript() {
  const scriptTag = document.createElement("script");
  scriptTag.src = 'https://cdn.wootric.com/wootric-sdk.js';
  document.head.appendChild(scriptTag);
}

function getWootricLanguage(userLanguage: string): string {
    switch (userLanguage) {
        case "nb":
            return "no";
        case "en":
        case "fi":
        case "sv":
        case "da":
        case "nl":
            return userLanguage;
        default:
            return "en";
    }
}

/**
 * This is a helper function used only for testing
 */
export const clear = () => {
    window.wootric = undefined;
    window.wootricSettings = null;
}

export default {
    loadWootric,
    run
}

