import { ReactNode } from "react";

export const ModalHeader = ({ onClose, children, className }: { onClose: () => any; children?: ReactNode; className?: string; }) => {
    return (
        <div className={`modal-header ${className}`}>
            <button className="close-button" aria-label="Close" onClick={() => onClose()}><span className="close"></span></button>
            {children}
        </div>
    );
};
