import { ICertificateData, IDefaultResponseModel, IDigipoortSettings, IPage, IPageQuery } from '../interfaces';
import axiosUtils from '../utils/axios';

const baseUrl = "api/settings/digipoort"

const getSettings = async (): Promise<IDigipoortSettings> => {
    const result = await axiosUtils.instance.get<IDigipoortSettings>(`${baseUrl}`);
    return result.data;
}

const setVismaCertificate = async (value: boolean): Promise<IDefaultResponseModel> => {
    const result = await axiosUtils.instance.post<IDefaultResponseModel>(`${baseUrl}/vismaCertificate/${value}`);
    return result.data;
}

const uploadPrivateCertificate = async (model: FormData): Promise<IDefaultResponseModel> => {
    const result = await axiosUtils.instance.post<IDefaultResponseModel>(`${baseUrl}/privateCertificate`, model, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
    return result.data;
}

const deletePrivateCertificate = async (): Promise<IDefaultResponseModel> => {
    const result = await axiosUtils.instance.delete<IDefaultResponseModel>(`${baseUrl}/privateCertificate`);
    return result.data;
}

const getSettingsForAvailableCompanies = async (pageQuery: IPageQuery): Promise<IPage<ICertificateData>> => {
    const result = await axiosUtils.instance.get<IPage<ICertificateData>>(`${baseUrl}/companies`, { params: { PageNumber: pageQuery.PageNumber, PageSize: pageQuery.PageSize, ...pageQuery.Filters } });
    return result.data;
}

const copySettingsForCompanies = async (model: any): Promise<IDefaultResponseModel> => {
    const result = await axiosUtils.instance.patch<IDefaultResponseModel>(`${baseUrl}/certificate`, model);
    return result.data;
}

export default {
    getSettings,
    setVismaCertificate,
    uploadPrivateCertificate,
    deletePrivateCertificate,
    getSettingsForAvailableCompanies,
    copySettingsForCompanies
}