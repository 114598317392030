import { KeyboardEvent, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { IApplication } from "../interfaces";
import { handleKeyDown } from "../utils"
import { useClickOutside } from "../hooks/ClickOutsideHook";
import navigationService from "../services/navigationService";
import { useMediaQuery } from "../hooks/MediaQueryHook";
import { ScreenSizeEnum } from "../utils/constants";
import { useTranslation } from "react-i18next";

export const NavBarBrand = (props: { apps: IApplication[] }) => {
    const [ref, showApps, setShowApps] = useClickOutside<HTMLInputElement>();
    const location = useLocation();
    const matches = useMediaQuery(ScreenSizeEnum.LG);
    const { t } = useTranslation("general");

    function handleToggleAppsSection() {
        setShowApps(!showApps);
    }

    function handleKeyDownInternal(event: KeyboardEvent<HTMLDivElement>): void {
        handleKeyDown(event, "Enter", () => setShowApps(true));
        handleKeyDown(event, "Escape", () => setShowApps(false));
    }

    return (
        <>
            <div className="navbar-header" ref={ref} tabIndex={0} onKeyDown={handleKeyDownInternal}>
                <div className={`navbar-brand dropdown ${showApps === true ? "open" : ""}`} id="application-name" data-testid="applications-selector">
                    {matches ? <a className="dropdown-toggle"
                        data-toggle="dropdown"
                        role="button"
                        aria-expanded={showApps}
                        aria-label="Visma.net AutoReport"
                        data-testid="applications-toggle"
                        onClick={handleToggleAppsSection}>
                        Visma.net AutoReport
                        <span className="caret"></span>
                    </a> :
                        <a onClick={handleToggleAppsSection}
                            className="dropdown-toggle"
                            id="nc4navMenuDropTrigger"
                            data-toggle="dropdown"
                            data-testid="applications-toggle"
                            aria-expanded={showApps}
                            href="#" tabIndex={0}>

                            {showApps === true ? <span className="close mr-8"></span> : <span className="vismaicon ar-burger" data-testid="burger-menu"></span>}
                            <span className="">{t("Menu")}</span>
                        </a>
                    }
                    <ul className="dropdown-menu " role="listbox">
                        {props.apps.map((app: IApplication) =>
                            <li key={app.Name}>
                                <a className={`app-item ${app.Selected === true ? "active" : ""}`} href={app.Href} role="option" aria-label={app.Name} aria-selected={app.Selected}>
                                    {app.Name}
                                </a>
                            </li>)
                        }
                    </ul>
                </div>
            </div>
        </>
    )
}