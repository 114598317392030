import React from 'react';
import ReactDOM from 'react-dom';
import './i18n.ts';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { registerLocale } from 'react-datepicker';
import { nb, nl, fi, sv, enGB } from 'date-fns/locale';
import ReactGA from "react-ga4";
import { toLower } from "lodash";

if (toLower(process.env.REACT_APP_GOOGLE_ANALYTICS_ENABLED) === "true" && process.env.REACT_APP_GOOGLE_ANALYTICS_ID) {
  ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID);
}

registerLocale('fi', fi);
registerLocale('nb', nb);
registerLocale('nl', nl);
registerLocale('sv', sv);
registerLocale('en-GB', enGB);

ReactDOM.render(
  <React.StrictMode>
    <React.Suspense>
      <App />
    </React.Suspense>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
