import { toLower } from "lodash";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useOutletContext } from "react-router-dom";
import { ComponentLoadingSpinner } from "../../components";
import { Tabs } from "../../components/Tabs";
import { IContext, IDigipoortSettings, IPinkWebSettings, ITab } from "../../interfaces";
import digipoortService from "../../services/digipoortService";
import pinkWebSettingService from "../../services/pinkWebSettingService";
import { DigipoortInfo, DigipoortSettings } from "./digipoort";
import { PinkWebInfo, PinkWebSettings } from "./pinkweb";
import { displayGoBackButton, redirectToOriginUrl } from "../../utils";
import { CountrySelection } from "./CountrySelection";
import { sendEvent } from "../../hooks/GoogleAnalyticsHook";

export const NetherlandSettingsPage = () => {
    const { t } = useTranslation(["general", "pinkweb", "digipoort"]);
    const currentCompany = useOutletContext<IContext>();
    const [showLoading, setShowLoading] = useState<boolean>(true);

    const [digipoortData, setDigipoortData] = useState<IDigipoortSettings>({} as IDigipoortSettings);
    const [pinkWebData, setPinkWebData] = useState<IPinkWebSettings | undefined>({} as IPinkWebSettings);

    const { search } = useLocation();

    useEffect(() => {
        sendEvent("settings_netherland", "Open netherlands settings page.")
        fetchDigipoortData();
        fetchPinkWebData();
    }, [currentCompany])

    const fetchDigipoortData = () => {
        setShowLoading(true);
        digipoortService.getSettings().then((data: IDigipoortSettings) => {
            setDigipoortData(data);
            setShowLoading(false);
        })
    }

    const fetchPinkWebData = () => {
        setShowLoading(true);
        pinkWebSettingService.getSettings()
            .then((response) => {
                setShowLoading(false);
                setPinkWebData(response);
            });
    }

    const getDigipoorCertificateUsed = () => {
        if (!digipoortData.HasDigipoortSettings)
            return "n/a";

        var certificateType = `${t("Digipoort", { ns: "digipoort" })} - `;
        if (digipoortData.VismaCorporateCertificateActivated)
            certificateType += t("Visma");
        else if (digipoortData.PrivateCertificate.CommonName)
            certificateType += t("Private");

        certificateType += ` ${toLower(t("Certificate"))}`;
        return certificateType;
    }

    const tabs: ITab[] = [
        { title: t("Digipoort", { ns: "digipoort" }), content: <DigipoortSettings data={digipoortData} refreshData={fetchDigipoortData} /> },
        { title: t("PinkWebClient", { ns: "pinkweb" }), content: <PinkWebSettings data={pinkWebData} refreshData={fetchPinkWebData} /> }
    ];
    const [activeTab, setActiveTab] = useState(tabs[0].title);

  
    

    return (
        <div className="ar-settings-container">
            <div className="panel ar-panel ar-settings-left">
                {displayGoBackButton(search) && <button type="button" className="btn mb-32" data-testid="return-button" onClick={() => redirectToOriginUrl(search)}>{t("GoBack")}</button>}
                {showLoading && <ComponentLoadingSpinner />}
                <h3>{t("SettingsSelection")}</h3>
                <Tabs setActiveTab={setActiveTab}>
                    {tabs}
                </Tabs>
            </div>
            <div className="ar-settings-right">
                <CountrySelection />
                {activeTab === t("PinkWebClient", { ns: "pinkweb" }) && <PinkWebInfo />}
                {activeTab === t("Digipoort", { ns: "digipoort" }) && <DigipoortInfo />}
                <div className="panel ar-panel">
                    <div className="d-flex align-items-center mb-16">
                        <span className="vismaicon ar-info" aria-hidden="true"></span>
                        <h2 className="ml-16">{t("Overview")}</h2>
                    </div>
                    <span data-testid="wagelevies-overview">{t("WageLevies")} : <b>{getDigipoorCertificateUsed()}</b></span>
                    <span data-testid="vat-and-euvat-overview" className="d-block mt-16">{t("VatAndEuVat")} :
                        <b> {pinkWebData && pinkWebData.UsePinkWebForVat ?
                            t("PinkWeb", { ns: "pinkweb" }) :
                            getDigipoorCertificateUsed()}</b>
                    </span>
                </div>
            </div>
        </div>);
}