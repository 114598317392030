import { useTranslation } from "react-i18next";
import { IPrivateCertificate } from "../../../../interfaces";

export const DigipoortPrivateCertificateData = (certificate: IPrivateCertificate) => {
    const { t } = useTranslation(["general", "digipoort"]);
    
    return (
        <>
            <div className="row">
                <div className="col col-5">
                    {t("CurrentCertificate")}
                </div>
                <div className="col col-7" data-testid="common-name">
                    {certificate.CommonName ? certificate.CommonName : "n/a"}
                </div>
            </div>
            <div className="row mt-16">
                <div className="col col-5">
                    {t("SerialNumber")}
                </div>
                <div className="col col-7" data-testid="serial-number">
                    {certificate.SerialNumber ? certificate.SerialNumber : "n/a"}
                </div>
            </div>
            <div className="row mt-16">
                <div className="col col-5">
                    {t("ExpirationDate")}
                </div>
                <div className="col col-7" data-testid="expiration-date">
                    {certificate.ExpirationDate ? certificate.ExpirationDate : "n/a"}
                </div>
            </div>
        </>
    )
}