import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { DropdownButton, Modal, ModalBody, ModalFooter, ModalHeader } from "../../components"
import { FormInput } from "../../components/FormInput";
import { ApplicationContext } from "../../contexts/ApplicationContext";
import { IChangeStatusModel, IDefaultResponseModel } from "../../interfaces";
import reportService from "../../services/reportService";
import { ResponseTypeEnum } from "../../utils/constants";

export const ChangeReportStatusModal = ({ reportId, onClose, refreshData }: { reportId: string, onClose: () => void, refreshData: () => void }) => {
    const { t } = useTranslation("general");
    const [data, setData] = useState<IChangeStatusModel>();
    const [newStatus, setNewStatus] = useState<string>();
    const [statusMessage, setStatusMessage] = useState<string>("");
    const { showToast } = useContext(ApplicationContext);

    useEffect(() => {
        reportService.getChangeStatusModel(reportId)
            .then((data: IChangeStatusModel) => {
                setData(data);
            });
    }, [])

    const handleChangeStatus = (value: string) => {
        setNewStatus(value);
    }

    const handleUpdate = () => {
        if (newStatus)
            reportService.changeReportStatus(reportId, newStatus, statusMessage)
                .then((response: IDefaultResponseModel) => {
                    if (response.ResponseType === ResponseTypeEnum.Success) {
                        onClose();
                        refreshData();
                    }
                    if (response.ResponseType === ResponseTypeEnum.Danger) {
                        showToast({ message: response.Message, type: response.ResponseType });
                    }
                });
        else
            showToast({ message: "Please choose a new status", type: ResponseTypeEnum.Info });
    }

    return (
        <> {data &&
            <Modal className="change-status-modal ar-modal modal-lg">
                <ModalHeader onClose={onClose}>
                    <h1 className="modal-title">{t("ChangeStatus")}</h1>
                </ModalHeader>
                <ModalBody>
                    <div>
                        <div className="row">
                            <div className="col-4">
                                <p>{t("ReportType")}:</p>
                            </div>
                            <div className="col-8">
                                <p>{data.ReportType}</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-4">
                                <p>{t("TaxRegistrationNumber")}:</p>
                            </div>
                            <div className="col-8">
                                <p>{data.TaxRegistrationNumber}</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-4">
                                <p>{t("Period")}:</p>
                            </div>
                            <div className="col-8">
                                <p>{data.PeriodStart} {t("PeriodSeparator")} {data.PeriodEnd}</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-4">
                                <p>{t("CurrentStatus")}:</p>
                            </div>
                            <div className="col-8">
                                <p>{data.Status}</p>
                            </div>
                        </div>
                        <div className="row new-status-row">
                            <div className="col-4">
                                <p>{t("NewStatus")}:</p>
                            </div>
                            <div className="col-8">
                                <DropdownButton options={data.Statuses} value={newStatus} onChange={handleChangeStatus} />
                            </div>
                        </div>
                        <div className="row new-status-row">
                            <div className="col-4">
                                <p>{t("StatusMessage")}:</p>
                            </div>
                            <div className="col-8">
                                <FormInput value={statusMessage} onChange={(newValue: string) => setStatusMessage(newValue)} label={t("StatusMessage")} />
                            </div>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter className="d-flex justify-content-end">
                    <div className="row">
                        <div className="col-12">
                            <button className="btn" data-testid="change-report-status" onClick={handleUpdate}>{t("Update")}</button>
                            <button className="btn btn-primary" data-testid="exit-change-status-modal" onClick={onClose}>{t("Close")}</button>
                        </div>
                    </div>
                </ModalFooter>
            </Modal>
        }
        </>
    )
}