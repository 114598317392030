import { IKatreAuthenticationModel } from '../interfaces';
import axiosUtils from '../utils/axios';

const baseUrl = "api/katre"

const getAuthenticationModel = async (reportId: string): Promise<IKatreAuthenticationModel> => {
    const result = await axiosUtils.instance.get<IKatreAuthenticationModel>(`${baseUrl}/authenticationModel/${reportId}`);
    return result.data;
}

export default{
    getAuthenticationModel
}