import { IDefaultResponseModel } from '../interfaces';
import { IAltinnTaxCardData, IAltinnTaxCardDownloadData } from '../interfaces/TaxCard';
import axiosUtils from '../utils/axios';

const baseUrl = "api/altinnTaxCard"

const getTaxCardData = async (taxCardId: string): Promise<IAltinnTaxCardData> => {
    const result = await axiosUtils.instance.get<IAltinnTaxCardData>(`${baseUrl}/${taxCardId}`);
    return result.data;
}

const sendTaxCard = async (taxCardId: string): Promise<IDefaultResponseModel> => {
    const result = await axiosUtils.instance.post<IDefaultResponseModel>(`${baseUrl}/send/${taxCardId}`);
    return result.data;
}

const getTaxCardDownloadData = async (taxCardId: string): Promise<IAltinnTaxCardDownloadData> => {
    const result = await axiosUtils.instance.get<IAltinnTaxCardDownloadData>(`${baseUrl}/downloadData/${taxCardId}`);
    return result.data;
}

const downloadCompleted = async (taxCardId: string, authenticationData: any): Promise<IDefaultResponseModel> => {
    const result = await axiosUtils.instance.post<IDefaultResponseModel>(`${baseUrl}/downloadCompleted/${taxCardId}`, authenticationData);
    return result.data;
}

const resendStatus = async (taxCardId: string): Promise<IDefaultResponseModel> => {
    const result = await axiosUtils.instance.post<IDefaultResponseModel>(`${baseUrl}/resendStatus/${taxCardId}`);
    return result.data;
}

export default {
    getTaxCardData,
    sendTaxCard,
    getTaxCardDownloadData,
    downloadCompleted,
    resendStatus
}