import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import aboutService from "../../services/aboutService";

export const AboutPage = () => {
    const { t } = useTranslation("general");
    const [version, setVersion] = useState<string>("");

    useEffect(() => {
        aboutService.getVersion()
            .then(version => setVersion(version));
    }, [])

    return (
        <div className="ar-page-container container">
            <div className="panel panel-default mt-32">
                <div className="panel-body">
                    <div className="row">
                        <div className="col-lg-4 col-md-5 col-sm-12 col-xs-12 align-right">
                            <div className="d-flex justify-content-end">
                                <img className="ar-logo" alt="AutoReport Logo" />
                            </div>
                        </div>
                        <div className="col-lg-8 col-md-7 col-sm-12 col-xs-12">
                            <div className="copy-right-text">
                                <h2 className="mb-8 mt-0">Visma.net AutoReport</h2>
                                <h3 className="mb-8 mt-0">{t("Version")}: {version}</h3>
                                <ul className="no-bottom-margin list-unstyled">
                                    <li>&copy; {new Date().getFullYear()} Visma</li>
                                    <li>{t("AboutMessage1")}</li>
                                    <li>{t("Click")} <a href="/3rdpartycomponents">{t("Here").toLocaleLowerCase()}</a> {t("AboutMesssage2")}.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}