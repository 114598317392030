import { toLower } from "lodash";
import { FormEvent, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { Checkbox, FormInput } from "../../../../components"
import { DigipoortSettingsContext } from "../../../../contexts/DigipoortSettingsContext";
import { IDefaultResponseModel, IDigipoortPrivateCertificateForm } from "../../../../interfaces";
import digipoortService from "../../../../services/digipoortService";
import { postMessageToParent, redirectToOriginUrl, sendMessageToParentFromSettingsPage, triggerWootric } from "../../../../utils";
import { ResponseTypeEnum } from "../../../../utils/constants";

export const DigipoortPrivateCertificateForm = ({ hasPrivateCertificate, certificate, fileInput, setFileName, setShowLoading }: IDigipoortPrivateCertificateForm) => {
    const { t } = useTranslation(["general", "digipoort"]);
    const { search } = useLocation();

    const initialPassword = certificate.PasswordLength > 0 ? "*".repeat(certificate.PasswordLength) : "";
    const [certificatePassword, setCertificatePassword] = useState<string>(initialPassword);
    const [rememberPassword, setRememberPassword] = useState(certificate.RememberPassword);

    const [fileSelected, setFileSelected] = useState(false);

    const { showAlert, refreshData, clearAlert } = useContext(DigipoortSettingsContext);
    const disableSaveAndCancel = certificate.Deactivated ||
        (!fileSelected && rememberPassword === certificate.RememberPassword) ||
        (fileSelected && certificatePassword.length === 0);

    const disablePassword = !fileSelected;
    const disableRememberPassword = certificate.Deactivated || (!fileSelected && !hasPrivateCertificate);

    useEffect(() => {
        resetPasswordAndRememberPassword();
    }, [certificate])

    const resetData = () => {
        resetPasswordAndRememberPassword();
        resetFileData();
    }

    const resetPasswordAndRememberPassword = () => {
        setCertificatePassword(initialPassword);
        setRememberPassword(certificate.RememberPassword);
    }

    const resetFileData = () => {
        setFileSelected(false);
        setFileName("");
    }

    const clearPasswordAndRememberPassword = () => {
        setRememberPassword(false);
        setCertificatePassword("");
    }

    const handleFileUpload = (event: any) => {
        if (event.target.files.length > 0) {
            clearPasswordAndRememberPassword();
            setFileSelected(true);
            setFileName(event.target.files[0].name);
        }
        else
            resetData();
    };

    const handleSave = (event: FormEvent) => {
        event.preventDefault();
        setShowLoading(true);

        let formData = new FormData();
        formData.append("RememberPassword", `${rememberPassword}`);

        if (fileInput?.current?.files != null && fileInput.current.files.length > 0) {
            formData.append("Certificate", fileInput.current.files[0]);
            formData.append("Password", certificatePassword);
        }

        digipoortService.uploadPrivateCertificate(formData)
            .then((data: IDefaultResponseModel) => {
                setShowLoading(false);
                showAlert({ message: data.Message, type: data.ResponseType, title: data.Title });

                if (data.ResponseType === ResponseTypeEnum.Success) {
                    if (fileInput?.current?.files != null && fileInput.current.files.length > 0) {
                        fileInput.current.value = "";
                    }
                    resetFileData();
                    refreshData();
                    redirectToOriginUrl(search);
                    if (sendMessageToParentFromSettingsPage(search))
                        postMessageToParent("settings/digipoort", "sent");
                }
            })
        
        triggerWootric('settings_save');
    }

    const handleCancel = (event: FormEvent) => {
        event.preventDefault();
        resetData();
        if (fileInput?.current?.files != null && fileInput.current.files.length > 0) {
            fileInput.current.value = "";
        }
        if (sendMessageToParentFromSettingsPage(search))
            postMessageToParent("settings/digipoort", "cancelled");
    }

    return (
        <form>
            <input
                ref={fileInput}
                onChange={handleFileUpload}
                type="file"
                className="d-none"
                data-testid="file-input"
                multiple={false} />
            <div className="mt-32">
                <span className="d-block mb-8">{`${t("Certificate")} ${toLower(t("Password"))}`}</span>
                <FormInput
                    value={certificatePassword}
                    disabled={disablePassword}
                    onChange={(newVal: string) => { setCertificatePassword(newVal) }}
                    isPassword={true}
                    autocomplete="new-password"
                    label={`${t("Certificate")} ${toLower(t("Password"))}`} />
            </div>
            <div className="mt-32">
                <Checkbox
                    label={t("RememberPassword")}
                    disabled={disableRememberPassword}
                    onChange={(newVal: boolean) => setRememberPassword(newVal)}
                    isChecked={rememberPassword} />
            </div>
            <div className="d-flex justify-content-end mt-32">
                <button className="btn btn-primary"
                    data-testid="save-button"
                    disabled={disableSaveAndCancel}
                    onClick={handleSave}>{t("Save")}</button>
                <button className="btn"
                    data-testid="cancel-button"
                    disabled={disableSaveAndCancel}
                    onClick={handleCancel}>{t("Cancel")}</button>
            </div>
        </form>
    )
}