import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import wootricService from "../services/wootricService";
import { WootricData } from "../interfaces";

export const Wootric = ({data}: {data: WootricData}) => {
    const { i18n, t } = useTranslation("general");

    useEffect(() => {
        if (!window.wootric) {
            wootricService.loadWootric(data, i18n.language, 
                { text: t("WootricDisclaimerText"), linkWord: t("WootricDisclaimerLinkWord") });
        }
    }, []);

    return (
        <div/>
    );
};