import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ReportDetailsContent } from "../reports";
import "../../styles/reportDetails.css"
import { ReportDetailsContainerEnum } from "../../utils/constants";
import { DialogCloseButton } from "../../components/DialogCloseButton";
import { sendEvent } from "../../hooks/GoogleAnalyticsHook";

export const ReportDetailsPage = () => {

    const [reportId, setReportId] = useState<string>();
    const params = useParams();
    const referenceId = params.id;

    const [action, setAction] = useState<string>("cancelled");

    useEffect(() => {
        if (referenceId)
            setReportId(referenceId);

       sendEvent('report_details', 'Open report details page.'); 
    }, [referenceId])

    return (<>
        <div className="ar-page-container d-flex flex-column pl-16 pr-16">
            <div className="ar-report-details-container">
                {reportId &&
                    <><ReportDetailsContent
                        referenceId={reportId}
                        expanded={true}
                        setAction={setAction}
                        detailsContainer={ReportDetailsContainerEnum.DetailsPage} />
                        <div className="mt-24">
                            <DialogCloseButton action={action} dialog="uploadrequestauthentication/authenticate" />
                        </div>
                    </>
                }
            </div>
        </div>
    </>);
}