import { Panel, PanelBody, PanelFooter } from "@vismaux/react-nc4";
import { toLower } from "lodash";
import { Suspense, useState } from "react";
import { useTranslation } from "react-i18next";
import { Alert, ComponentLoadingSpinner } from "../../components";
import { ReportExpandedRowContext } from "../../contexts/ReportExpandedRowContext";
import { IDefaultResponseModel, IReportActions, ISendReportButtonProps } from "../../interfaces";
import { UploadRequestAuthentication } from "./authentication/UploadRequestAuthentication";
import { ChangeReportStatusModal } from "./ChangeReportStatusModal";
import { ConfirmCancelReportModal } from "./ConfirmCancelReportModal";
import reportService from "../../services/reportService";
import { ReportDetailsContainerEnum, ResponseTypeEnum } from "../../utils/constants";
import { ActionButton } from "../../components/ActionButton";
import { useNavigate, useSearchParams } from "react-router-dom";
import { isApp, triggerWootric } from "../../utils";
import { useAlert } from "../../hooks/AlertHook";
import "../../styles/buttons.css"

export const ReportActionsPanel = ({ referenceId, reportActions, detailsContainer, refreshData }:
    {
        referenceId: string,
        reportActions: IReportActions,
        detailsContainer: ReportDetailsContainerEnum,
        refreshData: () => void,
    }) => {
    const { t } = useTranslation(["general", "altinn"]);
    const [showConfirmCancelModal, setShowConfirmCancelModal] = useState<boolean>(false);
    const [showChangeStatusModal, setShowChangeStatusModal] = useState<boolean>(false);
    const [sendButtonProps, setSendButtonProps] = useState<ISendReportButtonProps>();
    const [disableCancel, setDisableCancel] = useState<boolean>(false);
    const [searchParams] = useSearchParams({});
    const [alert, showAlert, clearAlert] = useAlert();
    const navigate = useNavigate();

    const handleCancelReport = () => {
        setShowConfirmCancelModal(true);
    }

    const handleChangeStatus = () => {
        setShowChangeStatusModal(true);
    }

    const handleResend = (reportId: string) => {
        setDisableCancel(true);
        var promise = reportService.resendReport(reportId);
        return promise;
    }

    const resendReportCallback = (data: IDefaultResponseModel) => {
        if (data.ResponseType === ResponseTypeEnum.Success)
            refreshData();

        setDisableCancel(false);
    }

    const handleSend = (reportId: string) => {
        if (!sendButtonProps)
            return;

        return reportService.sendReport(reportId, reportActions.SscName, sendButtonProps.getAuthenticationData());
    }

    const sendReportOnErrorCallback = (error: any) => {
        triggerWootric('report_send');
        if (error?.status === 412)
            refreshData();
        else
            navigate("/error/500");
    }

    const sendReportCallback = (data: IDefaultResponseModel) => {
        triggerWootric('report_send');
        if (data.ResponseType === ResponseTypeEnum.Danger)
            showAlert({ type: ResponseTypeEnum.Danger, message: data.Message, title: data.Title, sticky: true });
        else
            clearAlert();

        refreshData();
        if (sendButtonProps?.refreshAuthenticationComponent)
            sendButtonProps.refreshAuthenticationComponent();
    }

    return (
        <ReportExpandedRowContext.Provider value={{ setSendButtonProps }}>
            {reportActions.NeedsAuthentication && reportActions.MissingBusinessLogin &&
                <Alert alert={{
                    type: ResponseTypeEnum.Danger,
                    title: t("MissingBusinessLogin", { ns: "altinn" }),
                    message: '',
                    sticky: true,
                }} />
            }
            <Panel className="rounded-10 report-authentication-panel mb-0">
                <Suspense fallback={<ComponentLoadingSpinner />}>
                    {reportActions.MissingBusinessLogin &&
                        <UploadRequestAuthentication reportId={referenceId} sscName={reportActions.SscName} />
                    }
                    {!reportActions.MissingBusinessLogin && 
                        <PanelBody>
                            {reportActions.NeedsAuthentication && <UploadRequestAuthentication reportId={referenceId} sscName={reportActions.SscName} />}
                            {alert && <div className="p-16">{alert}</div>}
                        </PanelBody>
                    }
                    <PanelFooter>
                        <div className="d-flex ar-gap ar-flex-wrap">
                            {reportActions.NeedsAuthentication &&
                                <>
                                    {!sendButtonProps && <button className="btn btn-primary disabled"><ComponentLoadingSpinner /></button>}
                                    {sendButtonProps &&
                                        <ActionButton onclick={handleSend}
                                            onErrorCallback={sendReportOnErrorCallback}
                                            payload={referenceId}
                                            text={sendButtonProps.text}
                                            title={sendButtonProps.title}
                                            iconClassName="vismaicon vismaicon-sm ar-send"
                                            buttonClassName="btn-primary"
                                            callback={sendReportCallback}
                                            disabled={sendButtonProps.disabled}
                                            dataTestId="send-button" />
                                    }
                                </>
                            }
                            {reportActions.NeedsResend &&
                                <ActionButton onclick={handleResend}
                                    payload={referenceId}
                                    text={t("RetrySending")}
                                    title={t("RetrySendingTooltip")}
                                    iconClassName="vismaicon vismaicon-sm ar-send"
                                    buttonClassName="btn-primary"
                                    callback={resendReportCallback}
                                    dataTestId="resend-button" />
                            }
                            {reportActions.AuthorizationLink &&
                                <a className="mr-8 d-flex align-items-center ar-link-button" 
                                    href={reportActions.AuthorizationLink} 
                                    data-testid="authorization-link" 
                                    target="_blank" 
                                    title={t("ApproveInSkatTooltip")}
                                    rel="noreferrer">
                                        <span className="vismaicon vismaicon-sm vismaicon-dynamic vismaicon-export"></span>
                                        {t("ApproveInSkat")}..
                                </a>
                            }
                            {reportActions.IsArchived &&
                                <ActionButton onclick={reportService.unarchiveReport}
                                    payload={referenceId}
                                    text={t("Unarchive")}
                                    title={t("UnarchiveTooltip")}
                                    iconClassName="vismaicon vismaicon-sm ar-archive"
                                    callback={refreshData}
                                    dataTestId="unarchive-report" />
                            }
                            {!reportActions.IsArchived &&
                                <ActionButton onclick={reportService.archiveReports}
                                    payload={new Set([referenceId])}
                                    text={t("Archive")}
                                    title={t("ArchiveTooltip")}
                                    iconClassName="vismaicon vismaicon-sm ar-archive"
                                    callback={refreshData}
                                    dataTestId="archive-report" />
                            }
                            {reportActions.IsCancelAvailable &&
                                <ActionButton onclick={handleCancelReport}
                                    text={t("Cancel", { ns: "general" })}
                                    title={t("CancelReportTooltip")}
                                    iconClassName="ar-close close mr-8 mr-0"
                                    dataTestId="cancel-report"
                                    disabled={disableCancel} />
                            }
                            {reportActions.IsDownloadAvailable && reportActions.DownloadOutputReportUrl &&
                                <a className="btn" href={reportActions.DownloadOutputReportUrl} data-testid="download-report" title={t("DownloadReportTooltip")}>
                                    <span className="vismaicon vismaicon-sm ar-download"></span>
                                    {t("DownloadReport")}
                                </a>
                            }
                            {reportActions.StatusDescriptionAvailable && reportActions.DownloadFeedbackUrl &&
                                <a className="btn" href={reportActions.DownloadFeedbackUrl} data-testid="download-feedback" title={t("DownloadFeedbackTooltip")}>
                                    <span className="vismaicon vismaicon-sm ar-download"></span>
                                    {t("DownloadFeedback")}
                                </a>
                            }

                            {toLower(process.env.REACT_APP_IS_TEST_ENVIRONMENT) === "true" &&
                                <ActionButton onclick={handleChangeStatus}
                                    text={t("ChangeStatus")}
                                    iconClassName="vismaicon vismaicon-sm ar-replace"
                                    dataTestId="change-report-status" />
                            }
                            {reportActions.IsCheckStatusAvailable &&
                                <ActionButton onclick={refreshData}
                                    text={t("CheckStatus")}
                                    title={t("CheckReportStatusTooltip")}
                                    buttonClassName="btn-primary"
                                    iconClassName="vismaicon vismaicon-sm vismaicon-refresh"
                                    dataTestId="check-status" />
                            }
                            {detailsContainer === ReportDetailsContainerEnum.DetailsPage && isApp(searchParams) &&
                                <a className="btn" href="/report" data-testid="go-to-reports" title={t("GoToReportsTooltip")}>
                                    <span className="vismaicon vismaicon-sm vismaicon-list-view-1"></span>
                                    {t("GoToReports")}..
                                </a>
                            }
                        </div>
                    </PanelFooter>
                    {showConfirmCancelModal && <ConfirmCancelReportModal reportId={referenceId} onClose={() => { setShowConfirmCancelModal(false); }} refreshData={refreshData} />}
                    {showChangeStatusModal && <ChangeReportStatusModal reportId={referenceId} onClose={() => { setShowChangeStatusModal(false); }} refreshData={refreshData} />}
                </Suspense>
            </Panel>
        </ReportExpandedRowContext.Provider>
    );
};