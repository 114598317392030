import { useEffect, useState } from "react";
import { Navbar, NavbarNav, NavbarNavMenu } from "@vismaux/react-nc4";
import '../styles/navigation.css';
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { NavBarBrand } from "./NavBarBrand"
import { IContext, IMenuData } from "../interfaces";
import { NavBarUserMenu } from "./NavBarUserMenu";
import { NavBarTab } from "./NavBarTab";
import { toLower } from "lodash";

export const NavBar = ({ menuData, setCurrentCompany }: { menuData: IMenuData, setCurrentCompany: (context: IContext) => void }) => {
    const { t } = useTranslation("general");
    const [activeMenuItem, setActiveMenuItem] = useState<string>("report");
    const location = useLocation();

    useEffect(() => {
        const urlEndPart = location.pathname.split("/").pop();
        setActiveMenuItem(urlEndPart || "report");
    }, [location.pathname])

    return (
        <>
            <Navbar>
                <NavbarNav>
                    <NavBarBrand apps={menuData.Applications} />
                    <NavbarNavMenu>
                        <NavBarTab path="report" title={t("Reports")} activeMenuItem={activeMenuItem} />
                        <NavBarTab path="settings" title={t("Settings")} activeMenuItem={activeMenuItem} />
                        {menuData.IsAdmin &&
                            <li role="menuitem">
                                <a href={process.env.REACT_APP_ADMINPAGE_LINK} target="_blank" rel="noopener noreferrer">Admin</a>
                            </li>
                        }
                    </NavbarNavMenu>

                    <ul role="menubar" className="nav navbar-nav first-level navbar-right">
                        <li className="icon">
                            <a className="button-icon" href={process.env.REACT_APP_DOCUMENTATION_URL} role="button" aria-label={t("Help")} title={t("Help")} data-testid="help-button" target="_blank" rel="noopener noreferrer">
                                <span className="vismaicon vismaicon-dynamic vismaicon-menu vismaicon-help"></span>
                            </a>
                        </li>

                        <li className="icon">
                            <a className="button-icon" href={process.env.REACT_APP_FEEDBACK_FORM_URL} role="button" aria-label={t("Feedback")} title={t("Feedback")} data-testid="feedback-button">
                                <span className="vismaicon vismaicon-dynamic vismaicon-menu ar-feedback"></span>
                            </a>
                        </li>

                        <NavBarUserMenu {...menuData.UserMenuData} setCurrentCompany={setCurrentCompany} />
                    </ul>
                </NavbarNav>
            </Navbar>
        </>
    );
}

