import { useTranslation } from "react-i18next";
import { FetchFeedbackReport } from "../../../interfaces";
import { toLocaleDateString } from "../../../utils";

export const FetchFeedbackTable = ({ title, reports }: { title: string, reports: FetchFeedbackReport[] }) => {
    const { t } = useTranslation("general");

    return (
        <>
            {reports?.length > 0 &&
                <div>
                    <div className="ar-bold mb-24">{title}</div>
                    <table className="table">
                        <thead>
                            <tr>
                                <th>{t("TaxRegistrationNumber")}</th>
                                <th>{t("Period")}</th>
                                <th>{t("SentOn")}</th>
                                <th>{t("ArchiveReference")}</th>
                                <th>{t("Status")}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {reports.map((report: any) => (
                                <tr key={report.ReferenceId}>
                                    <td>{report.TaxRegistrationNumber}</td>
                                    <td>{toLocaleDateString(report.PeriodStart)} - {toLocaleDateString(report.PeriodEnd)}</td>
                                    <td>{toLocaleDateString(report.SentOn)}</td>
                                    <td>{report.ArchiveReference}</td>
                                    <td>{report.FetchStatus}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            }
        </>
    );
};
