import { useTranslation } from "react-i18next";
import { ISettingsCountryOption } from "../../interfaces";

export const CountrySelectionOption = ({ option, defaultCountryCode }: { option: ISettingsCountryOption; defaultCountryCode: string; }) => {
    const { t } = useTranslation("general");

    return (
        <div className="d-flex align-items-center justify-content-left">
            <span data-testid="country-selection-icon" className={`${option.icon} mr-8`}></span>
            <span data-testid="country-selection-text">{`${t(option.text)}${option.countryCode === defaultCountryCode ? ` - ${t("Default")}` : ""}`}</span>
        </div>
    );
};
