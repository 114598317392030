import { IPreset, IUserAuthentication } from "../interfaces";
import { addDays, addMonths } from "./utils";

export enum PresetsEnum {
    AllDates = "AllDates",
    Today = "Today",
    Last7Days = "Last7Days",
    Last14Days = "Last14Days",
    Last30Days = "Last30Days",
    Last3Months = "Last3Months",
    Last12Months = "Last12Months",
    Custom = "Custom",
}

export enum ReportStatusGroup {
    Received = "Received",
    ErrorInReport = "ErrorInReport",
    Canceled = "Canceled",
    InProgress = "InProgress",
    ErrorLogOn = "ErrorLogOn",
    SendingFailed = "SendingFailed",
    Completed = "Completed",
}

export enum ResponseTypeEnum {
    Success = "success",
    Info = "info",
    Warning = "warning",
    Danger = "danger"
}

export enum AuthenticationActionEnum {
    Authenticate = "authenticate",
    Logout = "logout"
}

export enum ScreenSizeEnum {
    SM = "sm",
    MD = "md",
    LG = "lg",
    XL = "xl"
}

export const PRESETS: IPreset[] = [
    { Id: PresetsEnum.AllDates, Interval: {} },
    { Id: PresetsEnum.Today, Interval: { PeriodStart: new Date(), PeriodEnd: new Date() } },
    { Id: PresetsEnum.Last7Days, Interval: { PeriodStart: addDays(new Date(), -7), PeriodEnd: new Date() } },
    { Id: PresetsEnum.Last14Days, Interval: { PeriodStart: addDays(new Date(), -14), PeriodEnd: new Date() } },
    { Id: PresetsEnum.Last30Days, Interval: { PeriodStart: addDays(new Date(), -30), PeriodEnd: new Date() } },
    { Id: PresetsEnum.Last3Months, Interval: { PeriodStart: addMonths(new Date(), -3), PeriodEnd: new Date() } },
    { Id: PresetsEnum.Last12Months, Interval: { PeriodStart: addMonths(new Date(), -12), PeriodEnd: new Date() } },
    { Id: PresetsEnum.Custom, Interval: {} }
];

export enum ReportDetailsContainerEnum {
    ReportTable,
    DetailsPage
}

export enum ReportStepEnum {
    CompletedAndSigned = "CompletedAndSigned",
    Completed = "Completed",
    NotCompleted = "NotCompleted"
}

export const DefaultAltinnUserAuthentication: IUserAuthentication = { StoreUserAuthentication: true, StoredCredentialsExpired: false, PasswordHasChanged: false } as IUserAuthentication;

export enum TaxCardType {
    Altinn,
    AnyTaxCard
}

export enum AltinnUserAuthenticationContextEnum {
    SendReport,
    DownloadReportFeedback,
    DownloadAltinnTaxCard
}