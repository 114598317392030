import * as React from 'react';
import { ReactElement } from 'react';

interface INoDataProps {
    title: string | ReactElement;
    description?: string | ReactElement;
}

export const NoData: React.FC<INoDataProps> = ({ title, description, ...others }) => {
    return (
        <div className="text-center w-100">
            <div className="ec-not-found__image" {...others}>
                <svg xmlns="http://www.w3.org/2000/svg" width="96" height="95.998" viewBox="0 0 96 95.998">
                    <path
                        d="M1680.763,4519a1.626,1.626,0,0,1-1.627-1.627v-6.508h-6.51a1.627,1.627,0,0,1-1.627-1.627v-84.609a1.627,1.627,0,0,1,1.627-1.627h66.711a1.626,1.626,0,0,1,1.627,1.627v6.508h6.508a1.627,1.627,0,0,1,1.629,1.627v16.271a1.628,1.628,0,0,1-3.256,0v-14.644H1682.39v81.355h63.456v-8.135a1.628,1.628,0,0,1,3.256,0v9.762a1.627,1.627,0,0,1-1.629,1.627Zm-6.508-11.389h4.881v-74.847a1.626,1.626,0,0,1,1.627-1.627h56.947v-4.881h-63.456Zm88.577,4.166-8.135-8.135a2.422,2.422,0,0,1-.459-2.76l-3.611-3.611a26.137,26.137,0,1,1,1.151-1.151l3.611,3.611a2.422,2.422,0,0,1,2.76.459l8.135,8.137a2.44,2.44,0,1,1-3.452,3.45Zm-52.782-33.452a22.779,22.779,0,1,0,22.779-22.781A22.805,22.805,0,0,0,1710.051,4478.322Zm3.254,0a19.426,19.426,0,0,1,10.877-17.512.814.814,0,0,1,.721,1.458,17.8,17.8,0,0,0-9.971,16.054.813.813,0,0,1-1.627,0Z"
                        transform="translate(-1670.999 -4423)"
                        fill="#959799"
                    />
                </svg>
            </div>
            <h2 className="ec-not-found__title margin-top margin-bottom">{title}</h2>
            {description && <p className="ec-not-found__description">{description}</p>}
        </div>
    );
};
