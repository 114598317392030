import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { ExpandableSection, FormInput } from "../../../components";
import { AltinnSettingsContext } from "../../../contexts/AltinnSettingsContext";

export const UpdateComputerSystemIdForm = ({passwordLength} : {passwordLength: number|undefined}) => {
    const { password, setPassword } = useContext(AltinnSettingsContext);
    const { t } = useTranslation(["altinn", "general"]);

    return (
        <ExpandableSection header={
            <div className="d-flex align-items-center">
                <p className="m-0">{t("Password", { ns: "general" })}</p>
                <span className="caret ml-12"></span>
            </div>}>
            <>
                <ul className="mt-16">
                    <li>{t("PasswordMessage1")}</li>
                    <li>{t("PasswordMessage2")}</li>
                </ul>
                <FormInput label={t("Password", { ns: "general" })} value={password} onChange={setPassword} isPassword placeholder={passwordLength ? "*".repeat(passwordLength) : ""} autocomplete="new-password"/>
            </>
        </ExpandableSection>
    );
}