import * as React from 'react';
import { ClassNameBuilder } from '../utils/ClassNameBuilder';
import { getElementId } from '../utils/getElementId';

export interface ITextFieldProps {
    onChange: (value: string) => void;
    id?: string;
    className?: string;
    value?: string;
    placeholder?: string;
    disabled?: boolean;
    hasError?: boolean;
}

export const TextField: React.FC<React.PropsWithChildren<ITextFieldProps> & React.HTMLProps<HTMLTextAreaElement>> = ({
    id,
    className,
    placeholder,
    disabled,
    value,
    hasError = false,
    onChange,
    ...others
}) => {
    const classBuilder = new ClassNameBuilder(className);
    const elementId = getElementId(id);

    classBuilder.add('textarea').add('has-error', hasError);

    return (
        <textarea
            id={elementId}
            className={classBuilder.build()}
            placeholder={placeholder}
            disabled={disabled}
            value={value}
            onChange={(event) => onChange(event.target.value)}
            {...others}
        />
    );
};
