import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { AltinnTaxCardAuthentication } from "./AltinnTaxCardAuthentication";
import { TaxCardType } from "../../utils/constants";
import { GlobalLoadingSpinner } from "../../components";
import "../../styles/taxCard.css";
import { sendEvent } from "../../hooks/GoogleAnalyticsHook";

export const TaxCardAuthenticationPage = ({ taxCardType }: { taxCardType: TaxCardType }) => {

    const [taxCardId, setTaxCardId] = useState<string>("");
    const { search } = useLocation();
    const params = useParams();
    const referenceId = params.id;

    useEffect(() => {
        if (referenceId) {
            if (taxCardType == TaxCardType.Altinn)
                sendEvent("taxcard_authentication", "Open altinn taxcard authentication page.");
            else if (taxCardType == TaxCardType.AnyTaxCard)
                sendEvent("taxcard_authentication", "Open taxcard authentication page.");

            setTaxCardId(referenceId);
            if (taxCardType === TaxCardType.AnyTaxCard) {
                var url = `${process.env.REACT_APP_API_BASEURL}/api/taxcard/${referenceId}/authenticate${search}`;
                window.location.href = url;
            }
        }
    }, [referenceId])

    return (
        <>
            {taxCardType === TaxCardType.Altinn ?
                <div className="ar-page-container d-flex flex-column pl-16 pr-16">
                    <AltinnTaxCardAuthentication referenceId={taxCardId} />
                </div>
                :
                <GlobalLoadingSpinner />
            }
        </>)
}