import { IMultiselectButton } from "../interfaces";
import { isMultiSelectButtonValue } from "../utils";

export const MultiselectButton = ({ Id, Value, Active, addId, removeId, }: IMultiselectButton) => {
    function handleOnClick(id: string) {
        if (Active === true)
            removeId(id);
        else
            addId(id);
    }

    return (
        <button
            key={Id}
            onClick={() => handleOnClick(Id)}
            type="button"
            className={Active ? "active" : ""}
            disabled={isMultiSelectButtonValue(Value) ? Value["disabled"] && !Active : false}
            title={typeof(Value) === "string" ? Value : Value["title"]}>
            {typeof(Value) === "string" ? Value : Value["title"] }
        </button>
    )
}