import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useOutletContext } from "react-router-dom";
import { ComponentLoadingSpinner } from "../../../components";
import { FormInput } from "../../../components/FormInput";
import { KatreSettingsContext } from "../../../contexts/KatreSettingsContext";
import { IContext, IDefaultResponseModel } from "../../../interfaces";
import { ICertificateRequest } from "../../../interfaces/KatreSettings";
import katreService from "../../../services/katreService";
import { postMessageToParent, redirectToOriginUrl, sendMessageToParentFromSettingsPage, triggerWootric } from "../../../utils";
import { ResponseTypeEnum } from "../../../utils/constants";

export const RequestCertificateForm = () => {
    const currentCompany = useOutletContext<IContext>();

    const [password, setPassword] = useState<string>("");
    const [taxRegistrationNumber, setTaxRegistrationNumber] = useState<string>("");
    const [transferId, setTransferId] = useState<string>("");
    const disableButtons = password === "" || taxRegistrationNumber === "" || transferId === "";

    const { t } = useTranslation(["katre", "general"]);
    const [showLoading, setShowLoading] = useState<boolean>(false);
    const { showAlert, refreshData, clearAlert } = useContext(KatreSettingsContext);
    const { search } = useLocation();

    const handleChangePassword = (value: string) => {
        setPassword(value);
    }

    const handleChangeTaxRegistration = (value: string) => {
        setTaxRegistrationNumber(value);
    }

    const handleChangeTransferId = (value: string) => {
        setTransferId(value);
    }

    const handleSave = () => {
       setShowLoading(true);
        var certificateRequest: ICertificateRequest = {
            TaxNumber: taxRegistrationNumber,
            TransferId: transferId,
            TransferPassword: password,
            CompanyId: currentCompany.Id,
            CompanyName: currentCompany.Name
        }

        katreService.requestNewCertificate(certificateRequest)
            .then((response: IDefaultResponseModel) => {
                setShowLoading(false);
                if (response.ResponseType === ResponseTypeEnum.Success) {
                    refreshData();
                    redirectToOriginUrl(search);
                    if (sendMessageToParentFromSettingsPage(search))
                        postMessageToParent("settings/katre", "sent");
                }
                else
                    showAlert({ type: response.ResponseType, message: response.Message, title: t("Error", { ns: "general" }) });
            });

        triggerWootric('settings_save');
    }

    const handleCancel = () => {
        clearAlert();
        setPassword("");
        setTaxRegistrationNumber("");
        setTransferId("");
        if (sendMessageToParentFromSettingsPage(search))
            postMessageToParent("settings/katre", "cancelled");
    }

    return (
        <>
            <h2 className="mt-32">{t("NewCertificateRequest", { ns: "general" })}</h2>
            <p className="mt-24">{t("NewCertificateInfo")}</p>
            {showLoading && <ComponentLoadingSpinner />}
            {!showLoading &&
                <>
                    <form>
                        <div className="form-group required" data-testid="tax-number-container">
                            <label className="d-block mt-24">{t("TaxRegistrationNumber", { ns: "general" })}</label>
                            <FormInput value={taxRegistrationNumber} onChange={handleChangeTaxRegistration} label={t("TaxRegistrationNumber", { ns: "general" })} />
                        </div>
                        <div className="form-group required" data-testid="transfer-id-container">
                            <label className="d-block mt-24">{t("TransferId", { ns: "general" })}</label>
                            <FormInput value={transferId} onChange={handleChangeTransferId} label={t("TransferId", { ns: "general" })} autocomplete="off" />
                        </div>
                        <div className="form-group required" data-testid="password-container">
                            <label className="d-block mt-16">{t("Password", { ns: "general" })}</label>
                            <FormInput value={password} onChange={handleChangePassword} isPassword label={t("Password", { ns: "general" })} autocomplete="new-password" />
                        </div>
                    </form>
                    <div className="d-flex justify-content-end mt-128">
                        <button type="button" className="btn btn-primary" data-testid="request-certificate-button" disabled={disableButtons} onClick={handleSave}>{t("SendRequest", { ns: 'general' })}</button>
                        <button type="button" className="btn" data-testid="cancel-button" disabled={disableButtons} onClick={handleCancel}>{t("Cancel", { ns: 'general' })}</button>
                    </div>
                </>
            }
        </>
    )
}