
import { useContext, useState } from "react";
import { Modal, ModalHeader, DropdownButton, ModalBody, ModalFooter, SearchBar, ExpandableSection, ActionButton } from "../../../components";
import altinnSettingService from "../../../services/altinnSettingService";
import { IAltinnSettingsRequest, IAltinnSettingsTableResponse, ISelectModel, IReportStep, IAltinnCredentials, IPage, IPageQuery, IAlert } from "../../../interfaces";
import { DeleteComputerSystemIdModal, InstructionsSectionHeader } from ".";
import { useTranslation } from "react-i18next";
import { AltinnSettingsContext } from "../../../contexts/AltinnSettingsContext";
import { ViewAllCompaniesTable } from "../ViewAllCompaniesTable";
import { useEffect } from "react";
import { usePageFetch } from "../../../hooks/PageHook";
import { SettingsContext } from "../../../contexts/SettingsContext";
import { ResponseTypeEnum } from "../../../utils/constants";

export const ViewAllCompaniesModal = ({ computerSystemIds, reportSteps, showAlert, onClose }: { computerSystemIds: ISelectModel[], reportSteps: IReportStep[], showAlert: (alert: IAlert) => void, onClose: () => any }) => {
    const { countryCode, defaultCountryCode } = useContext(SettingsContext);

    const [pageQuery, setPageQuery] = useState<IPageQuery>({ PageNumber: 1, PageSize: 200, Filters: { filterByCountryCode: countryCode === defaultCountryCode } });
    const [data, totalCount, hasMore, loadNextPage] = usePageFetch<IAltinnSettingsTableResponse>(altinnSettingService.getSettingsForAvailableCompanies, pageQuery);

    const [selectedCompanies, setSelectedCompanies] = useState<Set<number>>(new Set<number>());
    const [computerSystemId, setComputerSystemId] = useState<string>("");
    const [defaultReportStep, setDefaultReportStep] = useState<string>("");
    const [search, setSearch] = useState<string>("");

    const [computerSystemIdToDelete, setComputerSystemIdToDelete] = useState<IAltinnCredentials>();

    const { fetchData, handleDeleteComputerSystemId } = useContext(AltinnSettingsContext);
    const { t } = useTranslation(["altinn", "general"]);

    useEffect(() => {
        if (hasMore)
            loadNextPage();
    }, [data]);

    const refreshData = () => {
        setComputerSystemId("");
        setDefaultReportStep("");
        fetchTableData();
        fetchData();
    }

    const fetchTableData = () => {
        setPageQuery({ ...pageQuery, PageNumber: 1, ForceCall: true })
    }

    const handleSelect = (selectedCompanies: Set<number>) => {
        setSelectedCompanies(selectedCompanies);
    }

    const handleSave = () => {
        const credentials: ISelectModel | undefined = computerSystemIds.find(c => c.Id === computerSystemId);
        if (!credentials)
            return;

        const newSettings: IAltinnSettingsRequest = {
            ReportStep: defaultReportStep,
            ComputerSystemId: credentials.Text,
            CompanyIds: [...selectedCompanies.values()]
        }

        return altinnSettingService.updateSettings(newSettings);
    }

    const saveCallback = () => {
        showAlert({ type: ResponseTypeEnum.Success, message: t("SettingsAppliedSuccessfully", { ns: "general" }) });
        refreshData();
    }

    const handleDeleteRequest = (key: string) => {
        const data: ISelectModel | undefined = computerSystemIds.find(c => c.Id === key);

        if (data) {
            altinnSettingService.getNumberOfCompaniesUsingCredentials(data.Id).then((number) => {
                setComputerSystemIdToDelete({ Id: data.Id, Text: data.Text, CompaniesAssociated: number })
            })
        }
    }

    const handleDelete = (computerSystemId: string) => {
        return handleDeleteComputerSystemId(computerSystemId).then(() => {
            refreshData();
        });
    }

    return (
        <Modal className="modal-xl ar-view-all-modal ar-modal">
            <ModalHeader onClose={() => onClose()}>
                <h1 className="modal-title mb-16">{t("AllCompanies", { ns: "general" })}</h1>
            </ModalHeader>
            <ModalBody>
                <ExpandableSection header={<InstructionsSectionHeader altinnInstructionsTitle={t("AllCompaniesInstructionTitle")} />}>
                    <div className="mt-16">
                        <ul>
                            <li>{t("AllCompaniesInstruction1")}</li>
                            <li>{t("AllCompaniesInstruction2")}</li>
                            <li>{t("AllCompaniesInstruction3")}</li>
                            <li>{t("AllCompaniesInstruction4")}</li>
                        </ul>
                    </div>
                </ExpandableSection>
                <div className="container mt-24 p-0 ml-0 mb-24 mr-0">
                    <div className="row m-0 p-0 align-items-center">
                        <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12">
                            {t("ComputerSystemID")}
                            <DropdownButton
                                onChange={(value) => setComputerSystemId(value)}
                                options={computerSystemIds.map(c => ({ text: c.Text, key: c.Id, removable: c.Id === "-1" ? false : true }))}
                                onRemove={handleDeleteRequest}
                                value={computerSystemId}
                                placeHolderText="Select"
                            />
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-6  col-xs-12">
                            {t("DefaultReportStep")}
                            <DropdownButton
                                onChange={(value) => setDefaultReportStep(value)}
                                options={reportSteps.map(r => ({ text: r.Text, key: r.Id }))}
                                value={defaultReportStep}
                                placeHolderText="Select"
                            />
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12 pt-16">
                            <SearchBar onChange={(value) => setSearch(value)} />
                        </div>
                    </div>
                </div>
                <ViewAllCompaniesTable
                    data={data}
                    headers={[
                        t("Name", {ns: "general"}),
                        t("ComputerSystemID"),
                        t("ReportStep"),
                    ]}
                    columns={[
                        ({ CompanyName }) => CompanyName,
                        ({ ComputerSystemId }) => ComputerSystemId,
                        ({ ReportStep }) => {
                            const foundReportStep = reportSteps.find(r => r.Id === ReportStep)?.Text;
                            return foundReportStep ? foundReportStep : "";
                        },
                    ]}
                    rowId={({ CompanyId }) => CompanyId.toString()}
                    search={search}
                    onSelect={handleSelect}
                    loading={hasMore}
                />
            </ModalBody>
            <ModalFooter className="d-flex justify-content-end">
                <ActionButton onclick={handleSave}
                    text={t("Save", { ns: "general" })}
                    buttonClassName="btn-primary"
                    iconClassName=""
                    callback={saveCallback}
                    disabled={!computerSystemId || selectedCompanies.size === 0}
                    dataTestId="save-view-all-button" />
                <button className="btn btn-default" data-testid="cancel-view-all-button" onClick={() => onClose()}>{t("Cancel", { ns: "general" })}</button>
            </ModalFooter>
            {computerSystemIdToDelete && <DeleteComputerSystemIdModal computerSystemId={computerSystemIdToDelete} onClose={() => setComputerSystemIdToDelete(undefined)} onDelete={handleDelete} />}
        </Modal >
    )
};