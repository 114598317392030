import * as React from 'react';
import { ClassNameBuilder } from '../utils/ClassNameBuilder';
import { getElementId } from '../utils/getElementId';
import { isDefined } from '../utils/isDefined';

export type WizardItemState = 'active' | 'visited' | 'disabled';

export interface IWizardItemProps {
    id?: string;
    className?: string;
    ariaButtonLabelActive?: string | React.ReactElement;
    ariaButtonLabelVisited?: string | React.ReactElement;
    ariaButtonLabelDisabled?: string | React.ReactElement;
    ariaButtonLabelNormal?: string | React.ReactElement;
    state?: WizardItemState;
    isHidden?: boolean;
}

export const WizardItem: React.FC<React.PropsWithChildren<IWizardItemProps>> = ({
    id,
    className,
    ariaButtonLabelActive,
    ariaButtonLabelVisited,
    ariaButtonLabelDisabled,
    ariaButtonLabelNormal,
    isHidden = false,
    state = '',
    children,
    ...others
}) => {
    const classBuilder = new ClassNameBuilder(className || '');
    classBuilder.add('wizard-item').add(`${state}`, isDefined(state));
    classBuilder.add('hide', isHidden);

    const stateMapping = new Map<string, string | React.ReactElement>([
        ['active', ariaButtonLabelActive],
        ['visited', ariaButtonLabelVisited],
        ['disabled', ariaButtonLabelDisabled],
        ['', ariaButtonLabelNormal],
    ]);

    const elementId = getElementId(id);

    return (
        <>
            <button
                className={classBuilder.build()}
                type="button"
                aria-labelledby={`button-label-${elementId}`}
                disabled={state === 'disabled'}
                {...others}
            >
                {children}
            </button>
            <div id={`button-label-${elementId}`} className="sr-only">
                {stateMapping.get(state)}
            </div>
        </>
    );
};
