import React from "react";
import { ComponentLoadingSpinner } from "../../../components";
import * as authenticationScreens from './index';
import  "../../../styles/uploadRequestAuthentication.css"

interface IAuthenticationSection {
    component: (props: any) => JSX.Element,
    props? : object
}

const authenticationScreenSection: Record<string, IAuthenticationSection> = {
    "altinnIdPorten": { component: authenticationScreens.AltinnIdPorten },
    "altinn": { component: authenticationScreens.Altinn},
    "katre": { component: authenticationScreens.Katre, props: { missingSettingsText: "MissingSettingsKatre" } },
    "apitamo": { component: authenticationScreens.Katre, props: { missingSettingsText: "MissingSettingsApitamo" } },
    "digipoort": { component: authenticationScreens.Digipoort}
};

export const UploadRequestAuthentication = ({ reportId, sscName }: { reportId: string, sscName : string }) => {
    const renderAuthenticationSection = () => {
        const section = authenticationScreenSection[sscName];
        if (!section)
            return null;

        return React.createElement(section.component, { reportId: reportId, ...section.props });
    }

    return (
        <div className="report-authentication-content">
            {sscName ? renderAuthenticationSection() : <ComponentLoadingSpinner />}
        </div>
    )
}