import { useTranslation } from "react-i18next";

export const FeedbackButton = () => {
    const { t } = useTranslation("general");

    return (
        <a className="button-icon feeback-button" href={process.env.REACT_APP_FEEDBACK_FORM_URL} role="button" aria-label={t("Feedback")} title={t("Feedback")} data-testid="feedback-button" rel="noreferrer" target="_blank">
            <span className="vismaicon vismaicon-dynamic ar-feedback"></span>
            {t("GiveFeedback")}
        </a>
    )
}