import { useTranslation } from "react-i18next";

export const KatreInfo = () => {
    const { t } = useTranslation(["katre", "general"]);
    
    return (
        <>
            <div className="panel ar-panel">
                <div className="d-flex align-items-center mb-16">
                    <span className="vismaicon ar-info" aria-hidden="true"></span>
                    <h2 className="ml-16">{t("KatreInfoTitle")}</h2>
                </div>
                <p>{t("KatreInfoMessage")}</p>
            </div>
            <div className="panel ar-panel">
                <div className="d-flex align-items-center mb-16">
                    <span className="vismaicon ar-info" aria-hidden="true"></span>
                    <h2 className="ml-16">{t("CertificateForCompanies", { ns: "general" })}</h2>
                </div>
                <p>{t("KatreInfoMessage2")}</p>
            </div>
        </>
    )
}