export const TableLoadingSpinner = ({ colSpan }: { colSpan: number; }) => {
    return (
        <tr className="h-0" style={{ pointerEvents: "none" }}>
            <td colSpan={colSpan} className="p-0 h-0">
                <div className="d-flex ar-component-loading-container justify-content-center align-items-center position-absolute ar-opacity-50">
                    <span className="spinner spinner-secondary-grey" />
                </div>
            </td>
        </tr>
    );
};
