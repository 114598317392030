import { ErrorPage, ErrorType, } from "@vismaux/react-nc4";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from 'react-router-dom';
import "../styles/errorPage.css"

interface ErrorData {
  errorType: ErrorType,
  title: string,
  message: string
};

export const InternalErrorPage = () => {
  const params = useParams();
  const [errorData, setErrorData] = useState<ErrorData>();
  const { t } = useTranslation("general");

  useEffect(() => {
    var code = params.code;
    switch (code) {
      case '400':
        setErrorData({
          errorType: 'access-denied',
          title: t("BadRequestTitle"),
          message: t("BadRequestMessage"),
        });
        return;
      case '404':
        setErrorData({
          errorType: 'not-found',
          title: t("NotFoundTitle"),
          message: t("NotFoundMessage")
        });
        return;
      case '401':
        setErrorData({
          errorType: 'access-denied',
          title: t("UnautorizedTitle"),
          message: t("UnautorizedMessage")
        });
        return;
      case '403':
        setErrorData({
          errorType: 'access-denied',
          title: t("UnautorizedTitle"),
          message: t("UnautorizedMessage")
        });
        return;
      case '429':
        setErrorData({
          errorType: 'access-denied',
          title: t("TooManyRequestsTitle"),
          message: t("TooManyRequestsMessage")
        });
        return;
      case '500':
        setErrorData({
          errorType: 'server-error',
          title: t("InternalServerErrorTitle"),
          message: t("InternalServerErrorMessage")
        });
        return;
      default:
        setErrorData({
          errorType: 'server-error',
          title: t("InternalServerErrorTitle"),
          message: t("InternalServerErrorMessage")
        });
        return;
    }
  }, []);

  return (
    <>
      {errorData &&
        <div className="ar-error-page-container">
                  <ErrorPage type={errorData.errorType} title={errorData.title} buttonCaption={t("GoToHomePage")} redirectUrl={"/"}>
            {errorData.message}
          </ErrorPage>
        </div>
      }
    </>
  );
}