import { ReactNode, useState } from "react";
import { Alert } from "../components";
import { IAlert } from "../interfaces";

export const useAlert = () => {
    const [alert, setAlert] = useState<ReactNode | undefined>();

    const showAlert = (newAlert: IAlert) => {
        setAlert(<Alert alert={newAlert} clearAlert={() => clearAlert()} />);
    };

    const clearAlert = () => {
        setAlert(undefined);
    };

    return [alert, showAlert, clearAlert] as const;
};

