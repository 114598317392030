import { useEffect, useRef } from "react";

/**
 * Detects if a button or an input is called
 * @param callback function to call when a detection occurs
 */
export const useClickInputHook = (callback: () => any) => {
    const inputRef = useRef();

    useEffect(() => {
        document.body.addEventListener("click", onClickInput);
        return () => document.body.removeEventListener("click", onClickInput);
    }, []);

    const onClickInput = (e: any) => {
        switch (e.target?.tagName) {
            case "BUTTON":
            case "INPUT":
                break;
            default:
                return;
        }

        const previousInput = inputRef.current;

        if (previousInput === undefined) {
            inputRef.current = e.target;
        } else if (previousInput !== e.target) {
            callback();
        }
    };
};
