import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useOutletContext } from "react-router-dom";
import { ComponentLoadingSpinner } from "../../../components";
import { KatreSettingsContext } from "../../../contexts/KatreSettingsContext";
import { useAlert } from "../../../hooks/AlertHook";
import { IContext } from "../../../interfaces";
import { IKatreSettings } from "../../../interfaces/KatreSettings";
import katreService from "../../../services/katreService";
import { KatreCertificate } from "./KatreCertificate";
import { KatreInfo } from "./KatreInfo";
import { RequestCertificateForm } from "./RequestCertificateForm";
import '../../../styles/settings.css';
import { displayGoBackButton, redirectToOriginUrl } from "../../../utils";
import { CountrySelection } from "../CountrySelection";
import { useClickInputHook } from "../../../hooks/ClickInputHook";
import { sendEvent } from "../../../hooks/GoogleAnalyticsHook";

export const KatreSettingsPage = () => {

    const currentCompany = useOutletContext<IContext>();
    const { t } = useTranslation(["katre", "general"]);
    const [data, setData] = useState<IKatreSettings>({} as IKatreSettings);
    const [showLoading, setShowLoading] = useState<boolean>(true);
    const [alert, showAlert, clearAlert] = useAlert();
    useClickInputHook(clearAlert);

    const { search } = useLocation();

    const showRequestForm = !data.CertificateAvailable || data.ReplaceKatre;
    const showCertificateDetails = data.CertificateAvailable && !data.ReplaceKatre;

    useEffect(() => {
        sendEvent("settings_finland", "Open finnish settings page.");
        fetchData();
    }, [currentCompany])

    const fetchData = () => {
        const query = new URLSearchParams(search);
        var replaceKatre = query.get("replaceKatre");
        var shouldReplaceKatre = replaceKatre?.toLocaleLowerCase() === 'true';

        katreService.getKatreSettings(currentCompany.Id, shouldReplaceKatre)
            .then((data: IKatreSettings) => {
                setData(data);
                setShowLoading(false);
            })
    }

    return (
        <KatreSettingsContext.Provider value={{ showAlert, clearAlert, refreshData: fetchData }}>
            <div className="ar-settings-container">
                <div className="panel ar-panel ar-settings-left">
                    {showLoading && <ComponentLoadingSpinner />}
                    <div className="d-flex justify-content-between">
                        <div>
                            <h1 className="mb-0">{t("Settings", { ns: "general" })}</h1>
                            {displayGoBackButton(search) && <button type="button" className="btn mt-32 mb-16" data-testid="return-button" onClick={() => redirectToOriginUrl(search)}>{t("GoBack", { ns: 'general' })}</button>}
                        </div>
                        <div className="katre-logo" />
                    </div>
                    <h3 className="mt-16">{t("SettingsForCompany", { ns: "general" })} <b>{currentCompany.Name}.</b></h3>
                    {data && showRequestForm && <RequestCertificateForm />}
                    {data && showCertificateDetails && <KatreCertificate data={data} />}
                    <div className="ar-alert-container">
                        {alert}
                    </div>
                </div>
                <div className="ar-settings-right">
                    <CountrySelection />
                    <KatreInfo />
                </div>
            </div>
        </KatreSettingsContext.Provider>
    )
}