import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { FormInput } from "../../../components/FormInput";
import { ComputerSystemFromContext } from "../../../contexts/AltinnSettingsContext";

export const AddComputerSystemIdForm = () => {
    const { t } = useTranslation(["altinn", "general"]);
    const { password, setPassword, computerSystemId, setComputerSystemId } = useContext(ComputerSystemFromContext);

    return (
        <>
            <form>
                <div className="form-group">
                    <label className="d-block">{t("ComputerSystemID")}</label>
                    <FormInput value={computerSystemId} onChange={setComputerSystemId} label={t("ComputerSystemID")} autocomplete="off"/>
                </div>
                <div className="form-group">
                    <label className="d-block">{t("Password", { ns: "general" })}</label>
                    <FormInput value={password} onChange={setPassword} isPassword label={t("Password", { ns: "general" })} autocomplete="new-password"/>
                </div>
            </form>
        </>
    );
}