import * as React from 'react';
import { ReactNode, useEffect } from 'react';
import { ClassNameBuilder } from '../utils/ClassNameBuilder';
import { isDefined } from '../utils/isDefined';
import * as FocusTrap from 'focus-trap-react';

export type ModalSize = 'modal-xl' | 'modal-lg' | 'modal-sm';
export type ModalType = 'modal-info' | 'modal-help' | 'modal-success' | 'modal-warning' | 'modal-error';

interface IProps {
    className?: string;
    title: string | ReactNode;
    onModalClose: () => void;
    bodyContent: string | React.ReactNode;
    footerContent?: string | React.ReactNode;
    modalSize?: ModalSize;
    modalType?: ModalType;
    onMount?: () => void;
}

export const Modal: React.FC<IProps> & React.HTMLProps<HTMLDivElement> = ({
    className,
    title,
    onModalClose,
    bodyContent,
    footerContent,
    modalSize,
    modalType,
    onMount,
    ...others
}) => {
    const classBuilder = new ClassNameBuilder('modal fade in');
    classBuilder.add(className, isDefined(className)).add(modalType, isDefined(modalType));

    useEffect(() => {
        if (onMount) {
            onMount();
        }
    }, []);

    return (
        <>
            <div className={classBuilder.build()} role="dialog" aria-labelledby="modal-title" {...others}>
                <FocusTrap>
                    <div className={`modal-dialog ${modalSize ?? ''}`}>
                        <div className="modal-content">
                            <div className="modal-header">
                                <button className="close" data-dismiss="modal" onClick={() => onModalClose()}>
                                    <div className="sr-only">Close</div>
                                </button>
                                <h4 id="modal-title" className="modal-title">
                                    {title}
                                </h4>
                            </div>
                            <div className="modal-body">{bodyContent}</div>
                            <div className="modal-footer">{footerContent}</div>
                        </div>
                    </div>
                </FocusTrap>
            </div>
            <div className="modal-backdrop fade in" />
        </>
    );
};
