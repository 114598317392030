import { IOrderBy } from "../interfaces";

export const OrderableTableHeader = ({ title, value, order, handleOrderChange }: { title: string, value: string | number | symbol, order: IOrderBy, handleOrderChange: any }) => {
    return (
        <th onClick={(() => handleOrderChange(value))}>
            <div className="d-flex justify-content-between align-items-center ar-break-word">
                {title}
                <div className={`pl-8 ${order.OrderDirection === "DESC" ? "float-right open" : "float-right"}`}>
                    {order.OrderValue === value && <span className="caret" />}
                </div>
            </div>
        </th>
    );
};
