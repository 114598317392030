import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useOutletContext } from "react-router-dom";
import { FormInput } from "../../../components";
import { ReportExpandedRowContext } from "../../../contexts/ReportExpandedRowContext";
import { IContext, INetherlandsSettingsModel } from "../../../interfaces";
import netherlandsAuthenticationService from "../../../services/netherlandsAuthenticationService";
import { getEditSettingsUrlForReport } from "../../../utils";

export const Digipoort = ({ reportId }: { reportId: string }) => {
    const { setSendButtonProps } = useContext(ReportExpandedRowContext);
    const { t } = useTranslation(["general", "digipoort", "pinkweb"]);
    const currentCompany = useOutletContext<IContext>();
    const { search } = useLocation();

    const [data, setData] = useState<INetherlandsSettingsModel>({} as INetherlandsSettingsModel);
    const [password, setPassword] = useState("");

    useEffect(() => {
        netherlandsAuthenticationService
            .getAuthenticationModel(reportId)
            .then((response: any) => {
                setData(response);
            })
    }, [])

    useEffect(() => {
        setSendButtonProps({
            text: t("Send"),
            title: t("SendReportTooltip"),
            disabled: disableSend(),
            getAuthenticationData: () => ({ CompanyId: data.CompanyId, CertificatePassword: password })
        })
    }, [data, password])

    const disableSend = () => {
        return !data.HasSettings || (data.DigipoortData && !data.DigipoortData.IsPasswordSaved && !password);
    }

    return (
        <>
            {data.HasSettings ?
                <>
                    {data.PinkWebData &&
                        <>
                            <div className="row">
                                <div className="col col-4">
                                    <p>{t("Endpoint", { ns: "pinkweb" })}</p>
                                </div>
                                <div className="col col-8">
                                    <b>{data.PinkWebData.IsDefaultEndpoint ? t("DefaultEndpoint", { ns: "pinkweb" }) : data.PinkWebData.Endpoint}</b>
                                </div>
                            </div>
                            <div className="row mt-24">
                                <div className="col col-4">
                                    <p>{t("Username")}</p>
                                </div>
                                <div className="col col-8">
                                    <b>{data.PinkWebData.Username}</b>
                                </div>
                            </div>
                        </>
                    }
                    {data.DigipoortData &&
                        <>
                            <div className="row">
                                <div className="col col-4">
                                    <p>{t("CommonName")}</p>
                                </div>
                                <div className="col col-8">
                                    <b>{data.DigipoortData.CertificateCommonName}</b>
                                </div>
                            </div>
                            {data.DigipoortData.CertificateExpirationDate &&
                                <div className="row mt-24">
                                    <div className="col col-4">
                                        <p>{t("ValidUntil")}</p>
                                    </div>
                                    <div className="col col-8" data-testid="expiration-date">
                                        <b>{data.DigipoortData.CertificateExpirationDate}</b>
                                    </div>
                                </div>
                            }
                            {!data.DigipoortData.IsPasswordSaved &&
                                <div className="row mt-24 align-items-center">
                                    <div className="col col-4">
                                        <p>{t("Password")}</p>
                                    </div>
                                    <div className="col col-6">
                                        <FormInput label="Password" onChange={(value) => setPassword(value)} value={password} isPassword autocomplete="new-password"/>
                                    </div>
                                </div>

                            }
                        </>
                    }
                </>
                :
                <>
                    <p>{t("MissingSettings", { ns: "digipoort" })}</p>
                    <a className="btn btn-primary" href={getEditSettingsUrlForReport(reportId, data.CompanyId, currentCompany.Id, search)}>{t("EditSettings")}</a>
                </>
            }
        </>
    )
}