import { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Checkbox, OrderableTableHeader } from "../../components";
import { useTableOrder, useTableSelect } from "../../hooks/TableHooks";
import { IOrderByIndex } from "../../interfaces";
import "../../styles/settings.css";

export interface TableProps<T> {
    data: T[],
    rowId: (item: T) => string,
    headers: string[],
    columns: ((item: T) => string)[],
    loading: boolean,
    search?: string,
    defaultOrder?: IOrderByIndex,
    onSelect: (selectedData: Set<any>) => any
}

export const ViewAllCompaniesTable = <T,>({ data, rowId, headers, defaultOrder, columns, search, onSelect, loading }: TableProps<T>) => {
    const [filteredData, setFilteredData] = useState<T[]>(data);
    const [selectedData, handleSelect, handleAllSelect] = useTableSelect(filteredData.map(d => rowId(d)));
    const [tableOrder, handleOrderChange] = useTableOrder(defaultOrder ? defaultOrder : { OrderValue: 0, OrderDirection: "ASC" });
    const { t } = useTranslation("general");

    useEffect(() => {
        refreshData();
    }, [data, search])

    useEffect(() => {
        onSelect(selectedData);
    }, [selectedData])

    useEffect(() => {
        setFilteredData(orderBy(tableOrder.OrderValue, tableOrder.OrderDirection, filteredData));
    }, [tableOrder])

    const refreshData = () => {
        handleAllSelect(false);

        const searchedValues = searchBy(search, data);
        const orderedAndSearchedValues = orderBy(tableOrder.OrderValue, tableOrder.OrderDirection, searchedValues);

        setFilteredData(orderedAndSearchedValues);
    }

    const searchBy = (value: string | undefined, array: any[]): any[] => {
        if (!value)
            return array;

        const searchValue = value.toLowerCase();

        return array.filter(item => {
            return columns.some((extractData) => {
                const tableValue = getTableValue(extractData(item));
                return tableValue?.toLowerCase().includes(searchValue)
            });
        });
    }

    const orderBy = (columnIndex: number, orderDirection: "ASC" | "DESC", arrayOfObjects: any[]): any[] => {
        const columnValueFunction = columns[columnIndex];

        return orderDirection === "ASC"
            ?
            [...arrayOfObjects].sort((a, b) => (getTableValue(columnValueFunction(a)).toLowerCase() < getTableValue(columnValueFunction(b)).toLowerCase() ? -1 : 1))
            :
            [...arrayOfObjects].sort((a, b) => (getTableValue(columnValueFunction(a)).toLowerCase() > getTableValue(columnValueFunction(b)).toLowerCase() ? -1 : 1))
    }

    const getTableValue = (value: string): string => {
        return value ? value : "n/a";
    }

    return (
        <>
            <div className="ar-view-all-table">
                <table className="table table-stripe table-active mb-0" >
                    <thead>
                        <tr>
                            <th className="w-0 pr-16 pl-16">
                                <Checkbox isChecked={filteredData.length > 0 && filteredData.length === selectedData.size} onChange={handleAllSelect} />
                            </th>
                            {headers.map((h, index) => <OrderableTableHeader key={h} title={h} value={index} order={tableOrder} handleOrderChange={handleOrderChange} />)}
                        </tr>
                    </thead>
                    <tbody>
                        {filteredData.map((item: T) =>
                            <tr key={rowId(item)} data-testid={rowId(item)} onClick={() => { var checked = selectedData.has(rowId(item)); handleSelect(rowId(item), !checked) }}>
                                <td className="w-0 pr-16 pl-16">
                                    <div onClick={(event: any) => { event.stopPropagation(); }}>
                                        <Checkbox isChecked={selectedData.has(rowId(item))} onChange={(checked) => handleSelect(rowId(item), checked)} />
                                    </div>
                                </td>
                                {columns.map((extractData, index) => <td key={index}>{getTableValue(extractData(item))}</td>)}
                            </tr>
                        )}
                    </tbody>
                </table>
            </div >
            <div data-testid="view-all-data-count" className="data-count">
                <Trans
                    i18nKey="DataCount"
                    ns={"general"}
                    values={{ totalFilteredCount: filteredData.length, totalCount: data ? data.length : 0 }}
                    components={{ bold: <strong /> }}
                />

                <b className="ml-16">{selectedData.size}</b> {t("CompaniesSelected")}.
                {loading && <span> {t("LoadingMoreCompanies")}...</span>}
            </div>
        </>
    )
}