import { IDefaultResponseModel, ICertificateRequest, IKatreSettings, ICertificateData, IPageQuery, IPage } from '../interfaces';
import axiosUtils from '../utils/axios';

const baseUrl = "api/settings/katre"

const getKatreSettings = async (companyId: number, replaceKatre: boolean): Promise<IKatreSettings> => {
    const result = await axiosUtils.instance.get<IKatreSettings>(`${baseUrl}/${companyId}?replaceKatre=${replaceKatre}`);
    return result.data;
}

const requestNewCertificate = async (model: ICertificateRequest): Promise<IDefaultResponseModel> => {
    const result = await axiosUtils.instance.post<IDefaultResponseModel>(`${baseUrl}/requestCertificate`, model);
    return result.data;
}

const testCertificate = async (): Promise<IDefaultResponseModel> => {
    const result = await axiosUtils.instance.post<IDefaultResponseModel>(`${baseUrl}/testKatreCertificate`);
    return result.data;
}

const deleteCertificate = async (): Promise<IDefaultResponseModel> => {
    const result = await axiosUtils.instance.post<IDefaultResponseModel>(`${baseUrl}/deleteKatreSettings`);
    return result.data;
}

const getSettingsForAvailableCompanies = async (pageQuery: IPageQuery): Promise<IPage<ICertificateData>> => {
    const result = await axiosUtils.instance.get<IPage<ICertificateData>>(`${baseUrl}/companies`, { params: { PageNumber: pageQuery.PageNumber, PageSize: pageQuery.PageSize, ...pageQuery.Filters } });
    return result.data;
}

const copySettingsForCompanies = async (model: any): Promise<IDefaultResponseModel> => {
    const result = await axiosUtils.instance.patch<IDefaultResponseModel>(`${baseUrl}/certificate`, model);
    return result.data;
}

export default {
    getKatreSettings,
    requestNewCertificate,
    testCertificate,
    deleteCertificate,
    getSettingsForAvailableCompanies,
    copySettingsForCompanies
}