import * as React from 'react';
import { PropsWithChildren } from 'react';
import { ClassNameBuilder } from '../utils/ClassNameBuilder';
import { isDefined } from '../utils/isDefined';

interface IMenuUserProps {
    showIcon?: boolean;
    expanded?: boolean;
}

export const NavbarNavMenuUser: React.FC<PropsWithChildren<IMenuUserProps> & React.HTMLProps<HTMLLIElement>> = ({
    showIcon = false,
    expanded = false,
    className,
    children,
    ...others
}) => {
    const classBuilder = new ClassNameBuilder('dropdown user-dropdown');

    classBuilder.add(className, isDefined(className));
    classBuilder.add('icon', showIcon);
    classBuilder.add('open', expanded);

    return (
        <li role="none" className={classBuilder.build()} {...others}>
            {children}
        </li>
    );
};
