import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { DateIntervalFilter } from ".";
import { ExpandableSection, FilterRow, Multiselect } from "../../components";
import { useDebounce } from "../../hooks/DebounceHook";
import { IDateInterval, IReportFilters } from "../../interfaces";
import reportFiltersService from "../../services/reportFiltersService";
import { SimpleMultiselect } from "../../components/SimpleMultiselect";
import { sendEvent } from "../../hooks/GoogleAnalyticsHook";

export const ReportFilters = ({ filter, filterOptions, onChangeFilter, intervalPresetIndex, setIntervalPresetIndex, onResetFilter, search, setSearch }: IReportFilters) => {
    const { t } = useTranslation("general");
    const debouncedSearch = useDebounce<string>(search, 500);
    const initialSearch = useRef<boolean>(true);

    useEffect(() => {
        if (!initialSearch.current)
            handleFilterChange("Search", debouncedSearch);
        else
            initialSearch.current = false;
    }, [debouncedSearch]);

    const handleFilterChange = (key: string, value: any) => {
        sendEvent("report_change_filter", "Changing to  '" + key + "' with value '" + value + "'.")
        onChangeFilter({ ...filter, [key]: value });
    };

    const handleIntervalChange = (value: IDateInterval, presetIndex: number) => {
        setIntervalPresetIndex(presetIndex);
        reportFiltersService.savePresetToStorage(String(presetIndex));

        onChangeFilter({ ...filter, PeriodStart: value.PeriodStart, PeriodEnd: value.PeriodEnd });
    };

    const getDateInterval = () => {
        const periodStart = filter.PeriodStart;
        const periodEnd = filter.PeriodEnd;

        var interval: IDateInterval = {
            PeriodStart: periodStart ? new Date(periodStart) : undefined,
            PeriodEnd: periodEnd ? new Date(periodEnd) : undefined,
        }

        return interval;
    }

    return (
        <ExpandableSection className="p-16 ar-filters-expandable-section ar-reports-expandable-section"
            header={<FilterSectionHeader title={t("FilterOptions")} />}
            expanded={true}
            headerChildren={<button onClick={onResetFilter} className="btn float-right m-0">{t("Reset")}</button>}>
            <>
                {
                    filterOptions &&
                    <div className="reports-filters px-8">
                        <FilterRow title={t("Status")}>
                            <SimpleMultiselect Options={filterOptions.StatusGroups} onChange={(value) => handleFilterChange("Statuses", value)} Value={filter.Statuses} />
                        </FilterRow>
                        <FilterRow title={t("Type")}>
                            <Multiselect Options={filterOptions.ReportTypes} onChange={(value) => handleFilterChange("Types", value)} Value={filter.Types} />
                        </FilterRow>
                        <FilterRow title={t("Company")}>
                            <SimpleMultiselect Options={filterOptions.Companies} onChange={(value) => handleFilterChange("Company", value)} Value={filter.Company} />
                        </FilterRow>
                        <FilterRow title={t("Archive")}>
                            <SimpleMultiselect Options={filterOptions.ArchiveStates} onChange={(value) => handleFilterChange("ArchiveStates", value)} Value={filter.ArchiveStates} />
                        </FilterRow>
                        <FilterRow title={t("Date")}>
                            <div className="col ar-reports-filter-col">
                                <div>
                                    <select className="mr-16" onChange={(e) => handleFilterChange("DateFilterMode", e.target.value)} value={filter.DateFilterMode} data-testid="report-filter-date-mode">
                                        {Object.entries(filterOptions.DateModes).map(mode => <option key={mode[0]} value={mode[0]}>{mode[1]}</option>)}
                                    </select>
                                </div>
                                <DateIntervalFilter onChange={handleIntervalChange} Interval={getDateInterval()} preset={intervalPresetIndex} />
                                <div className="pl-0">
                                    <div className="search-group">
                                        <input className="form-control pr-16 search-input"
                                            type="search"
                                            placeholder={`${t("Search")}...`}
                                            value={search}
                                            aria-label={t("Search")}
                                            onChange={(e) => { setSearch(e.target.value) }}
                                            data-testid="report-filter-search-input" />
                                        <span className="search-icon">Search Icon</span>
                                        <span className="clear-search">Clear</span>
                                    </div>
                                </div>
                            </div>
                        </FilterRow>
                    </div>
                }
            </>
        </ExpandableSection>
    );
}

const FilterSectionHeader = (props: any) => {
    const { t } = useTranslation("general");

    return (
        <div className="font-weight-600 align-items-center ar-title d-flex pr-16 pl-16 pt-6 pb-6 m-0 cursor-pointer" data-testid="reports-filter-section">
            <div className="mb-0">{props.title}</div>
            <span className="caret ml-12"></span>
        </div>
    )
}