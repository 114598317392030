import { useTranslation } from "react-i18next";
import { DatePickerInput } from "../../components/DatePickerInput";
import { IDateIntervalFilterProps } from "../../interfaces";
import { PRESETS, PresetsEnum } from "../../utils/constants";

const customPresetIndex = PRESETS.findIndex(e => e.Id === PresetsEnum.Custom);

export const DateIntervalFilter = ({ Interval, preset, onChange }: IDateIntervalFilterProps) => {
	const { t } = useTranslation("general");

	const handlePresetChange = (value: string) => {
		const number = Number(value);

		onChange(PRESETS[number].Interval, number);
	};

	const handleDateChange = (key: "PeriodStart" | "PeriodEnd", value: any) => {
		if (Interval[key] === value)
			return;

		onChange({ ...Interval, [key]: value }, customPresetIndex);
	};

	return (
		<>
			<div className="mr-8" >
				{t("Preset")}
				<div className="px-8 d-inline-block">
					<select value={preset} onChange={(e) => handlePresetChange(e.target.value)} data-testid="report-filter-preset">
						{PRESETS.map((preset, index) => <option key={index} value={index}>{t(preset.Id)}</option>)}
					</select>
				</div>
			</div>
			<div>
				{t("From")}
				<div className="px-8 datepicker-container d-inline-block ar-mr-32" data-testid="report-filter-from">
					<DatePickerInput value={Interval.PeriodStart} onChange={(date) => handleDateChange("PeriodStart", date)} />
				</div>
			</div>
			<div>
				{t("To")}
				<div className="px-8 datepicker-container d-inline-block ar-mr-32" data-testid="report-filter-to">
					<DatePickerInput value={Interval.PeriodEnd} onChange={(date) => handleDateChange("PeriodEnd", date)} />
				</div>
			</div>
		</>
	)
}