export const TaxCardRowContentValue = ({ title, value }: { title: string, value: any }) => {
    return (
        <div className="row pb-10 ar-summary-value">
            <div className="col col-md-4 col-xl-3 ar-break-word ar-bold">
                {title}
            </div>
            <div className="col ar-break-word">
                {value}
            </div>
        </div>
    )
};