import * as React from 'react';
import { ClassNameBuilder } from '../utils/ClassNameBuilder';

export interface IWizardProps {
    className?: string;
}

export const Wizard: React.FC<React.PropsWithChildren<IWizardProps>> & React.HTMLProps<HTMLDivElement> = ({
    className,
    children,
    ...others
}) => {
    const classBuilder = new ClassNameBuilder(className || '');
    classBuilder.add('wizard');

    return (
        <div className={classBuilder.build()} {...others}>
            {children}
        </div>
    );
};
