import { useState } from "react";
import { ITabs } from "../interfaces";
import "../styles/tabs.css"
import { Tab } from "./Tab";

export const Tabs = ({children, setActiveTab}: ITabs) => {
    const [activeTab, setActiveTabInternal] = useState(children[0].title);

    return (
        <>
            <div className="ar-tabs">
                {children.map(t => <Tab title={t.title} 
                        key={t.title}
                        isActive={t.title === activeTab} 
                        onClick={()=>{setActiveTabInternal(t.title); setActiveTab && setActiveTab(t.title)}}/>
                )}
            </div>
            {children.filter(t => t.title === activeTab)[0].content}
        </>
    );
}

