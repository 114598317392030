import { IContext, IContextSelector, IPageQuery } from "../interfaces";
import { ChangeEvent, useState, KeyboardEvent, useRef, useEffect, useMemo } from "react";
import { InfiniteScroller } from "../components";
import { useClickOutside } from "../hooks/ClickOutsideHook";
import { usePageFetch } from "../hooks/PageHook";
import navigationService from "../services/navigationService";
import { handleKeyDown } from "../utils";
import { useDebounce } from "../hooks/DebounceHook";
import { useListNavigation } from "../hooks/ListNavigation";

export const NavBarContextSelector = (props: IContextSelector) => {
    const [ref, showList, setShowList] = useClickOutside<HTMLDivElement>();
    const [pageQuery, setPageQuery] = useState<IPageQuery>({ PageNumber: 1, PageSize: 10, Filters: { name: "" } });
    const debouncedSearch = useDebounce<IPageQuery>(pageQuery, 500);
    const [options, totalCount, hasMore, loadNextPage] = usePageFetch<IContext>(navigationService.getPage, debouncedSearch);
    const { activeItem, setActiveItem, clearState, focusNext, focusPrevious, focusFirst, focusLast } = useListNavigation<IContext>(options);
    const companiesListRef = useRef<HTMLUListElement>(null);
    const inputSearchRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (activeItem == null)
            return;

        if (activeItem.Id === options[options.length - 1].Id && hasMore)
            loadNextPage();

        var list = companiesListRef.current;
        if (list != null && list.getElementsByClassName("active")[0] != null)
            list.getElementsByClassName("active")[0].scrollIntoView(false);
    }, [activeItem])

    function handleChangeSearch(event: ChangeEvent<HTMLInputElement>) {
        setPageQuery({ PageNumber: 1, PageSize: 10, Filters: { name: event.target.value } });
    }

    function handleClickContext(newContext: IContext) {
        setPageQuery({ PageNumber: 1, PageSize: 10, Filters: { name: "" } });
        props.onChange(newContext);
    }

    function handleKeyDownButton(event: KeyboardEvent<HTMLButtonElement>) {
        handleKeyDown(event, "Enter", () => { event.stopPropagation(); setShowList(true) });
        handleKeyDown(event, "Escape", () => { setShowList(false) });
    };

    const handleTabKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
        if(showList === true)
            handleKeyDown(event, 'Tab', () => setShowList(false));
    }
    
    const handleTabKeyDownMemo  = useMemo(() => handleTabKeyDown, [showList]);
    
    function handleKeyDownSearch(event: KeyboardEvent<HTMLInputElement>) {
        event.stopPropagation();
        handleKeyDown(event, "Enter", () => {
            activeItem && handleClickContext(activeItem);
            setShowList(false);
        });
        handleKeyDown(event, 'ArrowDown', () => focusNext());
        handleKeyDown(event, 'ArrowUp', () => focusPrevious());
        handleKeyDown(event, 'Escape', () => setShowList(false));
        handleTabKeyDownMemo(event);
    }

    function handleClickButton(event: React.MouseEvent<HTMLButtonElement>) {
        clearState();
        setShowList(!showList);
        setTimeout(() => {
            inputSearchRef.current?.focus();
        }, 0);
    }

    return (
        <>
            <div ref={ref}
                className={`w-100 dropdown ${showList === true ? "open" : ""} d-block ar-context-selector mt-8`}
                data-testid="context-selector-dropdown">
                <button type="button"
                    onClick={handleClickButton}
                    className={`btn dropdown-toggle`}
                    data-toggle="dropdown"
                    id="context-selector-dropdown"
                    data-testid="context-selector-toggle"
                    aria-haspopup="true"
                    onKeyDown={handleKeyDownButton}
                    aria-expanded={showList}>
                    <span className="context-name">{props.currentContext.Name}</span>
                    <span className="caret"></span>
                </button>

                <div className="dropdown-menu w-100">
                    <div className="form-group search-group">
                        <input aria-label="Search"
                            className="form-control pr-10"
                            ref={inputSearchRef}
                            type="search"
                            placeholder="Search..."
                            data-testid="search-context-input"
                            value={pageQuery.Filters?.name}
                            onChange={handleChangeSearch}
                            onKeyDown={(event) => handleKeyDownSearch(event)}
                            tabIndex={0}
                            autoComplete="new-search"/>
                        <span className="search-icon">Search icon</span>
                        <span className="clear-search">Clear</span>
                    </div>

                    <ul role="menu" className="ar-custom-list p-0" aria-hidden={!showList} ref={companiesListRef}>
                        {options.map((opt: IContext) =>
                            <li key={opt.Id} className={(activeItem == null && opt.Id === props.currentContext.Id) || activeItem?.Id === opt.Id ? "active" : ''}
                                role="menuitem"
                                data-testid={`context-list-element-${opt.Id}`}>
                                <a onClick={() => handleClickContext(opt)}>
                                    {opt.Name}
                                </a>
                            </li>)}
                        <li>
                            <InfiniteScroller data={options} onLoadMore={loadNextPage} disabled={!hasMore} />
                        </li>
                    </ul>
                </div>
            </div>
        </>);
}