import { IAltinnCompanyCredentials, IAltinnSettingsRequest, IAltinnSettingsResponse, IAltinnSettingsTableResponse, IPage, IPageQuery, ISelectModel } from '../interfaces';
import axiosUtils from '../utils/axios';

const baseUrl = "api/settings/altinn";

const getSettings = async (companyId: number): Promise<IAltinnSettingsResponse> => {
    const result = await axiosUtils.instance.get<IAltinnSettingsResponse>(`${baseUrl}/${companyId}`);
    return result.data;
}

const updateSettings = async (newAltinnSettings: IAltinnSettingsRequest): Promise<IAltinnSettingsResponse> => {
    const result = await axiosUtils.instance.put<IAltinnSettingsResponse>(`${baseUrl}/company`, newAltinnSettings);
    return result.data;
}

const getCredentials = async (): Promise<IAltinnCompanyCredentials[]> => {
    const result = await axiosUtils.instance.get<IAltinnCompanyCredentials[]>(`${baseUrl}/credentials`);
    return result.data;
}

const deleteCredentials = async (systemId: string): Promise<boolean> => {
    await axiosUtils.instance.delete(`${baseUrl}/credentials/${systemId}`);
    return true;
}

const getSettingsForAvailableCompanies = async (pageQuery: IPageQuery): Promise<IPage<IAltinnSettingsTableResponse>> => {
    const result = await axiosUtils.instance.get<IPage<IAltinnSettingsTableResponse>>(`${baseUrl}/companies`, { params: { PageNumber: pageQuery.PageNumber, PageSize: pageQuery.PageSize, ...pageQuery.Filters } });
    return result.data;
}

const getNumberOfCompaniesUsingCredentials = async (credentialsId: string): Promise<number> => {
    const result = await axiosUtils.instance.get<number>(`${baseUrl}/companiesCount/${credentialsId}`);
    return result.data;
}

export default {
    getSettings,
    getCredentials,
    updateSettings,
    deleteCredentials,
    getSettingsForAvailableCompanies,
    getNumberOfCompaniesUsingCredentials
}