import * as React from 'react';
import { PropsWithChildren } from 'react';
import { ClassNameBuilder } from '../utils/ClassNameBuilder';
import { isDefined } from '../utils/isDefined';

export type AlertType = 'success' | 'info' | 'warning' | 'danger';

type IAlertProps = {
    type: AlertType;
    spacing?: 'sm';
    dismissible?: boolean;
};

export const Alert: React.FC<PropsWithChildren<IAlertProps> & React.HTMLProps<HTMLDivElement>> = ({
    className,
    type,
    spacing,
    dismissible,
    children,
    ...others
}) => {
    const classBuilder = new ClassNameBuilder(className || '');

    classBuilder.add('alert').add(`alert-${type}`).add(`alert-${spacing}`, isDefined(spacing));

    return (
        <div className={classBuilder.build()} {...others}>
            {dismissible && <button type="button" className="close" data-dismiss="alert" aria-label="Close" />}
            {children}
        </div>
    );
};
