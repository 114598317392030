import * as React from 'react';
import { PropsWithChildren } from 'react';
import { ClassNameBuilder } from '../utils/ClassNameBuilder';
import { isDefined } from '../utils/isDefined';

interface IProps {
    isDropdown?: boolean;
}

export const NavbarBrand: React.FC<PropsWithChildren<IProps> & React.HTMLProps<HTMLDivElement>> = ({
    isDropdown = false,
    children,
    className,
    ...others
}) => {
    const classBuilder = new ClassNameBuilder('navbar-brand');

    classBuilder.add(className, isDefined(className));
    classBuilder.add('dropdown', isDropdown);

    return (
        <div className="navbar-header">
            <div className={classBuilder.build()} {...others}>
                {children}
            </div>
        </div>
    );
};
