import { useTranslation } from "react-i18next";
import { IConfirmModal } from "../interfaces";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "./modal";
import { useEffect, useRef } from "react";

export const ConfirmModal = ({ title, message, description, onSuccess, onClose }: IConfirmModal) => {
    const { t } = useTranslation("general");
    const dummyElementRef = useRef<HTMLSpanElement>(null);

    useEffect(() => {
        //This trick is used to set the focus in the confirmation modal
        //It is helpful if we open the confirmation modal from another modal
        dummyElementRef.current?.focus();
    }, []);

    return (
        <Modal className="ar-modal ar-confirm-modal" >
            <span tabIndex={0} ref={dummyElementRef}></span>
            <ModalHeader onClose={onClose}>
                <h1 className="modal-title">{title}</h1>
                {message && <p className="ar-urgent-text mb-16">{message}</p>}
            </ModalHeader>
            <ModalBody>
                <p>{description}</p>
            </ModalBody>
            <ModalFooter className="d-flex justify-content-end">
                <button className="btn btn-primary mr-32" data-testid="confirm-button" onClick={onSuccess}>{t("Yes")}</button>
                <button className="btn btn-default" data-testid="cancel-confirm-button" onClick={onClose}>{t("No")}</button>
            </ModalFooter>
        </Modal>
    )
}