import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useDebounce } from "../hooks/DebounceHook";
import "../styles/datepicker.css";

export const DatePickerInput = ({ onChange, value, disabled }: { onChange: (inputDate: Date | undefined) => any, value: Date | undefined, disabled?: boolean }) => {
    const [selected, setSelected] = useState<Date | undefined>(value);
    const debouncedSelect = useDebounce<Date | undefined>(selected, 500);

    useEffect(() => {
        onChange(selected);
    }, [debouncedSelect]);

    const handleChange = (date: Date) => {
        setSelected(date);
    }

    useEffect(() => {
        if (selected === value)
            return;

        setSelected(value);
    }, [value]);

    return (
        <div className="col-md-auto px-0">
            <span className={`vismaicon vismaicon-dynamic vismaicon-calendar datepicker-icon ${disabled ? "disabled" : ""}`} aria-hidden="true" />
            <DatePicker
                selected={selected}
                onChange={(date: Date) => handleChange(date)}
                showPopperArrow={false}
                showWeekNumbers
                calendarStartDay={1}
                showMonthDropdown
                showYearDropdown
                disabled={disabled}
                dateFormat="P"
                isClearable={!disabled}
                dropdownMode="select">
            </DatePicker>
        </div>
    )
}