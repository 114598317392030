import { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { GlobalLoadingSpinner } from "../../../components";
import { AuthenticationActionEnum } from "../../../utils/constants";
import { sendEvent } from "../../../hooks/GoogleAnalyticsHook";

export const QuickAuthenticationPage = ({ action }: { action: AuthenticationActionEnum }) => {
    const { search } = useLocation();
    const params = useParams();

    useEffect(() => {
        var id = params.id;
        if (!id)
            return;

        var url = "";
        if (action === AuthenticationActionEnum.Authenticate) {
            sendEvent("quick_authentication", "Open quick authentication page.")
            url = `${process.env.REACT_APP_API_BASEURL}/api/report/${id}/authenticate${search}`;
        }
        else if (action === AuthenticationActionEnum.Logout) {
            sendEvent("quick_authentication_logout", "Log out from quick authentication.")
            url = `${process.env.REACT_APP_API_BASEURL}/api/report/logout/${id}${search}`;
        }

        window.location.href = url;
    }, [])

    return (<GlobalLoadingSpinner></GlobalLoadingSpinner>);
}