import { IGetCountryResponseModel } from '../interfaces';
import axiosUtils from '../utils/axios';

const getCountryCode = async (): Promise<IGetCountryResponseModel> => {
    const result = await axiosUtils.instance.get<IGetCountryResponseModel>("api/settings/getCountryCode");
    return result.data;
}

const verifyOriginUrl = async (originUrl:string|null): Promise<void> => {
    const result = await axiosUtils.instance.post<void>(`api/settings/returnUrl?originUrl=${originUrl}`);
    return result.data;
}

export default{
    getCountryCode,
    verifyOriginUrl
}