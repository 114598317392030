import { useTranslation } from "react-i18next";

export const DigipoortInfo = () => {
    const { t } = useTranslation("digipoort");
    return (
        <div className="panel ar-panel">
            <div className="d-flex align-items-center mb-16">
                <span className="vismaicon ar-info" aria-hidden="true"></span>
                <h2 className="ml-16">{t("Digipoort")}</h2>
            </div>
            <span>{t("DigipoortDescription")}</span>
        </div>
    )
}