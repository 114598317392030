import { useTranslation } from "react-i18next";
import { ExpandableSection } from "../../../components";
import '../../../styles/settings.css';
import { InstructionsSectionHeader } from "./InstructionsSectionHeader";

export const AltinnSettingsInfo = ({ isBusinessAuthenticationSet, isBusinessAuthenticationType }: { isBusinessAuthenticationSet?: boolean, isBusinessAuthenticationType?: boolean }) => {
    const { t } = useTranslation("altinn");
    return (
        <>
            <div className="panel ar-panel">
                <div className="d-flex align-items-center mb-16">
                    <span className="vismaicon ar-info" aria-hidden="true"></span>
                    <h2 className="ml-16">{t("Altinn")}</h2>
                </div>
                <span>{t("AltinnDescription")}</span>

                <ExpandableSection className="mt-16" header={<InstructionsSectionHeader altinnInstructionsTitle={t("AltinnInstructionsTitle")} />}>
                    <div className="mt-16">
                        <ul>
                            <li>{t("AltinnInstructionStep1")}</li>
                            <li>{t("AltinnInstructionStep2")}</li>
                            <li>{t("AltinnInstructionStep3")}</li>
                        </ul>
                    </div>
                </ExpandableSection>

            </div>
            <div className="panel ar-panel">
                <div className="d-flex align-items-center mb-16">
                    <span className="vismaicon ar-info" aria-hidden="true"></span>
                    <h2 className="ml-16">{t("Altinn3Title")}</h2>
                </div>
                <span>{t("Altinn3Description")}</span>
            </div>
            {isBusinessAuthenticationType && isBusinessAuthenticationSet &&
                <div className="panel ar-panel">
                    <div className="d-flex align-items-center mb-16">
                        <span className="vismaicon ar-info" aria-hidden="true"></span>
                        <h2 className="ml-16">{t("BusinessCertificateInfoTitle")}</h2>
                    </div>
                    <span>{t("BusinessCertificateInfoMessage")}</span>
                </div>
            }
            {isBusinessAuthenticationType && !isBusinessAuthenticationSet &&
                <div className="panel ar-panel ar-panel-error">
                    <div className="d-flex align-items-center mb-16">
                        <span className="vismaicon vismaicon-lg vismaicon-filled vismaicon-error" aria-hidden="true"></span>
                        <h2 className="ml-16">{t("ErrorBusinessCertificateTitle")}</h2>
                    </div>
                    <span>{t("MissingBusinessLogin")}</span>
                </div>
            }
        </>
    );
}

