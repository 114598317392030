import { useTranslation } from "react-i18next";
import { IMultiselect } from "../interfaces";
import { MultiselectButton } from "./MultiselectButton";

export const SimpleMultiselect = ({ Options, onChange, Value }: IMultiselect) => {
    const { t } = useTranslation("general");

    const addId = (id: string) => {
        onChange(Value ? [...Value, id] : [id]);
    }

    const removeId = (id: string) => {
        const newValue = Value?.filter(o => o !== id);
        onChange(newValue ? newValue : []);
    }

    const addAll = () => {
        if (Value?.length === 0)
            return;

        onChange([]);
    }

    return (
        <div className="col nav nav-pills nav-pills-primary">
            <MultiselectButton Id={"All"}
                Value={t("All")}
                Active={!Value || Value.length === 0}
                addId={addAll}
                removeId={addAll} />
            {
                Object.entries(Options).map(el => {
                    return <MultiselectButton Id={el[0]}
                        key={el[0]}
                        Value={el[1]}
                        Active={Value ? Value.includes(el[0]) : false}
                        addId={addId}
                        removeId={removeId} />
                })
            }
        </div>
    );
};