import * as React from 'react';
import { PropsWithChildren } from 'react';
import { ClassNameBuilder } from '../utils/ClassNameBuilder';
import { isDefined } from '../utils/isDefined';

type VerticalAlign = 'start' | 'center' | 'end';
type HorizontalAlign = 'start' | 'center' | 'end' | 'around' | 'between';

type IProps = {
    className?: string;
    noGutters?: boolean;
    verticalAlign?: VerticalAlign;
    horizontalAlign?: HorizontalAlign;
};

export const Row: React.FC<PropsWithChildren<IProps> & React.HTMLProps<HTMLDivElement>> = ({
    className,
    verticalAlign,
    horizontalAlign,
    noGutters,
    children,
    ...others
}) => {
    const classBuilder = new ClassNameBuilder(className || '');

    classBuilder
        .add('row')
        .add(`align-items-${verticalAlign}`, isDefined(verticalAlign))
        .add(`justify-content-${horizontalAlign}`, isDefined(horizontalAlign))
        .add('no-gutters', isDefined(noGutters));

    return (
        <div className={classBuilder.build()} {...others}>
            {children}
        </div>
    );
};
