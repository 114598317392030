import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { ApplicationContext } from "../../contexts/ApplicationContext";
import { IDefaultResponseModel } from "../../interfaces";
import reportService from "../../services/reportService";
import { ResponseTypeEnum } from "../../utils/constants";
import { ConfirmModal } from "../../components/ConfirmModal";

export const ConfirmCancelReportModal = ({ reportId, onClose, refreshData }: { reportId: string, onClose: () => void, refreshData: () => void }) => {
    const { t } = useTranslation("general");
    const { showToast } = useContext(ApplicationContext);

    const handleCancelReport = () => {
        reportService.cancelReport(reportId)
            .then((data: IDefaultResponseModel) => {
                if (data.ResponseType === ResponseTypeEnum.Danger) {
                    showToast({ message: data.Message, type: data.ResponseType });
                }
                if (data.ResponseType === ResponseTypeEnum.Success) {
                    refreshData();
                }
                onClose();
            });
    }

    return (
        <ConfirmModal
            title={t("ConfirmReportCancelTitle")}
            message={t("ConfirmReportCancelMessage")}
            description={t("ConfirmReportCancelDescription")}
            onSuccess={handleCancelReport}
            onClose={onClose}
        />
    )
}