import { useEffect, useRef, useState } from "react";
import { IPageQuery, IReportFilterOptions, IOrderByKey, IContext } from "../../interfaces";
import reportService from "../../services/reportService";
import '../../styles/reports.css';
import { useOutletContext } from "react-router-dom";
import { ReportFilters, ReportTable } from ".";
import reportFiltersService, { DEFAULT_FILTERS, DEFAULT_PRESET_INDEX } from "../../services/reportFiltersService";
import { sendEvent } from "../../hooks/GoogleAnalyticsHook";

export const ReportsPage = () => {
    
    const [pageQuery, setPageQuery] = useState<IPageQuery>({ PageNumber: 1, PageSize: 10, Filters: reportFiltersService.getFiltersFromStorage(), OrderBy: { OrderValue: "Created", OrderDirection: "DESC" } });
    const [filterOptions, setFilterOptions] = useState<IReportFilterOptions | null>(null);
    
    const currentCompany = useOutletContext<IContext>();
    const initialRender = useRef<boolean>(true);

    const [intervalPresetIndex, setIntervalPresetIndex] = useState<number>(reportFiltersService.getPresetFromStorage());
    const [search, setSearch] = useState<string>(pageQuery.Filters ? (pageQuery.Filters.Search ? pageQuery.Filters.Search : "") : "");

    useEffect(() => {
        sendEvent('reports', 'Open reports page.');
        var newQuery: IPageQuery = { PageNumber: 1, PageSize: 10, Filters: pageQuery.Filters, ForceCall: true };

        reportService.getFilterOptions(newQuery)
            .then((filterOptionsResponse) => {
                setFilterOptions(filterOptionsResponse);
                if (newQuery.Filters && !newQuery.Filters.Types)
                    newQuery.Filters.Types = Object.entries(filterOptionsResponse.ReportTypes).map(el => { return el[0] })

                if (!initialRender.current)
                    setPageQuery(newQuery);

                initialRender.current = false;
            });

    }, [currentCompany]);

    const onChangeFilter = (value: any) => {
        reportFiltersService.saveFiltersToStorage(value);
        refreshData({ ...pageQuery, PageNumber: 1, PageSize: 10, Filters: value });
    };

    const onRefreshData = () => {
        refreshData({ ...pageQuery, PageNumber: 1, PageSize: 10, ForceCall: true })
    }

    const onChangeOrder = (orderBy: IOrderByKey) => {
        setPageQuery({ ...pageQuery, PageNumber: 1, PageSize: 10, OrderBy: { ...orderBy } });
    }

    const refreshData = (newQuery: IPageQuery) => {
        setPageQuery(newQuery);
        reportService.getReportStatusGroupMetrics(newQuery).then(response => {
            if (filterOptions != null) {
                setFilterOptions({
                    ...filterOptions,
                    StatusGroups: response
                });
            }
        })
    }

    const onResetFilter = () => {
        setIntervalPresetIndex(DEFAULT_PRESET_INDEX);
        reportFiltersService.savePresetToStorage(`${DEFAULT_PRESET_INDEX}`);
        setSearch("");
        onChangeFilter({
            ...DEFAULT_FILTERS,
            Types: filterOptions && Object.entries(filterOptions.ReportTypes).map(el => { return el[0] })
        });
    }

    return (
        <div className="ar-page-container background-white d-flex flex-column">
            <ReportFilters filter={pageQuery.Filters ? pageQuery.Filters : {}}
                onChangeFilter={onChangeFilter}
                filterOptions={filterOptions}
                onResetFilter={onResetFilter}
                intervalPresetIndex={intervalPresetIndex}
                setIntervalPresetIndex={setIntervalPresetIndex}
                search={search}
                setSearch={setSearch}
            />
            <ReportTable pageQuery={pageQuery} onChangeOrder={onChangeOrder} refreshData={onRefreshData} onResetFilter={onResetFilter}/>
        </div>
    )
}