import { IAlert } from "../interfaces";

export const Alert = ({ alert, clearAlert }: { alert: IAlert, clearAlert?: () => any }) => {
    const canBeCleared = !alert.sticky && clearAlert !== undefined;

    const handleClick = () => {
        if (canBeCleared)
            clearAlert();
    }

    return (
        <div className={`alert alert-${alert.type}`} role="alert" onClick={() => handleClick()}>
            {canBeCleared && <button type="button" className="close"></button>}
            <div>
                {alert.title && <b>{alert.title} </b>}{alert.message}
            </div>
        </div>
    );
};
