import { useTranslation } from "react-i18next";
import { Tabs } from "../../../components/Tabs";
import { DigipoortSettingsContext } from "../../../contexts/DigipoortSettingsContext";
import { useAlert } from "../../../hooks/AlertHook";
import { IDigipoortSettingsProps, ITab } from "../../../interfaces";
import { DigipoortPrivateCertificate } from "./privateCertificate/DigipoortPrivateCertificate";
import { DigipoortVismaCertificate } from "./vismaCertificate/DigipoortVismaCertificate";
import '../../../styles/settings.css';
import { useClickInputHook } from "../../../hooks/ClickInputHook";

export const DigipoortSettings = ({ data, refreshData }: IDigipoortSettingsProps) => {

    const { t } = useTranslation(["general", "digipoort"]);
    const tabs: ITab[] = [
        {
            title: t("Visma"),
            content:
                <DigipoortVismaCertificate vismaCertificateActivated={data.VismaCorporateCertificateActivated}
                    totalCompanies={data.TotalCompanies}
                    companiesUsingVismaCorporateCertificate={data.CompaniesUsingVismaCorporateCertificate}
                />
        },
        {
            title: t("Private"),
            content:
                <DigipoortPrivateCertificate certificate={data.PrivateCertificate}
                    totalCompanies={data.TotalCompanies}
                    companiesUsingPrivateCertificate={data.CompaniesUsingPrivateCertificate}
                />
        }
    ];

    const [alert, showAlert, clearAlert] = useAlert();
    useClickInputHook(clearAlert);

    return (
        <DigipoortSettingsContext.Provider value={{ showAlert, clearAlert, refreshData: refreshData }}>
            <div className="d-flex justify-content-between">
                <h1>{t("Digipoort", { ns: "digipoort" })}</h1>
                <div className="digipoort-logo" />
            </div>
            <h3 className="mt-32">{t("TypeOfCertificate")}</h3>
            <Tabs>
                {tabs}
            </Tabs>
            <div className="ar-alert-container">
                {alert}
            </div>
        </DigipoortSettingsContext.Provider>
    );
}