import { useEffect, useState } from "react"
import { Trans } from "react-i18next";

export const AltinnCredentialsTimer = ({ initialSeconds, setExpired }: { initialSeconds: number, setExpired: (value: boolean) => void }) => {
    const [deadline, setDeadline] = useState(initialSeconds);

    const [minutes, setMinutes] = useState<number | undefined>();
    const [seconds, setSeconds] = useState<number | undefined>();

    const getTime = () => {
        setDeadline((deadline) => deadline - 1);
    };

    useEffect(() => {
        setMinutes(Math.floor((deadline / 60) % 60));
        setSeconds(Math.floor((deadline) % 60));

        if (deadline === 0)
            setExpired(true);
    }, [deadline])

    useEffect(() => {
        const interval = setInterval(() => getTime(), 1000);

        return () => clearInterval(interval);
    }, []);

    return (
        <div className="timer" data-testid="timer">
            <Trans
                i18nKey="TimerMessage"
                ns={"altinn"}
                values={{ minutes: minutes && minutes < 10 ? "0" + minutes : minutes, seconds: seconds && seconds < 10 ? "0" + seconds : seconds }}
                components={{ bold: <strong /> }}
            />
        </div>
    );
};