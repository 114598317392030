import * as React from 'react';
import { ScreenSize } from '../../utils/enums/ScreenSize';
import { ChangeEvent, PropsWithChildren, ReactElement, useEffect, useState } from 'react';
import { ClassNameBuilder } from '../../utils/ClassNameBuilder';
import { getElementId } from '../../utils/getElementId';

export interface ISwitchProps {
    onChange: (event: ChangeEvent<HTMLInputElement>, isChecked: boolean) => void;
    type?: 'checkbox' | 'radio';
    id?: string;
    className?: string;
    name?: string;
    value?: string | number;
    checked?: boolean;
    disabled?: boolean;
    label?: string | ReactElement;
    asLabel?: boolean;
    size?: ScreenSize.lg | false;
}

export const Switch: React.FC<PropsWithChildren<ISwitchProps>> = ({
    id,
    className = '',
    value,
    asLabel = true,
    size = false,
    type = 'checkbox',
    name,
    label,
    checked = false,
    onChange,
    ...otherProps
}) => {
    const classes = new ClassNameBuilder(className);
    const elementId = getElementId(id);
    const [isChecked, setIsChecked] = useState(checked);

    useEffect(() => {
        setIsChecked(checked);
    }, [checked]);

    classes
        .add('switch')
        .add(`switch-lg`, size === ScreenSize.lg)
        .add('switch-label', asLabel);

    return (
        <>
            <div className={classes.build()}>
                <input
                    id={elementId}
                    type={type}
                    name={name}
                    value={value}
                    checked={isChecked}
                    onChange={(event) => {
                        setIsChecked(!isChecked);
                        onChange(event, event.target.checked);
                    }}
                    {...otherProps}
                />
                <label htmlFor={elementId} className="togglemark">
                    {label}
                </label>
            </div>
        </>
    );
};
