import { useEffect, useRef, useState } from "react";
import { IPage, IPageQuery } from "../interfaces";

export const usePageFetch = <Type,>(pageFunction: (query: IPageQuery) => Promise<IPage<Type>>, Query: IPageQuery) => {
    const [result, setResult] = useState<IPage<Type>>({ Data: [], TotalCount: 0, HasMore: false, CurrentPage: 0, PageSize: 0, TotalPages: 0 });
    const abortControllerRef = useRef<AbortController>();

    const queryId = useRef<number>(0);
    const loadMorePagesId = useRef<number>(0);

    useEffect(() => {
        const newId = queryId.current + 1;
        queryId.current = newId;

        abortControllerRef.current?.abort();
        const abortController = new AbortController();
        abortControllerRef.current = abortController;

        const QueryWithAbort = { ...Query, AbortSignal: abortController.signal };

        pageFunction(QueryWithAbort)
            .then((response: IPage<Type>) => {
                setResult(response);
                loadMorePagesId.current = newId;
            })
            .catch((err: any) => console.error("Unable to get page", err));
    }, [Query]);

    const loadNextPage = () => {
        if (!result.HasMore)
            return;
        if (queryId.current !== loadMorePagesId.current)
            return;

        const QueryWithAbort = { ...Query, PageNumber: result.CurrentPage + 1, AbortSignal: abortControllerRef.current?.signal };

        pageFunction(QueryWithAbort)
            .then((response: IPage<Type>) => {
                if (queryId.current === loadMorePagesId.current) {
                    const newResult = { ...response, Data: [...result.Data, ...response.Data] };
                    setResult(newResult);
                }
            })
            .catch((err: any) => console.error("Unable to load more pages", err));
    }

    return [result.Data, result.TotalCount, result.HasMore, loadNextPage] as const;
}


