import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useParams } from "react-router-dom";
import { Alert } from "../../../components";
import { IAlert } from "../../../interfaces";
import { AuthenticationActionEnum, ResponseTypeEnum } from "../../../utils/constants";
import { sendEvent } from "../../../hooks/GoogleAnalyticsHook";

export const AuthenticationStatusPage = ({ action }: { action: AuthenticationActionEnum }) => {

    const [alert, setAlert] = useState<IAlert>({} as IAlert);
    const { t } = useTranslation("general");
    const params = useParams();
    const { search } = useLocation();

    useEffect(() => {
        sendEvent("authentication_status", "Open authentication status page.");
        var status = params.status;
        switch (status) {
            case "success":
                setSuccessMessage();
                break;
            case "error":
                var urlParams = new URLSearchParams(search);
                setErrorMessage(urlParams.get("code"));
                break;
        }
    }, [])

    const setErrorMessage = (code: string | null) => {
        var title = t("SomethingWentWrongTitle");
        var type = ResponseTypeEnum.Danger;
        var message = t("SomethingWentWrongMessage");
        switch (code) {
            case '401':
                title = t("Attention");
                message = t("LoginCanceled");
                type = ResponseTypeEnum.Warning;
                break;
            case '403':
                message = t("UnautorizedMessage");
                break;
            case '412':
                message = t("AuthenticationPreconditionFailed");
                type = ResponseTypeEnum.Warning;
                break;
            case '500':
                message = t("InternalServerErrorMessage");
                break;
        }

        setAlert({
            message: <span className="d-block mt-24">{message}</span>,
            title: title,
            type: type
        });
    }

    const setSuccessMessage = () => {
        if (action === AuthenticationActionEnum.Authenticate)
            setAlert({
                title: t("AuthenticatedSuccessfullyTitle"),
                message: <span className="d-block mt-24">{t("AuthenticatedSuccessfullyMessage")}</span>,
                type: ResponseTypeEnum.Success
            });
        else if (action === AuthenticationActionEnum.Logout)
            setAlert({
                title: t("LogoutSuccessfullyTitle"),
                message: <span className="d-block mt-24">{t("YouCanCloseTheWindow")}</span>,
                type: ResponseTypeEnum.Success
            });
    }

    return (<>
        <div className="ar-page-container container">
            <div className="mt-32">
                <Alert alert={{...alert, sticky:true}} />
            </div>
        </div>
    </>);
}