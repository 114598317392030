import { useClickOutside } from "../hooks/ClickOutsideHook";
import { useListNavigation } from "../hooks/ListNavigation";
import { KeyboardEvent, useEffect, useMemo, useState } from "react";
import "../styles/buttonDropDown.css";
import { handleKeyDown } from "../utils";

export interface IDropdownButtonProps {
    onChange: (value: string) => void;
    options: IDropdownButtonItem[];
    value?: string;
    onRemove?: (value: string) => any;
    placeHolderText?: string;
    className?: string;
}

export interface IDropdownButtonItem {
    text: string;
    key: string;
    removable?: boolean;
    disabled?: boolean;
}

export const DropdownButton = (props: IDropdownButtonProps) => {
    const [ref, showList, setShowList] = useClickOutside<HTMLDivElement>();
    const [internalOptions, setInternalOptions] = useState<IDropdownButtonItem[]>([]);

    const { activeItem, setActiveItem, clearState, focusNext, focusPrevious, focusFirst, focusLast } = useListNavigation<IDropdownButtonItem>(internalOptions);

    const handleTabKeyDown = (event: KeyboardEvent<HTMLButtonElement>) => {
        if (showList === true)
            handleKeyDown(event, 'Tab', () => setShowList(false));
    }

    const handleTabKeyDownMemo = useMemo(() => handleTabKeyDown, [showList]);

    const handleRemove = (key: string) => {
        if (props.onRemove)
            props.onRemove(key);
    }

    const getText = () => {
        const optionText = props.options.find(o => o.key === props.value)?.text
        return optionText ? optionText : props.placeHolderText;
    }

    const handleKeyDownButton = (event: KeyboardEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        handleKeyDown(event, "Enter", () => {
            if (activeItem) {
                if (activeItem.key.includes("remove"))
                    handleRemove(activeItem.key.split("-remove")[0])
                else
                    props.onChange(activeItem.key)
            }

            setShowList(!showList);
        });
        handleKeyDown(event, 'ArrowDown', () => focusNext());
        handleKeyDown(event, 'ArrowUp', () => focusPrevious());
        handleKeyDown(event, 'Escape', () => setShowList(false));
        handleKeyDown(event, 'ArrowRight', () => focusNext());
        handleKeyDown(event, 'ArrowLeft', () => focusPrevious());

        handleTabKeyDownMemo(event);
    }

    useEffect(() => {
        if (showList === false)
            setActiveItem(undefined);
    }, [showList])

    useEffect(() => {
        var options: IDropdownButtonItem[] = [];
        props.options.forEach(opt => {
            options.push(opt);
            if (opt.removable) {
                options.push({ key: opt.key + "-remove", text: "" });
            }
        });
        setInternalOptions(options);
    }, [])
    
    return (
        <div ref={ref} className={`dropdown ${showList === true ? "open" : ""} w-100`} data-testid="dropdown">
            <button type="button"
                onClick={() => setShowList(!showList)}
                className={`btn dropdown-toggle w-100 ${props.className}`}
                data-toggle="dropdown"
                data-testid="dropdown-toggle"
                aria-haspopup="true"
                onKeyDown={handleKeyDownButton}
                aria-expanded={showList}
                title={getText()}>
                {getText()}
            </button>

            <ul className="dropdown-menu w-100" role="menu" aria-hidden={!showList} data-testid="dropdown-list">
                {props.options.map((opt: IDropdownButtonItem) =>
                    <li key={opt.key} className={`${(opt.disabled ? "disabled" : "")} ${(activeItem?.key === opt.key ? "active" : '')} pr-2`} >
                        <a
                            title={opt.text}
                            className={opt.removable ? "ar-dropdown-option" : ""}
                            onClick={(e: any) => {
                                props.onChange(opt.key);
                                setShowList(false);
                            }}>
                            {opt.text}
                        </a>
                        {opt.removable && <span tabIndex={0} className={`close ar-close float-right ${activeItem?.key === opt.key + "-remove" && "active"}`} data-testid="delete-list-item-span" onClick={(event: React.MouseEvent<HTMLSpanElement>) => { event.stopPropagation(); handleRemove(opt.key) }} />}
                    </li>)}
            </ul>
        </div>
    );
}