import { KeyboardEvent } from "react";
import { getDefaultLocale } from "react-datepicker";
import { IMultiselectButtonValue } from "../interfaces";
import toggleService from "../services/toggleService";
import wootricService from "../services/wootricService";

export const handleKeyDown = (event: KeyboardEvent, key: string, handleMethod: Function) => {
    if (event.key === key) {
        event.preventDefault();
        handleMethod();
    }
}

export const capitalizeString = (input: string) => {
    return input ? input.charAt(0).toUpperCase() + input.slice(1) : input;
}

export const addDays = (date: Date, days: number) => {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
}

export const addMonths = (date: Date, months: number) => {
    const result = new Date(date);
    var currntMonth = result.getMonth();
    result.setMonth(currntMonth + months);
    return result;
}

export function isMultiSelectButtonValue(obj: any): obj is IMultiselectButtonValue {
    return typeof (obj) == "object" && 'title' in obj && 'disabled' in obj;
}

export const toLocaleDateString = (date: Date, options?: any): string => {
    if (!date)
        return "";

    return new Date(date).toLocaleDateString(getDefaultLocale(), options ? options : { year: 'numeric', month: '2-digit', day: '2-digit' });
}

export const toLocaleDateTimeString = (date: Date, options?: any): string => {
    if (!date)
        return "";

    return new Date(date).toLocaleString(getDefaultLocale(), options ? options : { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' });
}

export const isApp = (urlParams: URLSearchParams) => {
    var menu = urlParams.get("menu");
    return menu == null || menu?.toLowerCase() !== "false";
}

export const isParentEaccounting = (search: string) => {
    var urlParams = new URLSearchParams(search);
    var parent = urlParams.get("parent");
    return parent != null && parent.toLowerCase() === "eaccounting";
}

export const getEditSettingsUrlForReport = (reportId: string, reportCompanyId: number, currentCompanyId: number, search: string): string => {
    var urlParams = new URLSearchParams(search);
    var originUrl = encodeURIComponent(`${process.env.REACT_APP_BASEURL}report/${reportId}/details?${urlParams.toString()}`);
    return getEditSettingUrl(urlParams, originUrl, reportCompanyId, currentCompanyId);
}

export const getEditSettingsUrlAltinnForTaxCard = (taxCardId: string, taxCardCompanyId: number, currentCompanyId: number, search: string): string => {
    var urlParams = new URLSearchParams(search);
    var originUrl = encodeURIComponent(`${process.env.REACT_APP_BASEURL}altinnTaxCard/${taxCardId}?${urlParams.toString()}`);
    return getEditSettingUrl(urlParams, originUrl, taxCardCompanyId, currentCompanyId);
}

export const getEditSettingsUrlAltinnForFetchFeedback = (reportIds: string[], reportCompanyId: number, currentCompanyId: number, search: string): string => {
    var urlParams = new URLSearchParams(search);
    var originUrl = "";
    if (reportIds.length === 1)
        originUrl = encodeURIComponent(`${process.env.REACT_APP_BASEURL}report/${reportIds[0]}/feedback?${urlParams.toString()}`);
    else
        originUrl = encodeURIComponent(`${process.env.REACT_APP_BASEURL}report/feedback/?${urlParams.toString()}`);
    return getEditSettingUrl(urlParams, originUrl, reportCompanyId, currentCompanyId);
}

export const getEditSettingsUrlAltinnDownloadTaxCard= (taxCardId: string, taxCardCompanyId: number, currentCompanyId: number, search: string): string => {
    var urlParams = new URLSearchParams(search);
    var originUrl = encodeURIComponent(`${process.env.REACT_APP_BASEURL}altinnTaxCard/${taxCardId}/download?${urlParams.toString()}`);
    return getEditSettingUrl(urlParams, originUrl, taxCardCompanyId, currentCompanyId);
}

export const getEditSettingUrl = (urlParams: URLSearchParams, originUrl: string, companyId: number, currentCompanyId: number) => {
    urlParams.delete("context");
    var url = `${process.env.REACT_APP_BASEURL}settings?originUrl=${originUrl}`;

    if (currentCompanyId !== companyId)
        url += `&context=${companyId}`;

    url += `&${urlParams.toString()}`;
    return url;
}

export const redirectToOriginUrl = (search: string) => {
    var urlParams = new URLSearchParams(search);
    var originUrl = urlParams.get("originUrl");
    if (originUrl) {
        var url = new URL(originUrl);
        url.searchParams.append('fromSettings', "true");

        window.location.href = url.toString();
    }
}

export const getQueryParameter = (query: string, parameterName: string) => {
    const urlParams = new URLSearchParams(query);
    const parameter = urlParams.get(parameterName);

    return parameter;
}

export const displayGoBackButton = (query: string) => {
    var originUrl = getQueryParameter(query, "originUrl");

    return originUrl != null;
}

export const postMessageToParent = (dialog: string, action: string) => {
    const callData = {
        dialog: dialog,
        action: action,
        data: null
    };

    window.parent.postMessage(callData, "*");
    window.parent.parent.postMessage(callData, "*");
}

export const sendMessageToParentFromSettingsPage = (search: string): boolean => {
    var urlParams = new URLSearchParams(search);

    return !isApp(urlParams) && !Boolean(getQueryParameter(search, "originUrl"));
}

export const inIFrame = (): boolean => {
    return window.self !== window.top;
}

export const  triggerWootric = (eventName: string) => {
    wootricService.run(eventName);
}