import { Trans, useTranslation } from "react-i18next";
import reportService from "../../services/reportService";

export const ReportTableActions = ({ selectedReports, onCancel, refreshData, totalFilteredCount, totalReportCount }:
    { selectedReports: Set<string>, onCancel: () => any, refreshData: () => void, totalFilteredCount: number, totalReportCount: number }) => {
    const { t } = useTranslation("general");

    const handleArchive = () => {
        reportService.archiveReports(selectedReports)
            .then(() => {
                refreshData();
            });
    }

    return (
        <div className="row ar-sticky-bottom-action ar-gap p-8 m-0">
            <div className="ml-16">
                <Trans
                    i18nKey="DataCount"
                    ns={"general"}
                    values={{ totalFilteredCount: totalFilteredCount, totalCount: totalReportCount }}
                    components={{ bold: <strong /> }}
                />
                <b className="ml-16">{selectedReports.size}</b> {t("ReportsSelected")}
            </div>
            <div className="col d-flex justify-content-between">
                <button type="button" className="btn m-0" disabled={selectedReports.size === 0} onClick={handleArchive} title={t("ArchiveTooltip")}>
                    <span className="vismaicon vismaicon-sm ar-archive ml-n16" />{t("Archive")}
                </button>
                <button type="button" className="btn m-0" disabled={selectedReports.size === 0} onClick={onCancel} title={t("CancelSelectionTooltip")}>{t("CancelSelection")}</button>
            </div>
        </div>
    );
};
