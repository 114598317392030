import { useId } from "react";
import { ICheckbox } from "../interfaces";

export const Checkbox = ({ isChecked, onChange, onKeyDown, label, disabled, tabIndex }: ICheckbox) => {
    const id = useId();

    return (
        <div className="checkbox">
            <input id={id} type="checkbox"
                checked={isChecked}
                onChange={(e) => onChange(e.target.checked)}
                onKeyDown={onKeyDown} disabled={disabled}
                tabIndex={tabIndex ? tabIndex : 0} />
            <label className={`d-block ${label ? "pl-8" : ""}`} htmlFor={id}>{label}</label>
        </div>
    )
}