import { useContext, useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { DropdownButton, IDropdownButtonItem } from "../../../components"
import { IAltinnCompanyCredentials, ISelectModel } from "../../../interfaces";
import { AddComputerSystemIdForm, UpdateComputerSystemIdForm } from ".";
import { AltinnSettingsContext, ComputerSystemFromContext } from "../../../contexts/AltinnSettingsContext";

export const AltinnSettingsComputerSystemId = ({
    computerSystemIds,
    currentComputerSystemId,
    totalCompanies
}: {
    computerSystemIds: IAltinnCompanyCredentials[],
    currentComputerSystemId: ISelectModel | undefined,
    totalCompanies: number
}) => {
    const { t } = useTranslation(["altinn", "general"]);
    const { computerSystemId, setComputerSystemId, setPassword, password } = useContext(AltinnSettingsContext);
    const [passwordLength, setPasswordLength] = useState<number | undefined>(computerSystemIds.find(c => c.Id === currentComputerSystemId?.Id)?.PasswordLength);

    const handleChangeComputerSystemId = (key: string) => {
        const existingId = computerSystemIds.find(c => c.Id === key);
        setComputerSystemId(existingId);
        setPasswordLength(existingId?.PasswordLength);
        setPassword("");
    }

    const computerSystemIdsToDropdownList = (ids: ISelectModel[]) => {
        const options: IDropdownButtonItem[] = ids.map(p => { return { text: p.Text, key: p.Id } });
        return [{ key: "", text: `${t("AddNew", { ns: "general" })}...` }, ...options]
    }

    const idsFound = () => {
        return computerSystemIds.length > 0;
    }

    useEffect(() => {
        setPasswordLength(computerSystemIds.find(c => c.Id === currentComputerSystemId?.Id)?.PasswordLength);
    }, [currentComputerSystemId])

    return (
        <>
            <h2>{t("ComputerSystemID")}</h2>
            {!idsFound() && <p className="mt-24">{t("NoIdFoundMessage")}</p>}

            {idsFound() && !currentComputerSystemId && <p className="mt-24"><b>{t("NoIdAssignedTitle")}</b> <br /> {t("NoIdAssignedMessage")}</p>}

            {currentComputerSystemId && <>
                <p className="mt-24">{t("IdAssociatedTitle")} <b>{currentComputerSystemId?.Text}</b>.</p>
                <ul>
                    <li>{t("IdAssociatedMessage1")}</li>
                    <li>{t("IdAssociatedMessage2")}</li>
                    <li>{t("IdAssociatedMessage3")}</li>
                </ul>
            </>}

            {idsFound() &&
                <>
                    <div style={{ width: 256 }}>
                        <label>{t("SetComputerSystemID")}</label>
                        <DropdownButton
                            options={computerSystemIdsToDropdownList(computerSystemIds)}
                            onChange={handleChangeComputerSystemId}
                            value={computerSystemId.Id ? computerSystemId.Id : ""} />
                    </div>
                    {computerSystemId.Id &&
                        <>
                            <p className="mt-24" data-testid="altinn-credentials-stats">
                                <Trans
                                    i18nKey="CredentialsStatistics"
                                    ns={"altinn"}
                                    values={{ companiesWithSameCredentials: computerSystemId.CompaniesAssociated, totalCompanies: totalCompanies }}
                                    components={{ bold: <strong /> }}
                                />
                            </p>
                            <UpdateComputerSystemIdForm passwordLength={passwordLength} />
                        </>
                    }
                </>
            }
            {(!idsFound() || !computerSystemId.Id) &&
                <ComputerSystemFromContext.Provider value={
                    {
                        setComputerSystemId: (value: string) => setComputerSystemId({ Text: value, Id: "" }),
                        setPassword,
                        computerSystemId: computerSystemId.Text ? computerSystemId.Text : "" ,
                        password
                    }}>
                    <b>{t("AddComputerSystemIdMessage")}</b>
                    <AddComputerSystemIdForm />
                </ComputerSystemFromContext.Provider>}
        </>
    )
}