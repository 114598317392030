
import { useContext, useState } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, SearchBar, ActionButton } from "../../../components";
import { ViewAllCompaniesTable } from "../ViewAllCompaniesTable";
import { useEffect } from "react";
import pinkWebSettingService from "../../../services/pinkWebSettingService";
import { useTranslation } from "react-i18next";
import { IPageQuery, IPinkWebSettings, IViewAllCompaniesProps } from "../../../interfaces";
import { usePageFetch } from "../../../hooks/PageHook";
import { SettingsContext } from "../../../contexts/SettingsContext";
import { ResponseTypeEnum } from "../../../utils/constants";

export const ViewAllCompaniesModal = ({ currentCompany, currentEndpoint, showAlert, onClose, refreshData }: IViewAllCompaniesProps) => {
    const { countryCode, defaultCountryCode } = useContext(SettingsContext);

    const [pageQuery, setPageQuery] = useState<IPageQuery>({ PageNumber: 1, PageSize: 200, Filters: { filterByCountryCode: countryCode === defaultCountryCode } });
    const [data, totalCount, hasMore, loadNextPage] = usePageFetch<IPinkWebSettings>(pinkWebSettingService.getSettingsForAvailableCompanies, pageQuery);

    const [selectedCompanies, setSelectedCompanies] = useState<Set<number>>(new Set<number>());
    const [search, setSearch] = useState<string>("");

    const { t } = useTranslation(["pinkWeb", "general"]);

    useEffect(() => {
        if (hasMore)
            loadNextPage();
    }, [data]);

    const fetchTableData = () => {
        setPageQuery({ ...pageQuery, PageNumber: 1, ForceCall: true })
    }

    const handleSelect = (selectedCompanies: Set<number>) => {
        setSelectedCompanies(selectedCompanies);
    }

    const handleSave = () => {
        return pinkWebSettingService.assignSettings([...selectedCompanies.values()]);
    }

    const saveCallback = () => {
        showAlert({ type: ResponseTypeEnum.Success, message: t("SettingsAppliedSuccessfully", {ns: "general"})});
        fetchTableData();
        refreshData();
    }

    return (
        <Modal className="modal-xl ar-view-all-modal ar-modal">
            <ModalHeader onClose={() => onClose()}>
                <h1 className="modal-title">{t("AllCompanies", { ns: "general" })}</h1>
            </ModalHeader>
            <ModalBody>
                <div>{t("CurrentCompany", { ns: "general" })}: <b>{currentCompany}</b></div>
                <br />
                <div>{t("CurrentEndpoint")}: <b>{currentEndpoint || t("DefaultEndpoint")}</b></div>
                <br />

                <div className="row m-0 p-0 align-items-center justify-content-between">
                    <div className="col-lg-7 p-0 col-md-7 col-sm-7 col-xs-12 mb-24">
                        {t("ViewAllInstructions")}
                    </div>
                    <div className="col-lg-4 col-md-5 col-sm-5 col-xs-12 mb-24">
                        <SearchBar onChange={(value) => setSearch(value)} />
                    </div>
                </div>
                <ViewAllCompaniesTable
                    data={data}
                    headers={[
                        t("Name", { ns: "general" }),
                        t("Endpoint"),
                        t("Username", { ns: "general" }),
                    ]}
                    columns={[
                        ({ CompanyName }) => CompanyName,
                        ({ Endpoint, UseDefaultEndpoint }) => UseDefaultEndpoint ? t("DefaultEndpoint") : Endpoint,
                        ({ Username }) => Username
                    ]}
                    rowId={({ CompanyId }) => CompanyId.toString()}
                    search={search}
                    onSelect={handleSelect}
                    loading={hasMore}
                />
            </ModalBody>
            <ModalFooter className="d-flex justify-content-end">
                <ActionButton onclick={handleSave}
                    text={t("Save", { ns: "general" })}
                    buttonClassName="btn-primary"
                    iconClassName=""
                    callback={saveCallback}
                    disabled={selectedCompanies.size === 0}
                    dataTestId="save-view-all-button" />
                <button className="btn btn-default" onClick={() => onClose()}>{t("Cancel", { ns: "general" })}</button>
            </ModalFooter>
        </Modal >
    )
};