import * as React from 'react';
import { PropsWithChildren } from 'react';
import { ClassNameBuilder } from '../utils/ClassNameBuilder';
import { isDefined } from '../utils/isDefined';

export type InputFieldType = 'text' | 'password' | 'email' | 'number';

export interface IInputFieldProps {
    onChange: (value: string) => void;
    type?: InputFieldType;
    value?: string;
    disabled?: boolean;
    placeholder?: string;
    hasError?: boolean;
}

export const InputField: React.FC<PropsWithChildren<IInputFieldProps> & React.HTMLProps<HTMLInputElement>> = ({
    className,
    type,
    disabled,
    placeholder,
    value,
    hasError = false,
    onChange,
    ...others
}) => {
    const classBuilder = new ClassNameBuilder(className);
    classBuilder.add('input').add(type, isDefined(type)).add('has-error', hasError);

    return (
        <input
            className={classBuilder.build()}
            type={type}
            disabled={disabled}
            placeholder={placeholder}
            value={value}
            onChange={(event) => onChange(event.target.value)}
            {...others}
        />
    );
};
