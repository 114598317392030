import { useState } from "react";
import { IFormInput } from "../interfaces";

export const FormInput = ({ value, onChange, error, isPassword, label, disabled, placeholder, inputRef, fullWidth, autocomplete }: IFormInput) => {
    const [showTooltip, setShowTooltip] = useState<boolean>(false);
    const [previewPassword, setPreviewPassword] = useState<boolean>(false);

    return (
        <div className="d-flex align-items-center">
            <input
                ref={inputRef}
                type={isPassword && !previewPassword ? "password" : "text"}
                placeholder={placeholder}
                className={"ar-input " + (error ? " has-error " : "") + (fullWidth ? "w-100" : "w-36")}
                value={value}
                onChange={(e) => onChange(e.target.value)}
                aria-label={label}
                autoComplete={autocomplete ? autocomplete : "on"}
                disabled={disabled}
            />

            {isPassword && !disabled && value?.length > 0 &&
                <span
                    data-testid="preview-icon"
                    className={`ml-n32 ar-preview vismaicon ${previewPassword ? "vismaicon-preview" : "vismaicon-preview-off"}`}
                    onClick={() => { setPreviewPassword(!previewPassword); }} />}

            {error &&
                <>
                    <div className="d-flex ml-8">
                        <span
                            data-testid="error-icon"
                            style={{ cursor: "pointer" }}
                            onMouseEnter={() => setShowTooltip(true)}
                            onMouseLeave={() => setShowTooltip(false)}
                            className="vismaicon vismaicon-sm vismaicon-filled vismaicon-error" />
                    </div>
                    {showTooltip &&
                        <div className="position-relative top-n24">
                            <div className="tooltip tooltip-error right in" role="tooltip">
                                <div className="tooltip-arrow"></div>
                                <div className="tooltip-inner">
                                    {error}
                                </div>
                            </div>
                        </div>}
                </>}
        </div>
    );
};
