import { useTranslation } from "react-i18next";
import { IAltinnCredentials } from "../../../interfaces";
import { ConfirmModal } from "../../../components/ConfirmModal";

export const DeleteComputerSystemIdModal = ({ computerSystemId, onClose, onDelete }: { computerSystemId: IAltinnCredentials, onClose: () => any, onDelete: (compyterSystemId: string) => any }) => {
    const { t } = useTranslation("altinn");

    const handleDelete = () => {
        onDelete(computerSystemId.Text)
            .finally(() => {
                onClose();
            });
    }

    return (
        <ConfirmModal
            title={t("RemoveComputerSystemIdTitle")}
            message={`${t("RemoveComputerSystemIdTitle")} - ${computerSystemId.Text}`}
            description={t("RemoveComputerSystemIdWarning", { count: computerSystemId.CompaniesAssociated })}
            onSuccess={() => handleDelete()}
            onClose={() => onClose()}
        />
    );
};
