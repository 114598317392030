import axiosUtils from '../utils/axios';
import { IMultiselectButtonValue, IPage, IPageQuery, IReportFilterOptions, IReportMetadata, IReportData, AnyObject, IDefaultResponseModel, IChangeStatusModel } from '../interfaces';
import { toLocaleDateString } from '../utils';
const baseUrl = `api/reports`

const getPage = async (pageQuery: IPageQuery): Promise<IPage<IReportMetadata>> => {
  var filters = customizeReportFilters(pageQuery.Filters);

  const result = await axiosUtils.instance.get<IPage<IReportMetadata>>(baseUrl, { params: { pageNumber: pageQuery.PageNumber, pageSize: pageQuery.PageSize, ...filters, ...pageQuery.OrderBy }, signal: pageQuery.AbortSignal });
  return result.data;
}

const getFilterOptions = async (pageQuery: IPageQuery): Promise<IReportFilterOptions> => {
  var filters = customizeReportFilters(pageQuery.Filters);

  const result = await axiosUtils.instance.get<IReportFilterOptions>(`${baseUrl}/getFilterOptions`, { params: { pageNumber: pageQuery.PageNumber, pageSize: pageQuery.PageSize, ...filters } });
  return result.data;
}

const getReportStatusGroupMetrics = async (pageQuery: IPageQuery): Promise<Record<string, IMultiselectButtonValue>> => {
  var filters = customizeReportFilters(pageQuery.Filters);

  const result = await axiosUtils.instance.get<Record<string, IMultiselectButtonValue>>(`${baseUrl}/getReportStatusGroupMetrics`, { params: { pageNumber: pageQuery.PageNumber, pageSize: pageQuery.PageSize, ...filters } });
  return result.data;
}

const getReport = async (referenceId: string): Promise<IReportData> => {
  const result = await axiosUtils.instance.get<IReportData>(`${baseUrl}/${referenceId}`);
  return result.data;
}

const getCount = async (): Promise<number> => {
  const result = await axiosUtils.instance.get<number>(`${baseUrl}/count`);
  return result.data;
}

const archiveReports = async (reportIds: Set<string>): Promise<object> => {
  return await axiosUtils.instance.post(`${baseUrl}/archiveReports`, Array.from(reportIds.values()));;
}

const unarchiveReport = async (reportId: string): Promise<object> => {
  return await axiosUtils.instance.post(`${baseUrl}/unarchiveReport/${reportId}`);
}

const cancelReport = async (reportId: string): Promise<IDefaultResponseModel> => {
  const result = await axiosUtils.instance.post<IDefaultResponseModel>(`${baseUrl}/cancelReport/${reportId}`);
  return result.data;
}

const resendReport = async (reportId: string): Promise<IDefaultResponseModel> => {
  const result = await axiosUtils.instance.post<IDefaultResponseModel>(`${baseUrl}/resendReport/${reportId}`);
  return result.data;
}

const getReportMetadata = async (reportId: string): Promise<IReportMetadata> => {
  const result = await axiosUtils.instance.get<IReportMetadata>(`${baseUrl}/metadata/${reportId}`);
  return result.data;
}

const customizeReportFilters = (filters: AnyObject | undefined): AnyObject => {
  var newFilters = { ...filters };
  if (newFilters) {
    if (newFilters["PeriodStart"])
      newFilters["PeriodStart"] = toLocaleDateString(newFilters["PeriodStart"]);
    if (newFilters["PeriodEnd"])
      newFilters["PeriodEnd"] = toLocaleDateString(newFilters["PeriodEnd"]);
  }
  return newFilters;
}

const getChangeStatusModel = async (reportId: string): Promise<IChangeStatusModel> => {
  const result = await axiosUtils.instance.get<IChangeStatusModel>(`${baseUrl}/changeStatusModel/${reportId}`);
  return result.data;
}

const changeReportStatus = async (reportId: string, newStatus: string, statusMessage: string): Promise<IDefaultResponseModel> => {
  const result = await axiosUtils.instance.post<IDefaultResponseModel>(`${baseUrl}/changeStatus`, { Id: reportId, NewStatus: newStatus, StatusMessage: statusMessage });
  return result.data;
}

const sendReport = async (reportId: string, sscName: string, authenticationModel: object): Promise<IDefaultResponseModel> => {
  const result = await axiosUtils.instance.post<IDefaultResponseModel>(`${baseUrl}/sendReport`, {
    ReferenceId: reportId,
    SscName: sscName,
    AuthenticationModelJson: JSON.stringify(authenticationModel)
  });

  return result.data;
}

export default {
  getPage,
  getFilterOptions,
  getReportStatusGroupMetrics,
  getReport,
  getCount,
  archiveReports,
  unarchiveReport,
  cancelReport,
  getChangeStatusModel,
  changeReportStatus,
  resendReport,
  getReportMetadata,
  sendReport
}