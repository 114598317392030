import * as React from 'react';
import { PropsWithChildren } from 'react';
import { ClassNameBuilder } from '../utils/ClassNameBuilder';

export const Panel: React.FC<PropsWithChildren<React.HTMLProps<HTMLDivElement>>> = ({
    className,
    children,
    ...others
}) => {
    const classBuilder = new ClassNameBuilder(className);
    classBuilder.add('panel', true);

    return (
        <>
            <div className={classBuilder.build()} {...others}>
                {children}
            </div>
        </>
    );
};
