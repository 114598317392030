import {  IDefaultResponseModel, IAltinnAuthenticationInfo, IRequestOtpData, IAltinnAuthenticationModel } from "../interfaces";
import axiosUtils from '../utils/axios';

const baseUrl = "api/altinn/authentication"

const requestOtp = async (data: IRequestOtpData): Promise<IDefaultResponseModel> => {
    const result = await axiosUtils.instance.post<IDefaultResponseModel>(`${baseUrl}/requestOtp`, data);
    return result.data;
}

const logData = async (data: IAltinnAuthenticationInfo): Promise<void> => {
    const result = await axiosUtils.instance.post<void>(`${baseUrl}/logData`, data);
    return result.data;
}

const getAuthenticationModel = async (companyId: number, reportType: string): Promise<IAltinnAuthenticationModel> => {
    const result = await axiosUtils.instance.get<IAltinnAuthenticationModel>(`${baseUrl}/authenticationModel/${companyId}/${reportType}`);
    return result.data;
}

export default {
    getAuthenticationModel,
    requestOtp,
    logData
}