import { handleKeyDown } from "../utils";
import { KeyboardEvent, useEffect } from "react";
import { IContext, IUser } from "../interfaces";
import { useClickOutside } from "../hooks/ClickOutsideHook";
import { NavBarContextSelector } from "./NavBarContextSelector";
import { useLocation, useNavigate } from "react-router-dom";
import navigationService from "../services/navigationService";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "../hooks/MediaQueryHook";
import { ScreenSizeEnum } from "../utils/constants";

export const NavBarUserMenu = ({ User, CurrentContext, ContextCount, setCurrentCompany }: 
        { User: IUser, CurrentContext: IContext, ContextCount: number, setCurrentCompany: (context: IContext) => void }) => {
    const [ref, showUserMenu, setShowUserMenu] = useClickOutside<HTMLLIElement>();
    const { search, pathname } = useLocation();
    const navigate = useNavigate();
    const { t } = useTranslation("general");
    const matches = useMediaQuery(ScreenSizeEnum.LG);

    function handleToogleUserSection() {
        setShowUserMenu(!showUserMenu);
    }

    function handleKeyDownInternal(event: KeyboardEvent<HTMLLIElement>): void {
        handleKeyDown(event, "Enter", () => setShowUserMenu(true));
        handleKeyDown(event, "Escape", () => setShowUserMenu(false));
    }

    function handleOnChangeContext(newContext: IContext) {
        setShowUserMenu(false);
        const query = new URLSearchParams(search);
        const contextId = newContext.Id < 0 ? newContext.TenantId : newContext.Id;
        query.set("context", `${contextId}`);
        navigate(`${pathname}?${query}`);
    }

    useEffect(() => {
        const query = new URLSearchParams(search);
        var contextId = query.get("context");
        if (contextId == null)
            contextId = query.get("contextSelector:switchContext");

        if (contextId === null)
            return;

        navigationService.switchContext(contextId).then((data) => {
            if (data === null || data.Id == 0) {
                navigate("/error/500");
                return;
            }
            
            setCurrentCompany(data);
        })
    }, [search]);

    return (
        <li className={`dropdown user-dropdown  ${!matches && 'icon'} ${showUserMenu === true ? "open" : ""}`} data-testid="user-menu-dropdown" ref={ref} tabIndex={0} onKeyDown={handleKeyDownInternal} >
            <a className="dropdown-toggle button-context"
                onClick={handleToogleUserSection}
                data-toggle="dropdown"
                role="button"
                aria-expanded={showUserMenu}
                data-testid="user-menu-toggle"
                aria-label={`${User.FirstName} ${User.LastName} : ${CurrentContext.Name}`}>

                {`${User.FirstName} ${User.LastName}`}
                <small className="selectedContext">{CurrentContext.Name}</small>

                <span className="vismaicon vismaicon-dynamic vismaicon-menu vismaicon-user"></span>
                <span className="caret"></span>
            </a>

            <ul className="dropdown-menu company-selection ar-user-menu pt-32" role="menu">
                <li className="overflow-visible">
                    <b data-testid="company-count">{t("Select")} {t("Company").toLowerCase()} - {ContextCount} {ContextCount > 1 ? t("Companies").toLowerCase() : t("Company").toLowerCase()}</b>
                    <NavBarContextSelector
                        currentContext={CurrentContext}
                        onChange={handleOnChangeContext}
                    />
                </li>
                <li className="divider" />
                <li className="clear" role="menuitem">
                    <a href={User.UserDetailsUrl} className="d-flex align-items-center" data-testid="user-details">
                        <span className="vismaicon vismaicon-dynamic ar-user vismaicon-sm"></span>
                        {t("ViewMyDetails")}
                    </a>
                </li>
                <li className="clear" role="menuitem">
                    <a className="d-flex align-items-center" data-testid="about-autoreport" href="/about">
                        <span className="vismaicon ar-icon-sm vismaicon-dynamic vismaicon-info"></span>
                        {t("About")}  AutoReport
                    </a>
                </li>
                <li className="divider"></li>

                <li className="clear" role="menuitem">
                    <a className="d-flex align-items-center justify-content-between" data-testid="user-logout" href={`${process.env.REACT_APP_API_BASEURL}/connectIdp/selfsignout`} target="_self" tabIndex={0}>
                        <span className="d-flex"> <span className="vismaicon ar-icon-sm vismaicon-logout mr-8"></span> {t("Logout")} </span>
                        <span className="ar-last-login">
                            {User.LastLogin !== "" && (<span> {t("LastLogin")}<br /> {User.LastLogin} </span>)}
                        </span>
                    </a>
                </li>
            </ul>
        </li>
    );
}
