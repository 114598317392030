import { createContext } from "react";
import { IAltinnCredentials, ISelectModel, ISystemAuthentication, IUserAuthentication } from "../interfaces";
import { AltinnUserAuthenticationContextEnum } from "../utils/constants";

export interface IAltinnSettingsContext {
    computerSystemId: IAltinnCredentials,
    password: string,
    setDefaultReportStep: (value: string) => any,
    setComputerSystemId: (value: ISelectModel | undefined) => any,
    setPassword: (value: string) => any,
    handleDeleteComputerSystemId: (value: string) => any,
    fetchData: () => any
}

export interface IComputerSystemFormContext {
    computerSystemId: string,
    password: string,
    setComputerSystemId: (value: string) => any,
    setPassword: (value: string) => any,
}

export interface IUserCredentialsFormContext {
    companyId: number,
    ids: string[],
    source: AltinnUserAuthenticationContextEnum,
    authenticationType: string,
    systemAuthentication: ISystemAuthentication,
    userAuthentication: IUserAuthentication,
    useNewSettings: boolean,
    setUserAuthentication: (value: IUserAuthentication) => void
}

export const AltinnSettingsContext = createContext<IAltinnSettingsContext>({} as IAltinnSettingsContext);
export const ComputerSystemFromContext = createContext<IComputerSystemFormContext>({} as IComputerSystemFormContext);
export const UserCredentialsFormContext = createContext<IUserCredentialsFormContext>({} as IUserCredentialsFormContext);

