import { SignInToIdPortenResponseModel, IAltinnIdPortenAuthenticationModel } from "../interfaces";
import axiosUtils from '../utils/axios';

const baseUrl = "api/idporten"

const getAuthenticationModel = async (reportId: string): Promise<IAltinnIdPortenAuthenticationModel> => {
    const result = await axiosUtils.instance.get<IAltinnIdPortenAuthenticationModel>(`${baseUrl}/authenticationModel/${reportId}`);
    return result.data;
}

const signInToIdPorten = async (originalUrl: string): Promise<SignInToIdPortenResponseModel> => {
    const result = await axiosUtils.instance.post<SignInToIdPortenResponseModel>(`${baseUrl}/signinLink`, {originalUrl: originalUrl});
    return result.data;
}

export default{
    getAuthenticationModel,
    signInToIdPorten
}