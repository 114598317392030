import { useState, useEffect, Suspense } from "react";
import { useNavigate, Outlet, useSearchParams } from "react-router-dom";
import { IContext, IMenuData } from "../interfaces";
import axiosUtils from "../utils/axios";
import { GlobalLoadingSpinner } from "../components/GlobalLoadingSpinner";
import { NavBar } from "./NavBar";
import { useTranslation } from "react-i18next";
import { setDefaultLocale } from "react-datepicker";
import { useToast } from "../hooks/ToastHook";
import { ApplicationContext } from "../contexts/ApplicationContext";
import { Navbar, NavbarBrand } from "@vismaux/react-nc4";
import { FeedbackButton } from "../components/FeedbackButton";
import { Wootric } from "../components/Wootric";

export const ProtectedRoute = () => {
    const navigate = useNavigate();
    const [menuData, setMenuData] = useState<IMenuData>();
    const [searchParams] = useSearchParams({});
    var menu = searchParams.get("menu");
    var header = searchParams.get("header");
    var fromSettings = searchParams.get("fromSettings");

    const { i18n } = useTranslation();
    const [toast, showToast] = useToast();

    const [showMenu, setShowMenu] = useState<boolean>(true);
    const [showHeader, setShowHeader] = useState<boolean>(true);

    useEffect(() => {
        let userTimeZoneHeaderName = process.env.REACT_APP_USER_TIMEZONE_HEADER_NAME as string;
        let userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

        axiosUtils.instance
            .get<IMenuData>("api/menu/data", {
                headers:  { [userTimeZoneHeaderName]: userTimeZone }
            })
            .then((response) => {
                if (response.data) {
                    setMenuData(response.data);
                    i18n.changeLanguage(response.data.UserLanguageCode);
                    setDefaultLocale(response.data.DateLocale);
                } else {
                    navigate("/error/500");
                }
            })
    }, []);

    useEffect(() => {
        if (menu == null) {
            setShowMenu(true);
            return;
        }

        switch (menu) {
            case "true":
                setShowMenu(true);
                break;
            case "false":
                setShowMenu(false);
                break;
            default:
                setShowMenu(true);
                break;
        }
    }, [menu])

    useEffect(() => {
        if (header == null) {
            setShowHeader(true);
            return;
        }

        switch (header) {
            case "true":
                setShowHeader(true);
                break;
            case "false":
                setShowHeader(false);
                break;
            default:
                setShowHeader(true);
                break;
        }
    }, [header])

    useEffect(() => {
        var root = document.getElementById("root");

        if (root) {
            if (!showMenu && !showHeader)
                root.style.paddingTop = "0rem";
            else
                root.style.paddingTop = "6.5rem";
        }

    }, [showMenu, showHeader])

    useEffect(() => {
        searchParams.delete("fromSettings");
    }, [fromSettings])

    const setCurrentCompany = (context: IContext) => {
        if (menuData) {
            var newMenuData: IMenuData = {
                Applications: menuData.Applications,
                UserLanguageCode: menuData.UserLanguageCode,
                DateLocale: menuData.DateLocale,
                IsAdmin: menuData.IsAdmin,
                UserMenuData: {
                    ContextCount: menuData.UserMenuData.ContextCount,
                    User: menuData.UserMenuData.User,
                    CurrentContext: context
                },
                WootricData: menuData.WootricData
            }
            setMenuData(newMenuData);
        }
    }

    return (
        <ApplicationContext.Provider value={{ showToast: showToast }}>
            {toast}
            {menuData ?
                <>
                    {showMenu && <NavBar menuData={menuData} setCurrentCompany={setCurrentCompany} />}
                    {!showMenu && header !== "false" &&
                        <Navbar >
                            <NavbarBrand>
                                <a className="dropdown-toggle"
                                    role="button"
                                    aria-expanded="false"
                                    aria-label="Visma Project Name"
                                    data-testid="project-name">
                                    Visma.net AutoReport
                                </a>
                            </NavbarBrand>
                        </Navbar>
                    }
                    {!showMenu && <FeedbackButton />}
                    <Suspense>
                        <Outlet context={menuData.UserMenuData.CurrentContext} />
                    </Suspense>
                    <Wootric data={menuData.WootricData} />
                </> :
                <GlobalLoadingSpinner />
            }
        </ApplicationContext.Provider>
    )
}