import { FetchFeedbackResponse, IAltinnAuthenticationData, ReportFetchList } from '../interfaces';
import axiosUtils from '../utils/axios';

const baseUrl = "api/feedback"

const getReportFetchList = async (reportIds: string[]): Promise<ReportFetchList> => {
    const result = await axiosUtils.instance.get<ReportFetchList>(baseUrl, { params: { reportIds } });
    return result.data;
}

const fetchResponse = async (reportIds: string[], authenticationModel: IAltinnAuthenticationData | undefined): Promise<FetchFeedbackResponse> => {
    const request = {
        ReportIds: reportIds,
        ...authenticationModel
    }

    const result = await axiosUtils.instance.post<FetchFeedbackResponse>(baseUrl, request);
    return result.data;
}

export default {
    getReportFetchList,
    fetchResponse,
}