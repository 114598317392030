import { useId } from "react";
import { IRadioButton } from "../interfaces";

export const RadioButton = ({ label, value, selectedValue, onChange, disabled }: IRadioButton) => {
    const id = useId();

    return (
        <div className="radio">
            <input
                type="radio"
                id={id}
                value={value}
                checked={value === selectedValue}
                onChange={(e) => onChange(e.target.value)}
                disabled={disabled} />
            <label htmlFor={id}>{label}</label>
        </div>
    );
};
