import { Suspense, useEffect, useState } from "react";
import { useLocation, useNavigate, useOutletContext, useParams } from "react-router-dom";
import { IAltinnTaxCardDownloadData } from "../../interfaces/TaxCard";
import { ActionButton, Alert, AltinnAuthentication, ComponentLoadingSpinner } from "../../components";
import { TaxCardRowContentValue } from "./TaxCardRowContentValue";
import { useTranslation } from "react-i18next";
import { IAltinnAuthenticationData, IAuthenticationData, IContext, IDefaultResponseModel } from "../../interfaces";
import { AltinnUserAuthenticationContextEnum, ResponseTypeEnum } from "../../utils/constants";
import { Panel, PanelBody, PanelFooter } from "@vismaux/react-nc4";
import { useAlert } from "../../hooks/AlertHook";
import { ConfirmModal } from "../../components/ConfirmModal";
import { getEditSettingsUrlAltinnDownloadTaxCard, triggerWootric } from "../../utils";
import altinnTaxCardService from "../../services/altinnTaxCardService";
import "../../styles/taxCard.css";
import { DialogCloseButton } from "../../components/DialogCloseButton";
import { sendEvent } from "../../hooks/GoogleAnalyticsHook";

const defaultValue = "n/a";

export const DownloadAltinnTaxCardPage = () => {

    const [data, setData] = useState<IAltinnTaxCardDownloadData>({} as IAltinnTaxCardDownloadData);
    const [taxCardId, setTaxCardId] = useState<string>("");
    const params = useParams();
    const { t } = useTranslation(["general", "altinn"]);
    const currentCompany = useOutletContext<IContext>();
    const [authenticationData, setAuthenticationData] = useState<IAuthenticationData>({} as IAuthenticationData);
    const [alert, showAlert, clearAlert] = useAlert();
    const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
    const { search } = useLocation();
    const navigate = useNavigate();

    const [action, setAction] = useState<string>("cancelled");

    useEffect(() => {
        var referenceId = params.id;
        if (referenceId) {
            sendEvent("altinn_taxcard_download", "Open altinn taxcard download page.");
            setTaxCardId(referenceId);
            fetchData(referenceId);
        }
    }, []);

    const fetchData = (referenceId: string) => {
        altinnTaxCardService.getTaxCardDownloadData(referenceId)
            .then((data: IAltinnTaxCardDownloadData) => {
                setData(data);
            })
            .catch((error: any) => {
                if (error?.status)
                    navigate(`/error/${error?.status}`);
            })
    }

    const handleDownloadCompleted = () => {
        if (data.Downloaded)
            setShowConfirmModal(true);
        else {
            var downloadData = {
                CompanyId: currentCompany.Id,
                SocialSecurityId: authenticationData.authenticationData.User.SocialSecurityId,
                Password: authenticationData.authenticationData.User.Password,
                PasswordChanged: authenticationData.authenticationData.User.PasswordHasChanged,
                StoreUserAuthentication: authenticationData.authenticationData.User.StoreUserAuthentication,
                StoredCredentialsExpired: authenticationData.authenticationData.User.StoredCredentialsExpired,
                OtpPassword: authenticationData.authenticationData.User.Pincode,
                SystemAuthentication: authenticationData.authenticationData.System,
                ShouldUseBusinessAuthentication: authenticationData.authenticationData.ShouldUseBusinessAuthentication,
                IsBusinessAuthenticationSet: authenticationData.authenticationData.IsBusinessAuthenticationSet
            };

            var promise = altinnTaxCardService.downloadCompleted(taxCardId, downloadData);
            return promise;
        }
    }

    const handleConfirmAction = () => {
        resendStatus();
    }

    const resendStatus = () => {
        altinnTaxCardService.resendStatus(taxCardId)
            .then((response: IDefaultResponseModel) => {
                setShowConfirmModal(false);
                if(response.ResponseType === ResponseTypeEnum.Success)
                    setAction("downloaded");
                showAlert({ type: response.ResponseType, message: <span className="d-block mt-16">{response.Message}</span>, title: response.Title, sticky: true });
            })
    }

    const downloadCompletedCallback = (data: IDefaultResponseModel) => {
        triggerWootric('download_feedback');
        
        if (data.ResponseType != ResponseTypeEnum.Success)
            showAlert({ type: data.ResponseType, message: <span className="d-block mt-16">{data.Message}</span>, title: data.Title, sticky: true });
        else {
            clearAlert();
            setAction("downloaded");
        }
        fetchData(taxCardId);
    }

    return (
        <div className="ar-page-container d-flex flex-column pl-16 pr-16">
            <div className="row ar-tax-card-details-container">
                {!data ? <ComponentLoadingSpinner /> :
                    <>
                        <div className="col">
                            <TaxCardRowContentValue title={t("OrganizationName")} value={data.OrganizationName ? data.OrganizationName : defaultValue} />
                            <TaxCardRowContentValue title={t("OrganizationNumber")} value={data.OrganizationNumber ? data.OrganizationNumber : defaultValue} />
                            <TaxCardRowContentValue title={t("RequestSent")} value={data.RequestSent ? data.RequestSent : defaultValue} />
                        </div>
                        <div className="col">
                            {data.StatusDetails && <Alert alert={{
                                type: data.StatusDetails.Category,
                                title: data.StatusDetails.Title,
                                message: data.StatusDetails.Message && <span className="d-block mt-24">{data.StatusDetails.Message}</span>,
                                sticky: true,
                            }} />
                            }
                            {alert && data.Downloaded && <div>{alert}</div>}
                            {
                                (data.NeedsDownload || data.Downloaded) &&
                                authenticationData?.authenticationData?.ShouldUseBusinessAuthentication &&
                                !authenticationData?.authenticationData?.IsBusinessAuthenticationSet &&
                                <>
                                    <Alert alert={{
                                        type: ResponseTypeEnum.Danger,
                                        title: t("MissingBusinessLogin", { ns: "altinn" }),
                                        message: '',
                                        sticky: true,
                                    }} />
                                </>
                            }
                            {(data.NeedsDownload || data.Downloaded) &&
                                <Panel className="rounded-10 tax-card-authentication-panel mb-0">
                                    <Suspense fallback={<ComponentLoadingSpinner />}>
                                        {(data.NeedsDownload) &&
                                            <PanelBody data-testid="altinn-authentication">
                                                <AltinnAuthentication
                                                    ids={[taxCardId]}
                                                    reportType="TAXCARD"
                                                    companyId={currentCompany.Id}
                                                    source={AltinnUserAuthenticationContextEnum.DownloadAltinnTaxCard}
                                                    setAuthenticationData={setAuthenticationData}
                                                    useUserAuthentication={true}
                                                    settingsUrl={getEditSettingsUrlAltinnDownloadTaxCard(taxCardId, currentCompany.Id, currentCompany.Id, search)}
                                                />
                                                {alert && !data.Downloaded && <div className="mt-32">{alert}</div>}
                                            </PanelBody>
                                        }
                                        <PanelFooter>
                                            <ActionButton
                                                onclick={handleDownloadCompleted}
                                                text={data.Downloaded ? t("DownloadAgain") : t("Download")}
                                                iconClassName="vismaicon vismaicon-sm ar-send"
                                                buttonClassName="btn-primary"
                                                disabled={authenticationData.disabled}
                                                callback={!data.Downloaded ? downloadCompletedCallback : () => { }}
                                                dataTestId="download-completed-button" />
                                        </PanelFooter>
                                    </Suspense>
                                </Panel>
                            }
                        </div>
                        <div className="mt-24 col-lg-12">
                            <DialogCloseButton action={action} dialog="altinntaxcard/download" />
                        </div>
                    </>}
                {
                    showConfirmModal &&
                    <ConfirmModal title={t("DownloadTaxCardTitle", { ns: "altinn" })}
                        description={t("DownloadTaxCardMessage", { ns: "altinn" })}
                        onSuccess={handleConfirmAction}
                        onClose={() => setShowConfirmModal(false)} />
                }
            </div>

        </div>);
}