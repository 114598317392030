import { useEffect, useState, KeyboardEvent } from "react";
import reportService from "../../services/reportService";
import { usePageFetch } from "../../hooks/PageHook";
import "../../styles/reports.css"
import { IPageQuery, IOrderByKey, IReportMetadata } from "../../interfaces";
import { useTranslation } from "react-i18next";
import { OrderableTableHeader, InfiniteScroller } from "../../components";
import { ReportTableActions, ReportTableRow } from ".";
import { useTableOrder, useTableSelect } from "../../hooks/TableHooks";
import { Checkbox } from "../../components/Checkbox";
import { TableLoadingSpinner } from "../../components/TableLoadingSpinner";
import { handleKeyDown } from "../../utils";

export const ReportTable = ({ pageQuery, onChangeOrder, refreshData, onResetFilter }: { pageQuery: IPageQuery; onChangeOrder: (orderBy: IOrderByKey) => any, refreshData: () => void, onResetFilter: () => void }) => {
    const [reports, totalFilteredCount, hasMore, loadNextPage] = usePageFetch<IReportMetadata>(reportService.getPage, pageQuery);
    const [totalReportCount, setTotalReportCount] = useState<number>(0);
    const [loading, setLoading] = useState(true);
    const [tableOrder, handleOrderChange] = useTableOrder(pageQuery.OrderBy);
    const [selectedReports, handleSelect, handleAllSelect] = useTableSelect(reports.filter(r => r.Archived === false).map(r => r.ReferenceId));
    const { t } = useTranslation("general");

    useEffect(() => {
        reportService.getCount()
            .then((response) => {
                setTotalReportCount(response);
            });
    }, []);

    useEffect(() => {
        setLoading(false);
    }, [reports])

    useEffect(() => {
        setLoading(true);
        handleAllSelect(false);
    }, [pageQuery])

    useEffect(() => {
        if (loading)
            return;

        onChangeOrder(tableOrder);
    }, [tableOrder])

    const cancelSelection = () => {
        handleAllSelect(false);
    }

    const handleKeyDownCheckbox = (event: KeyboardEvent<HTMLInputElement>) => {
        handleKeyDown(event, "Enter", () => { event.stopPropagation(); handleAllSelect(!(reports.length > 0 && reports.length === selectedReports.size)) });
    };

    return (
        <>
            <div className="report-table">
                <table className={`table table-active expandable-table mb-0 ${totalFilteredCount === 0 && "h-100"}`}>
                    <thead>
                        <tr>
                            <th>
                                <Checkbox onKeyDown={handleKeyDownCheckbox} isChecked={reports.length > 0 && reports.length === selectedReports.size} onChange={handleAllSelect} />
                            </th>
                            <OrderableTableHeader title={t("Status")} value="Status" handleOrderChange={handleOrderChange} order={tableOrder} />
                            <OrderableTableHeader title={t("OrganizationName")} value="OrganizationName" handleOrderChange={handleOrderChange} order={tableOrder} />
                            <OrderableTableHeader title={t("Type")} value="Type" handleOrderChange={handleOrderChange} order={tableOrder} />
                            <OrderableTableHeader title={t("Period")} value="PeriodStart" handleOrderChange={handleOrderChange} order={tableOrder} />
                            <OrderableTableHeader title={t("CreatedOn")} value="Created" handleOrderChange={handleOrderChange} order={tableOrder} />
                        </tr>
                    </thead>
                    <tbody className="position-relative">
                        {totalFilteredCount > 0 ?
                            <>
                                {reports.map((report: IReportMetadata) =>
                                    <ReportTableRow
                                        key={report.ReferenceId}
                                        isChecked={selectedReports.has(report.ReferenceId)}
                                        report={report}
                                        onCheck={(checked) => handleSelect(report.ReferenceId, checked)} />
                                )}
                                {loading && <TableLoadingSpinner colSpan={7} />}
                                <tr className="h-0" id="ar-spinner-loading" style={{ pointerEvents: "none" }}>
                                    <td className="h-0" colSpan={7}>
                                        <InfiniteScroller data={reports} onLoadMore={loadNextPage} disabled={!hasMore} />
                                    </td>
                                </tr>
                            </> :
                            <tr className="ar-no-reports-message-row">
                                <td colSpan={6}>
                                    <div className="ar-reportSearch"></div>
                                    <div className="ar-no-reports-message">
                                        <h1>{t("NoReportsMessage")}</h1>
                                        <div>
                                            <button onClick={onResetFilter} className="btn" data-testid="reset-filters-button">{t("Reset")}</button>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        }
                    </tbody>
                </table>
            </div >
            <ReportTableActions selectedReports={selectedReports} onCancel={cancelSelection} refreshData={refreshData} totalFilteredCount={totalFilteredCount} totalReportCount={totalReportCount} />
        </>
    );
};
