import { AnyObject } from "../interfaces";
import { PRESETS, PresetsEnum } from "../utils/constants";

const reportsFiltersKey = "reportListFiltersV2";
export const DEFAULT_PRESET_INDEX = PRESETS.findIndex(el => el.Id === PresetsEnum.Last12Months);
export const DEFAULT_FILTERS = {
    PeriodStart: PRESETS[DEFAULT_PRESET_INDEX].Interval.PeriodStart,
    PeriodEnd: PRESETS[DEFAULT_PRESET_INDEX].Interval.PeriodEnd,
    DateFilterMode: "0",
    ArchiveStates: ["0"]
}

const saveFiltersToStorage = (filters: AnyObject) => {
    if (filters["PeriodStart"])
        filters["PeriodStart"] = new Date(filters["PeriodStart"]).toDateString();

    if (filters["PeriodEnd"])
        filters["PeriodEnd"] = new Date(filters["PeriodEnd"]).toDateString();

    localStorage.setItem(reportsFiltersKey, JSON.stringify(filters));
}

const savePresetToStorage = (preset: string) => {
    localStorage.setItem("Preset", preset);
}

const getFiltersFromStorage = () => {
    var filters = localStorage.getItem(reportsFiltersKey);
    if (filters) {
        var parsedFilters = JSON.parse(filters);
        var presetIndex = getPresetFromStorage();

        if (presetIndex && PRESETS[presetIndex].Id !== PresetsEnum.Custom) {
            var interval = PRESETS[presetIndex].Interval;
            parsedFilters["PeriodStart"] = interval.PeriodStart;
            parsedFilters["PeriodEnd"] = interval.PeriodEnd;
        }
        else {
            if (parsedFilters["PeriodStart"])
                parsedFilters["PeriodStart"] = new Date(parsedFilters["PeriodStart"]);

            if (parsedFilters["PeriodEnd"])
                parsedFilters["PeriodEnd"] = new Date(parsedFilters["PeriodEnd"]);
        }
        return parsedFilters;
    }
    else
        return DEFAULT_FILTERS;
}

const getPresetFromStorage = (): number => {
    var preset = localStorage.getItem("Preset");
    if (preset)
        return Number(preset);

    return DEFAULT_PRESET_INDEX;
}

export default {
    saveFiltersToStorage,
    savePresetToStorage,
    getFiltersFromStorage,
    getPresetFromStorage
}