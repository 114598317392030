import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useOutletContext, useParams, useSearchParams } from "react-router-dom";
import { DialogCloseButton } from "../../../components/DialogCloseButton";
import { useAlert } from "../../../hooks/AlertHook";
import fetchResponseService from "../../../services/fetchResponseService";
import { AltinnUserAuthenticationContextEnum, ResponseTypeEnum } from "../../../utils/constants";
import { useTranslation } from "react-i18next";
import { FetchFeedbackResponse, IAuthenticationData, IContext, ReportFetchList } from "../../../interfaces";
import { AltinnAuthentication } from "../../../components/authentication/AltinnAuthentication";
import { getEditSettingsUrlAltinnForFetchFeedback, getQueryParameter, inIFrame, isParentEaccounting, triggerWootric } from "../../../utils";
import { FetchFeedbackTable } from "./FetchFeedbackTable";
import { AltinnCredentialsTimer, Modal, ModalBody, ModalFooter, ModalHeader } from "../../../components";
import { useClickInputHook } from "../../../hooks/ClickInputHook";
import { sendEvent } from "../../../hooks/GoogleAnalyticsHook";
import "../../../styles/feedback.css";
import "../../../styles/buttons.css";

export const FetchFeedbackPage = () => {

    const params = useParams();
    const { search } = useLocation();
    const [searchParams] = useSearchParams();
    const currentCompany = useOutletContext<IContext>();
    const navigate = useNavigate();

    const [reportIds, setReportIds] = useState<string[]>([]);
    const [data, setData] = useState<ReportFetchList>();

    const [savedAuthenticationData, setSavedAuthenticationData] = useState<IAuthenticationData>();
    const [authenticationFormData, setAuthenticationFormData] = useState<IAuthenticationData>();

    const [showModal, setShowModal] = useState(false);

    const [alert, setAlert, clearAlert] = useAlert();
    const { t } = useTranslation(["altinn", "general"]);
    const alertMessageRef = useRef<HTMLDivElement>(null);

    const [action, setAction] = useState<string>("cancelled");
    useClickInputHook(clearAlert);

    useEffect(() => {
        sendEvent("feedback", "Open feedback page.");
        const referenceIds = params.id ? [params.id] : searchParams.getAll("id");
        if (referenceIds) {
            setReportIds(referenceIds);
            getReportFetchList(referenceIds);

            const fromSettings = getQueryParameter(search, "fromSettings")?.toLowerCase() === "true";
            setShowModal(fromSettings);
        }
    }, [])

    useEffect(() => {
        if (authenticationFormData && !savedAuthenticationData) {
            setSavedAuthenticationData(authenticationFormData);
        }
    }, [authenticationFormData])

    const onFetch = () => {
        if (!data || data.FetchableReports.length === 0)
            return;

        triggerWootric('download_feedback');

        fetchResponseService.fetchResponse(data.FetchableReports.map(r => r.ReferenceId), authenticationFormData?.authenticationData)
            .then((fetchResponse: FetchFeedbackResponse) => {
                const updatedFetchableReports = data.FetchableReports.map((r: any) => ({ ...r, FetchStatus: fetchResponse.Reports[r.ReferenceId] }));
                const newData: ReportFetchList = {
                    ...data,
                    FetchableReports: updatedFetchableReports,
                    FetchFromAltinn: fetchResponse.FetchFromAltinn,
                    FetchedAmount: fetchResponse.FetchedAmount,
                    NotFetchedAmount: fetchResponse.NotFetchedAmount
                };

                setData(newData);

                const success = fetchResponse.Success;
                const message = success ? t("FetchResponsesAgainMessage") : fetchResponse.Message;
                const title = success ? t("FetchResponsesAgainTitle") : t("ErrorFetchingResponseForSomeReports");

                setAlert({ title, message: <div className="d-block mt-24 ws-preline" ref={alertMessageRef}></div>, type: success ? ResponseTypeEnum.Success : ResponseTypeEnum.Danger });

                if(alertMessageRef.current)
                    alertMessageRef.current.innerHTML = message;

                if (success) {
                    setAction("downloaded");
                    setSavedAuthenticationData(undefined);
                } else if (!fetchResponse.LoginError) {
                    setSavedAuthenticationData(undefined);
                }
                else if (!authenticationFormData?.authenticationData.IsBusinessAuthenticationSet) {
                    setShowModal(true);
                }

            })
            .catch((error: any) => {
                if (error?.status)
                    navigate(`/error/${error.status}`);
            });
    }

    const getReportFetchList = (referenceIds: string[]) => {
        clearAlert();
        fetchResponseService.getReportFetchList(referenceIds).then((response) => {
            setData(response);
        }).catch((error: any) => {
            setData({ FetchableReports: [], UnfetchableReports: [], FetchFromAltinn: false, NotFetchedAmount: 0, FetchedAmount: 0, ReportType: ""});

            const status = error?.status;
            if (status === 400) {
                setAlert({ title: `${t("Error")}:`, message: error?.data ?? t("SomethingWentWrongTitle", { ns: "general" }), type: ResponseTypeEnum.Danger });
            }
            else if (status) {
                navigate(`/error/${error.status}`);
            }
        });
    }

    const disableFetch = () => {
        if (!data)
            return true;
        if (data.FetchableReports.length === 0)
            return true;
        if (data.FetchableReports.length > 0 && savedAuthenticationData?.disabled)
            return true;

        return false;
    }

    const onSave = () => {
        setSavedAuthenticationData(authenticationFormData);
        setShowModal(false);
    }

    const onClose = () => {
        setShowModal(false);
        if (savedAuthenticationData) {
            setSavedAuthenticationData({ ...savedAuthenticationData });
        }
    }

    const showTimer = () => {
        if (authenticationFormData?.authenticationData.ShouldUseBusinessAuthentication)
            return false;

        const userData = authenticationFormData?.authenticationData.User;
        return userData && userData.ExpirationInSeconds !== undefined && !userData.StoredCredentialsExpired;
    }

    return (
        <div className="ar-page-container d-flex flex-column">
            <div className="p-16 flex-grow-1">
                {data &&
                    <>
                        <div className="ar-alert-container">
                            {alert}
                        </div>
                        <FetchFeedbackTable title={`${t("FetchableReportsTitle")}:`} reports={data.FetchableReports} />
                        <FetchFeedbackTable title={`${t("UnfetchableReportsTitle")}:`} reports={data.UnfetchableReports} />
                        {data.FetchFromAltinn &&
                            <div data-testid="fetch-settings-modal" className={showModal ? "" : "d-none"}>
                                <Modal className={`ar-modal ${inIFrame() ? "modal-full ar-modal-full" : "ar-view-all-modal modal-xl"}`}>
                                    <ModalHeader onClose={onClose}>
                                        <h1 className="modal-title">{t("FetchResponsesSettings")}</h1>
                                    </ModalHeader>
                                    <ModalBody className="pt-0 pb-0">
                                        <div className="pr-8">
                                            <AltinnAuthentication
                                                companyId={currentCompany.Id}
                                                ids={reportIds}
                                                reportType={data.ReportType}
                                                source={AltinnUserAuthenticationContextEnum.DownloadReportFeedback}
                                                settingsUrl={getEditSettingsUrlAltinnForFetchFeedback(reportIds, currentCompany.Id, currentCompany.Id, search)}
                                                setAuthenticationData={setAuthenticationFormData}
                                                authenticationData={savedAuthenticationData}
                                                useUserAuthentication />
                                        </div>
                                    </ModalBody>
                                    <ModalFooter >
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div>
                                                {
                                                    isParentEaccounting(search) &&
                                                    <a className="d-flex align-items-center ar-link-button" target="_blank" rel="noreferrer" href="https://community.visma.com/t5/Brukertips/Innstillinger-i-Altinn-for-Visma-eAccounting-Lonnslipp/ta-p/29898" data-testid="information-altinn">
                                                        <span className="vismaicon vismaicon-sm vismaicon-dynamic vismaicon-export"></span>
                                                        {t("InformationAltinnSettings")}
                                                    </a>
                                                }
                                            </div>
                                            <div>
                                                <button className="btn btn-primary mr-32 mb-0" data-testid="confirm-button" disabled={authenticationFormData?.disabled} onClick={onSave}>{t("Save", { ns: "general" })}</button>
                                                <button className="btn btn-default mb-0" data-testid="cancel-confirm-button" onClick={onClose}>{t("Cancel", { ns: "general" })}</button>
                                            </div>
                                        </div>
                                    </ModalFooter>
                                </Modal >
                            </div>
                        }
                    </>
                }
            </div >
            <div className="row ar-sticky-bottom-action d-flex ar-gap p-8 m-0 justify-content-between">
                <div className="d-flex align-items-center">
                    <div className="ml-16">
                        <b>{t("FetchResponse")}</b>
                        <b className="ml-8" data-testid="fetched-amount">{data ? data.FetchedAmount : 0}</b> {t("ResponsesFetched")}
                        ,
                        <b className="ml-8" data-testid="not-fetched-amount">{data ? data.NotFetchedAmount : 0}</b> {t("NotFetched")}
                    </div>
                    <div className="ml-16">
                        <button
                            type="button"
                            className="btn btn-primary m-0"
                            onClick={onFetch}
                            disabled={disableFetch()}>

                            {t("Fetch", { ns: "general" })}
                        </button>
                    </div>
                    <div className="ml-16">
                        {
                            authenticationFormData?.authenticationData.ShouldUseBusinessAuthentication &&
                            authenticationFormData?.authenticationData.IsBusinessAuthenticationSet && 
                            <b>{t("UseBusinessCertificateForFeedbackText")}</b>
                        }
                        {
                            authenticationFormData?.authenticationData.ShouldUseBusinessAuthentication &&
                            !authenticationFormData?.authenticationData.IsBusinessAuthenticationSet && 
                            <b className="ar-fetch-error-text">{t("MissingBusinessLogin")}</b>
                        }
                    </div>
                    {!authenticationFormData?.authenticationData.ShouldUseBusinessAuthentication && 
                    !authenticationFormData?.authenticationData.IsBusinessAuthenticationSet &&
                        <div className="ml-16">
                            <button
                                type="button"
                                className={`btn m-0 ${disableFetch() ? 'btn-primary' : ""}`}
                                disabled={!data?.FetchFromAltinn}
                                onClick={() => setShowModal(true)}>
                                {t("LoginToAltinn")}
                            </button>
                        </div>
                    }
                    {showTimer() &&
                        <div className="ml-16">
                            <AltinnCredentialsTimer initialSeconds={authenticationFormData ? authenticationFormData.authenticationData.User.ExpirationInSeconds : 0} setExpired={() => { setSavedAuthenticationData(undefined) }} />
                        </div>
                    }
                </div>
                <DialogCloseButton action={action} dialog="altinnresponse/index" className="return-button-container" />
            </div>
        </div >
    );
}