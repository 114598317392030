import { useState } from "react";
import { ComponentLoadingSpinner } from ".";
import { IActionButton } from "../interfaces";
import { useNavigate } from "react-router-dom";

export const ActionButton = ({ onclick, callback, onErrorCallback, payload, dataTestId, text, title, buttonClassName, iconClassName, disabled }: IActionButton) => {
    const [showLoading, setShowLoading] = useState<boolean>(false);
    const navigate = useNavigate();

    const handleClick = () => {
        setShowLoading(true);

        var promise = onclick(payload);
        promise ? promise.then((data: any) => {
            setShowLoading(false);
            callback && callback(data);
        }).catch((error: any) => {
            if (error?.status === 404)
                navigate("/error/404");
            else
                onErrorCallback ? onErrorCallback(error) : navigate("/error/500");
        }) :
            setShowLoading(false);
    }

    return (
        <>
            {showLoading && <button className={`btn ${buttonClassName} position-relative disabled`}><ComponentLoadingSpinner /></button>}
            {!showLoading &&
                <button className={`btn ${buttonClassName}`} onClick={handleClick} data-testid={dataTestId} disabled={disabled} title={title}>
                    <span className={iconClassName}></span>
                    {text}
                </button>
            }
        </>
    )
}