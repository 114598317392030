import { useEffect, useState } from "react"
import settingsService from "../../services/settingsService";
import { AltinnSettingsPage } from "."
import { ComponentLoadingSpinner } from "../../components";
import { useLocation, useNavigate, useOutletContext } from "react-router-dom";
import { IAlert, IContext } from "../../interfaces";
import { Alert } from "../../components/Alert";
import { ResponseTypeEnum } from "../../utils/constants";
import { KatreSettingsPage } from "./katre";
import { NetherlandSettingsPage } from "./NetherlandSettingsPage";
import { SettingsContext } from "../../contexts/SettingsContext";

export const SettingsPage = () => {
    const getSettingsPage = (key: string) => {
        switch (key) {
            case "no":
                return <AltinnSettingsPage />;
            case "fi":
                return <KatreSettingsPage />;
            case "nl":
                return <NetherlandSettingsPage />;
            default:
                return <AltinnSettingsPage />;
        }
    }

    const [countryCode, setCountryCode] = useState<string>();
    const [defaultCountryCode, setDefaultCountryCode] = useState<string>();
    const currentCompany = useOutletContext<IContext>();
    const [showLoading, setShowLoading] = useState<boolean>(true);
    const [alert, setAlert] = useState<IAlert | undefined>();
    const { search } = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        setAlert(undefined);
        setCountryCode(undefined);
        setShowLoading(true);

        var urlParams = new URLSearchParams(search);
        var originUrl = urlParams.get("originUrl");

        if (originUrl != null)
            settingsService.verifyOriginUrl(originUrl)
                .then(() => getCountryCode())
                .catch((error: any) => {
                    if (error?.status === 400)
                        navigate("/error/400");
                    else
                        navigate("/error/500");
                })
        else
            getCountryCode();

    }, [currentCompany])

    const getCountryCode = () => {
        settingsService
            .getCountryCode()
            .then(response => {
                setShowLoading(false);

                const code = response.CountryCode?.toLowerCase();
                setCountryCode(code);
                setDefaultCountryCode(code);

                if (response.ResponseType !== ResponseTypeEnum.Success) {
                    setAlert({ type: response.ResponseType, title: response.Title, message: response.Message });
                }
            });
    }

    return (
        <>
            {showLoading && <ComponentLoadingSpinner />}
            {countryCode && defaultCountryCode &&
                <div className="ar-page-container">
                    <SettingsContext.Provider value={{ setCountryCode, countryCode, defaultCountryCode }}>
                        {alert && <div className="ar-settings-container ar-settings-top-alert"><Alert alert={alert} /></div>}
                        {getSettingsPage(countryCode)}
                    </SettingsContext.Provider>
                </div>
            }
        </>
    )
}