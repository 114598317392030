import { useEffect, useState } from "react";
import { ScreenSizeEnum } from "../utils/constants";

export const useMediaQuery = (screen: ScreenSizeEnum) => {
    const [matches, setMatches] = useState(false);

    const getScreenSize = () => {
        switch (screen) {
            case ScreenSizeEnum.SM:
                return '600px';
            case ScreenSizeEnum.MD:
                return '768px';
            case ScreenSizeEnum.LG:
                return '992px';
            case ScreenSizeEnum.XL:
                return '1200px';
            default:
                return '992px';
        }
    }

    useEffect(() => {
        const query = `(min-width: ${getScreenSize()})`;
        const media = window.matchMedia(query);
        if (media.matches !== matches) {
            setMatches(media.matches);
        }
        const listener = () => setMatches(media.matches);
        window.addEventListener('resize', listener);
        return () => window.removeEventListener('resize', listener);
    }, [matches, screen]);

    return matches;
}; 