import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useOutletContext } from "react-router-dom";
import { ActionButton, Modal, ModalBody, ModalFooter, ModalHeader, SearchBar } from "../../components";
import { usePageFetch } from "../../hooks/PageHook";
import { IContext, ICertificateData, IViewAllCertificatesModal, IPageQuery } from "../../interfaces";
import { ViewAllCompaniesTable } from "./ViewAllCompaniesTable";
import { SettingsContext } from "../../contexts/SettingsContext";
import { ResponseTypeEnum } from "../../utils/constants";

export const ViewAllCompanyCertificatesModal = ({ onClose, currentCertificate, fetchTableData, copyCertificateForCompanies, showAlert, refreshData }: IViewAllCertificatesModal) => {
    const { countryCode, defaultCountryCode } = useContext(SettingsContext);

    const [pageQuery, setPageQuery] = useState<IPageQuery>({ PageNumber: 1, PageSize: 200, Filters: { filterByCountryCode: countryCode === defaultCountryCode } });
    const [data, totalCount, hasMore, loadNextPage] = usePageFetch<ICertificateData>(fetchTableData, pageQuery);

    const { t } = useTranslation("general");
    const [search, setSearch] = useState<string>("");
    const [selectedCompanies, setSelectedCompanies] = useState<Set<number>>(new Set<number>());

    const currentCompany = useOutletContext<IContext>();

    useEffect(() => {
        if (hasMore)
            loadNextPage();
    }, [data])

    const handleSelect = (selectedCompanies: Set<number>) => {
        setSelectedCompanies(selectedCompanies);
    }

    const refreshAllData = () => {
        refreshData();
        setPageQuery({ ...pageQuery, PageNumber: 1, ForceCall: true })
    }

    const handleSave = () => {
        const model = {
            CompanyIds: [...selectedCompanies.values()]
        }
        return copyCertificateForCompanies(model);
    }

    const saveCallback = () => {
        showAlert({ type: ResponseTypeEnum.Success, message: t("SettingsAppliedSuccessfully")});
        refreshAllData();
    }

    return (
        <Modal className="modal-xl ar-view-all-modal ar-modal">
            <ModalHeader onClose={onClose}>
                <h1 className="modal-title">{t("AllCompanies", { ns: "general" })}</h1>
            </ModalHeader>
            <ModalBody>
                <div className="p-0 ml-0 mb-24 mr-0">
                    <p>{t("CurrentCompany")} : <b>{currentCompany.Name}</b></p>
                    <p>{t("CurrentCertificate")} : <b>{currentCertificate}</b></p>
                    <div className="container p-0 m-0 w-100">
                        <div className="row p-0 m-0">
                            <div className="p-0 col-lg-8 col-md-6 col-sm-12 col-xs-12 mb-16"><p className="mr-32">{t("ViewAllDescription")}</p></div>
                            <div className="p-0 col-lg-4 col-md-6 col-sm-12 col-xs-12 float-right"> <SearchBar onChange={(value) => setSearch(value)} /></div>
                        </div>
                    </div>
                </div>
                <ViewAllCompaniesTable
                    data={data}
                    headers={[
                        t("Name"),
                        t("Certificate"),
                        t("ExpirationDate"),
                    ]}
                    columns={[
                        ({ CompanyName }) => CompanyName,
                        ({ Certificate }) => Certificate,
                        ({ ExpirationDate }) => ExpirationDate,
                    ]}
                    rowId={({ CompanyId }) => CompanyId.toString()}
                    search={search}
                    onSelect={handleSelect}
                    loading={hasMore}
                />

            </ModalBody>
            <ModalFooter className="d-flex justify-content-end">
                <ActionButton onclick={handleSave}
                    text={t("Save")}
                    buttonClassName="btn-primary"
                    iconClassName=""
                    callback={saveCallback}
                    disabled={selectedCompanies.size === 0}
                    dataTestId="save-view-all-button" />
                <button className="btn btn-default" onClick={onClose} data-testid="cancel-view-all-button">{t("Cancel")}</button>
            </ModalFooter>
        </Modal >
    )
}