import "../../styles/modal.css";

export const Modal = ({ children, className }: { children?: React.ReactNode, className?: string }) => {
    return (
        <div className="modal fade in" tabIndex={-1} role="dialog" aria-labelledby="modal-title" aria-hidden="false">
            <div className={"modal-dialog " + className} role="document">
                <div className="modal-content">
                    {children}
                </div>
            </div>
        </div>
    );
};
