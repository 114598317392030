import { useState } from "react";
import { IOrderBy } from "../interfaces";

export const useTableSelect = (data: any[]) => {
    const [selectedData, setSelectedData] = useState<Set<any>>(new Set<any>());

    const handleSelect = (id: any, selected: boolean) => {
        setSelectedData((prev: Set<any>) => selected ? new Set(prev.add(id)) : new Set([...prev].filter(_id => _id !== id)));
    };

    const handleAllSelect = (checked: boolean) => {
        setSelectedData(checked ? new Set(data) : new Set());
    }

    return [selectedData, handleSelect, handleAllSelect] as const;
}

export const useTableOrder = <T extends IOrderBy,>(defaultOrder?: T) => {
    const [tableOrder, setTableOrder] = useState<T>(defaultOrder ? defaultOrder : {} as T);

    const handleOrderChange = (value: T["OrderValue"]) => {
        if (tableOrder.OrderValue === value) {
            setTableOrder({ OrderValue: value, OrderDirection: tableOrder.OrderDirection === "DESC" ? "ASC" : "DESC" } as T);
        } else {
            setTableOrder({ OrderValue: value, OrderDirection: "DESC" } as T);
        }
    }

    return [tableOrder, handleOrderChange] as const;
}