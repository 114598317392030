import { useEffect, useState, KeyboardEvent } from "react";
import { ReportDetailsContent } from ".";
import { Checkbox } from "../../components/Checkbox";
import { IReportMetadata } from "../../interfaces";
import reportService from "../../services/reportService";
import { handleKeyDown, toLocaleDateString, toLocaleDateTimeString, triggerWootric } from "../../utils";
import { ReportDetailsContainerEnum, ReportStatusGroup } from "../../utils/constants";

export const ReportTableRow = ({ report, onCheck, isChecked }: { isChecked: boolean, report: IReportMetadata, onCheck: (checked: boolean) => any }) => {
    const [expanded, setExpanded] = useState(false);
    const [rowData, setRowData] = useState(report);
    const [refreshed, setRefreshed] = useState(false);

    const getStatusIcon = (statusGroup: ReportStatusGroup) => {
        switch (statusGroup) {
            case ReportStatusGroup.Completed:
                return "vismaicon vismaicon-sm vismaicon-filled vismaicon-success";
            case ReportStatusGroup.InProgress:
                return "vismaicon vismaicon-sm vismaicon-dynamic ar-hourglass";
            case ReportStatusGroup.Received:
                return "vismaicon vismaicon-sm vismaicon-send icon-info";
            case ReportStatusGroup.Canceled:
                return "close ar-close";
            case ReportStatusGroup.ErrorInReport:
                return "vismaicon vismaicon-sm vismaicon-filled vismaicon-error";
            case ReportStatusGroup.ErrorLogOn:
                return "vismaicon vismaicon-sm vismaicon-logon-failed icon-error";
            case ReportStatusGroup.SendingFailed:
                return "vismaicon vismaicon-sm vismaicon-send icon-error";
            default:
                return "";
        }
    }

    useEffect(() => {
        setRowData(report);
        setRefreshed(false);
    }, [report])

    const refreshRowHeader = () => {
        var currentStatus = rowData.ReportStatus;

        reportService.getReportMetadata(report.ReferenceId)
            .then(data => {
                setRowData(data);

                var newStatus = data.ReportStatus;
                if (currentStatus !== newStatus)
                    setRefreshed(true);
            })
    }

    function handleKeyDownRow(event: KeyboardEvent<HTMLTableRowElement>) {
        handleKeyDown(event, "Enter", () => { event.stopPropagation(); setExpanded(!expanded) });
    };

    function handleKeyDownCheckbox(event: KeyboardEvent<HTMLInputElement>) {
        handleKeyDown(event, "Enter", () => { event.stopPropagation(); onCheck(!isChecked) });
    };

    function onRowClick() {
        setExpanded(!expanded);

        if (expanded) {
            triggerWootric('report_collapse');
        }
    };

    return (
        <>
            <tr tabIndex={0} onKeyDown={handleKeyDownRow} onClick={onRowClick} className={`position-relative ${expanded && "active"} ${refreshed && "ar-table-row-refreshed"}`}>
                <td className="w-0 position-initial">
                    {!rowData.Archived && <Checkbox isChecked={isChecked} onChange={onCheck} onKeyDown={handleKeyDownCheckbox} tabIndex={expanded ? 0 : -1} />}
                </td>
                <td className="ar-break-word"  >    
                    <div className="d-flex align-items-center justify-content-left">
                        <span data-testid="status-group-icon" className={`${getStatusIcon(rowData.ReportStatusGroup)}`}></span>
                        <span className="pl-8">{rowData.ReportStatus}</span>
                    </div>
                </td>
                <td className="ar-break-word">{rowData.OrganizationName}</td>
                <td className="ar-break-word">{rowData.ReportType}</td>
                <td className="ar-break-word">{toLocaleDateString(rowData.PeriodStart)} - {toLocaleDateString(rowData.PeriodEnd)}</td>
                <td className="ar-break-word">{toLocaleDateTimeString(rowData.Created)}</td>
            </tr>
            <tr className={`active expanded-content ${expanded ? "" : "d-none"}`}>
                <td colSpan={6} className="p-24">
                    <ReportDetailsContent
                        referenceId={rowData.ReferenceId}
                        expanded={expanded}
                        refreshRowHeader={refreshRowHeader}
                        detailsContainer={ReportDetailsContainerEnum.ReportTable} />
                </td>
            </tr>
        </>
    )
}



