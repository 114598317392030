import { useState, KeyboardEvent } from "react";
import '../styles/expandableSection.css';
import { handleKeyDown } from "../utils";

interface IExpandableSection {
    header: JSX.Element,
    headerChildren?: JSX.Element,
    children: JSX.Element,
    expanded?: boolean,
    className?: string
}

export const ExpandableSection = ({ header, headerChildren, children, expanded, className }: IExpandableSection) => {
    const [showSection, setShowSection] = useState(expanded != null ? expanded : false);

    function handleKeyDownHeader(event: KeyboardEvent<HTMLDivElement>) {
        handleKeyDown(event, "Enter", () => { event.stopPropagation(); setShowSection(!showSection) });
    };

    return (
        <div className={`expandable-section ${showSection ? "open" : ""} ${className ?? ""}`} aria-expanded={showSection} data-testid="expandable-section">
            <div className="header-container">
                <div onClick={() => setShowSection(!showSection)} className="header" role="button" tabIndex={0} onKeyDown={handleKeyDownHeader}>
                    {header}
                </div>
                {headerChildren}
            </div>
            {showSection && children}
        </div>
    );
}