import { IPage, IPageQuery, IPinkWebSettings, IPinkWebUpdateSettingsRequest } from '../interfaces';
import axiosUtils from '../utils/axios';

const baseUrl = "api/settings/pinkweb";

const getSettings = async (): Promise<IPinkWebSettings | undefined> => {
    const result = await axiosUtils.instance.get<IPinkWebSettings>(`${baseUrl}`);
    return result.data;
}

const getSettingsForAvailableCompanies = async (pageQuery: IPageQuery): Promise<IPage<IPinkWebSettings>> => {
    const result = await axiosUtils.instance.get<IPage<IPinkWebSettings>>(`${baseUrl}/companies`, { params: { PageNumber: pageQuery.PageNumber, PageSize: pageQuery.PageSize, ...pageQuery.Filters } });
    return result.data;
}

const updateSettings = async (newAltinnSettings: IPinkWebUpdateSettingsRequest): Promise<any> => {
    const result = await axiosUtils.instance.put<any>(`${baseUrl}`, newAltinnSettings);
    return result.data;
}

const assignSettings = async (companyIds: number[]): Promise<any> => {
    const result = await axiosUtils.instance.put<any>(`${baseUrl}/companies`, companyIds);
    return result.data;
}

const deleteCredentials = async (): Promise<any> => {
    await axiosUtils.instance.delete(`${baseUrl}`);
    return true;
}

export default {
    getSettings,
    getSettingsForAvailableCompanies,
    updateSettings,
    deleteCredentials,
    assignSettings,
}