import * as React from 'react';
import { ClassNameBuilder } from '../utils/ClassNameBuilder';
import { getElementId } from '../utils/getElementId';

export interface IWizardDropdownItemProps {
    id?: string;
    className?: string;
    ariaDropdownMenuLabel?: string | React.ReactElement;
    ariaDropdownToggleLabelOpen?: string | React.ReactElement;
    ariaDropdownToggleLabelClose?: string | React.ReactElement;
}

export const WizardDropdownItem: React.FC<React.PropsWithChildren<IWizardDropdownItemProps>> & React.HTMLProps<HTMLDivElement> = ({
    id,
    className,
    ariaDropdownMenuLabel,
    ariaDropdownToggleLabelOpen,
    ariaDropdownToggleLabelClose,
    children,
    ...others
}) => {
    const [isOpen, setIsOpen] = React.useState(false);

    const classBuilder = new ClassNameBuilder(className || '');
    classBuilder.add('wizard-item').add('dropdown');
    classBuilder.add('open', isOpen);

    const elementId = getElementId(id);

    return (
        <div className={classBuilder.build()} {...others}>
            <a
                className="dropdown-toggle"
                aria-labelledby={`dropdown-toggle-label-${elementId}`}
                role="link"
                tabIndex={0}
                aria-expanded={isOpen}
                onClick={() => setIsOpen(!isOpen)}
            >
                <i className="icon-align-justify" />
            </a>
            <div id={`dropdown-toggle-label-${elementId}`} className="sr-only">
                {isOpen && ariaDropdownToggleLabelClose}
                {!isOpen && ariaDropdownToggleLabelOpen}
            </div>
            <div
                className="dropdown-menu"
                role="menu"
                aria-hidden={!isOpen}
                aria-expanded={isOpen}
                aria-labelledby={`wizard-overflow-menu-label-${elementId}`}
            >
                {children}
            </div>
            <div id={`wizard-overflow-menu-label-${elementId}`} className="sr-only">
                {ariaDropdownMenuLabel}
            </div>
        </div>
    );
};
