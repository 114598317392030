import * as React from 'react';
import { PropsWithChildren } from 'react';
import { ClassNameBuilder } from '../utils/ClassNameBuilder';
import { isDefined } from '../utils/isDefined';

interface IProps {
    fluid?: boolean;
}

export const Container: React.FC<PropsWithChildren<IProps & React.HTMLProps<HTMLDivElement>>> = ({
    fluid = false,
    children,
    ...others
}) => {
    const classBuilder = new ClassNameBuilder();

    classBuilder.add('container', !isDefined(fluid)).add('container-fluid', isDefined(fluid));

    return (
        <>
            <div className={classBuilder.build()} {...others}>
                {children}
            </div>
        </>
    );
};
